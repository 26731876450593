// import createCache from '@emotion/cache';
import createEmotion, { Options } from 'create-emotion';
import createEmotionServer from 'create-emotion-server';
import { makeInsert } from './emotionInsertStyle';

const options: Options = {
  // The key option is required when there will be multiple instances in a single app
  key: 'eb',
  speedy: false,
};

const emotionInstance = createEmotion(options);

export const {
  flush,
  hydrate,
  cx,
  merge,
  getRegisteredStyles,
  injectGlobal,
  keyframes,
  css,
  sheet,
  cache,
} = emotionInstance;

// @ts-ignore
cache.insert = makeInsert(options, cache);

export const {
  extractCritical,
  renderStylesToString,
  renderStylesToNodeStream,
} = createEmotionServer(cache);
