import React, { ChangeEvent, useState } from 'react';
import slugify from 'slugify';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
} from '@chakra-ui/react';

import { routes } from 'src/routes';
import {
  useDuplicateProjectMutation,
  useOrganizationsQuery,
} from 'src/generated/graphql';

export const DuplicateProjectModalContent = ({
  currentProjectId,
  currentProjectName,
  currentOrgSlug,
  onClose,
}: {
  currentProjectId: string;
  currentProjectName: string;
  currentOrgSlug: string;
  onClose: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newProjectName, setNewProjectName] = useState(
    `Copy of ${currentProjectName}`,
  );
  const [updatedOrgSlug, setUpdatedOrgSlug] = useState<string>(currentOrgSlug);

  const newProjectId = slugify(newProjectName, {
    lower: true,
  });

  const { data } = useOrganizationsQuery();

  const [duplicateProject] = useDuplicateProjectMutation();

  return (
    <ModalContent
      borderRadius="4px"
      as="form"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const newProject = await duplicateProject({
          variables: {
            newProjectName: newProjectName,
            currentProjectId,
            organizationSlug: updatedOrgSlug,
          },
        });

        setIsSubmitting(false);
        if (newProject.data?.duplicateProject?.slug) {
          window.open(
            routes.projectEditor.getPath({
              orgSlug: updatedOrgSlug,
              projectSlug: newProject.data.duplicateProject.slug,
            }),
          );
        } else {
          throw new Error('Error duplicating project');
        }

        onClose();
      }}
    >
      <ModalHeader color="gray.800" fontSize="1rem" fontWeight="700">
        Duplicate Project
      </ModalHeader>
      <ModalBody>
        <FormControl>
          <FormLabel
            htmlFor="newProjectName"
            color="gray.700"
            fontSize="0.8rem"
          >
            New project name
          </FormLabel>
          <Input
            id="newProjectName"
            size="sm"
            defaultValue={newProjectName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewProjectName(e.target.value)
            }
          />
        </FormControl>
        <FormLabel htmlFor="newProjectName" color="gray.700" fontSize="0.8rem">
          Organization
        </FormLabel>
        <Select
          onChange={(e) => {
            setUpdatedOrgSlug(e.target.value);
          }}
        >
          {data?.currentUser?.memberships?.map((membership) => {
            console.log('orgs');
            return (
              <option
                key={membership.organization.id}
                value={membership.organization.slug as string}
                selected={membership.organization.slug === currentOrgSlug}
              >
                {membership.organization.slug}
              </option>
            );
          })}
        </Select>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          isDisabled={isSubmitting}
          size="sm"
          mr={3}
          onClick={onClose}
          color="gray.600"
        >
          Close
        </Button>
        <Button
          type="submit"
          isDisabled={isSubmitting}
          colorScheme="blue"
          size="sm"
          transition="none"
          _hover={{ boxShadow: '0 1px 3px 1px rgba(0,0,0,0.1)' }}
        >
          {isSubmitting ? (
            <React.Fragment>
              Copying to <i>{newProjectId}</i>...
            </React.Fragment>
          ) : (
            `Duplicate Project`
          )}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
