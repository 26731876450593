import { ImageList } from './ImageList';
import { ProjectSettings } from './ProjectSettings';
import { useProjectContext } from 'src/utils/useProject/useProject';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { SideContent } from './SideNavbar';

export const SideDisplay = ({
  content,
  editorRef,
  src,
}: {
  content: SideContent;
  editorRef: React.MutableRefObject<monacoEditor.editor.IStandaloneCodeEditor>;
  src: string;
}) => {
  const [project, { addFiles, removeFile }] = useProjectContext();

  return (
    <>
      {content === 'images' ? (
        <ImageList
          addFilesToProject={addFiles}
          removeFileFromProject={removeFile}
          onSelectImage={(url) => {
            // insert the url of the image into the editor's current cursor position
            if (editorRef.current) {
              const position = editorRef.current.getPosition();
              if (position) {
                const lines = src.split('\n');
                const chars = lines[position.lineNumber - 1].split('');
                chars.splice(position.column - 1, 0, url);
                lines[position.lineNumber - 1] = chars.join('');

                editorRef.current.setValue(lines.join('\n'));
                editorRef.current.setPosition({
                  ...position,
                  column: position.column + url.length,
                });
                editorRef.current.focus();
                editorRef.current.revealPositionInCenter({
                  ...position,
                  column: position.column + url.length,
                });
              }
            }
          }}
        />
      ) : content === 'projectSettings' ? (
        <ProjectSettings projectId={project.id} projectName={project.name} />
      ) : null}
    </>
  );
};
