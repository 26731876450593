import { useCallback, useEffect, useMemo, useState } from 'react';
import constate from 'constate';
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactGA from 'react-ga';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const useAuth = () => {
  // a "firebaseAuthUserEntry" is the user object returned from firebase's user service
  // contains whether the user is logged in and their user id (uid) if logged in
  // Does not contain data from the user object from firestore (isAdmin)
  const [firebaseAuthUserEntry, initializingAuth, error] = useAuthState(
    firebase.auth(),
  );
  const [userIdToken, setUserIdToken] = useState<string | undefined>(undefined);

  const uid = firebaseAuthUserEntry && firebaseAuthUserEntry.uid;

  useEffect(() => {
    const refreshIdToken = () => {
      firebase
        .auth()
        .currentUser?.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          setUserIdToken(idToken);
          console.log({ idToken });
        });
    };
    const interval = setInterval(refreshIdToken, 60_000);
    refreshIdToken();

    return () => clearInterval(interval);
  }, [uid]);

  const signup = useCallback((email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }, []);

  const login = useCallback(() => {
    return firebase.auth().signInWithPopup(googleAuthProvider);
  }, []);

  const logout = useCallback(() => {
    return firebase.auth().signOut();
  }, []);

  if (firebaseAuthUserEntry) {
    ReactGA.ga('set', 'userId', firebaseAuthUserEntry.uid);
  }

  const initializing =
    initializingAuth ||
    // logged in but haven't gotten token yet
    (uid !== null && !userIdToken);
  const isLoggedOut = !initializing && !uid;

  const user = useMemo(
    () =>
      !initializing && uid
        ? {
            uid,
          }
        : null,
    [initializing, uid],
  );

  return useMemo(
    () => ({
      user,
      backendAccessToken: userIdToken,
      initializing,
      isLoggedOut,
      signup,
      login,
      logout,
      error,
    }),
    [
      error,
      initializing,
      isLoggedOut,
      login,
      logout,
      signup,
      user,
      userIdToken,
    ],
  );
};

export const [AuthContextProvider, useAuthContext] = constate(useAuth);
