import { css } from './emotion';
import { Language } from './types';

const style = {
  breakSmall: `520px`,
};

const mjRawWrap = (str: string, wrap: boolean) =>
  wrap ? `<mj-raw>${str}</mj-raw>` : str;

export const media = {
  smallDown: (...args: any[]) => css`
    @media (max-width: ${style.breakSmall}) {
      ${css(...args)};
    }
    body[data-outlook-cycle] & {
      ${css(...args)};
    }
  `,
  outlookMobile: (...args: any[]) => css`
    body[data-outlook-cycle] & {
      ${css(...args)};
    }
  `,
  mediumUp: (...args: any[]) => css`
    @media (min-width: ${style.breakSmall}) {
      ${css(...args)};
    }
  `,
  notOutlook: (input: any) => `
    <!--[if !mso]><!-->
      ${input}
    <!--<![endif]-->
    `,
  experimental_notOutlook: (input: any, shouldWrapWithMjmlRaw = false) => `
    ${mjRawWrap(
      `
        <!--[if mso]>
          <div style="mso-hide: all; overflow: hidden; max-height: 0; width: 0px; height:0px; display: none; line-height: 0; visibility: hidden; font-size:0px; color: transparent;">
        <![endif]-->
        <div class="hide-tables-on-outlook">
      `,
      shouldWrapWithMjmlRaw,
    )}
    ${input}
    ${mjRawWrap(
      `
        </div>
        <!--[if mso]>
          </div>
        <![endif]-->
      `,
      shouldWrapWithMjmlRaw,
    )}
  `,
  outlook: (input: any) => `
    <!--[if mso]>
      ${input}
    <![endif]-->
  `,
};

export const translate = (language: Language) => (
  english: string = 'en',
  french: string = 'fr',
) => {
  return language === Language.English ? english : french;
};

export const veeva = {
  dropdown: (texts?: Array<string>, stableText?: string) => {
    if (!texts) {
      console.warn(`veeva.dropdown() needs an array of strings`);
      return '';
    }
    const pieces = texts.map((text) =>
      text
        .replace(/firstname/gi, '##accFname##')
        .replace(/lastname/gi, '##accLname##')
        .concat(stableText || ''),
    );
    return `{{customText[${pieces.join('|')}]}}`;
  },
};

export const list = ({
  items,
  styles,
  tablePadding = '5px 10px 10px',
  bullet = '&bull;',
  tableClass,
}: {
  items: string[];
  styles?: string;
  tablePadding?: string;
  bullet?: string;
  tableClass?: string;
}) => {
  return `
    <mj-table padding="${tablePadding}" css-class="${tableClass}">
      ${items
        .map((item, index) => {
          return `
            <tr>
              <td
                valign="top"
                width="6px"
                align="left"
                style="padding-top: 5px;white-space: nowrap;${styles}"
              >
                ${bullet === 'number' ? `${index + 1}.` : bullet}
              </td>
              <td style="${styles}">&nbsp;</td>
              <td style="padding-top: 5px;${styles}" valign="top">
                ${item}
              </td>
            </tr>
          `;
        })
        .join('')}
    </mj-table>
  `;
};

export const footer = (enFr: ReturnType<typeof translate>) => ({
  emailCode,
  backgroundColor,
  immunology = false,
  fullWidth = true,
  includePaab = true,
  textColor = '#777',
  background2 = '#199ed9',
  invertLogos = false,
  invertJansen = true,
}: {
  emailCode?: string;
  backgroundColor?: string;
  immunology?: boolean;
  fullWidth?: boolean;
  includePaab?: boolean;
  textColor?: string;
  background2?: string;
  invertLogos?: boolean;
  invertJansen?: boolean;
}) =>
  `
    <mj-section
      ${fullWidth ? 'full-width="full-width"' : ''}
      ${backgroundColor ? `background-color="${backgroundColor}"` : ''}
      padding="30px"
    >
      <mj-column>
        <mj-text
          font-size="12px"
          color="${textColor}"
          align="center"
          padding="0"
        >
          &copy; 2019 Janssen Inc.
        </mj-text>
        <mj-text
          font-size="12px"
          color="${textColor}"
          align="center"
          padding-top="0"
          padding-bottom="20px" 
        >
          ${enFr(
            'All trademarks used under license.',
            'Marques de commerce utilisées sous licence.',
          )}
        </mj-text>
        <mj-text
          font-size="12px"
          color="${textColor}"
          align="center"
          padding="0"
        >
          Janssen Inc.
        </mj-text>
        <mj-text
          font-size="12px"
          color="${textColor}"
          align="center"
          padding="0"
          padding-bottom="20px"
        >
          19 Green Belt Drive |
          Toronto${enFr(`, Ontario`, ` (Ontario)`)} | M3C&nbsp;1L9
          |
          ${enFr(
            `
              <a
                href="https://www.janssen.com/canada"
                style="color: ${textColor};"
                target="_blank"
              >
                www.janssen.com/canada
              </a>
            `,
            `
              <a
                href="https://www.janssen.com/canada/fr"
                style="color: ${textColor};"
                target="_blank"
              >
                www.janssen.com/canada/fr
              </a>
            `,
          )}
        </mj-text>
        <mj-text
          font-size="12px"
          color="${textColor}"
          align="center"
          padding-bottom="20px"
        >
          ${emailCode}${enFr('E', 'F')}
        </mj-text>
        <mj-image
          width="153px"
          alt="${enFr('IMC', 'IMC')}"
          src="./images/logo-imc-${invertLogos ? 'white-' : ''}${enFr()}.png"
          href="${enFr(
            'http://innovativemedicines.ca',
            'http://innovativemedicines.ca/fr',
          )}"
        />
        ${
          includePaab
            ? `
                <mj-image
                  width="47px"
                  alt="${enFr('PAAB', 'CCPP')}"
                  src="./images/logo-paab-${
                    invertLogos ? 'white-' : ''
                  }${enFr()}.png"
                  href="${enFr(
                    'https://www.paab.ca',
                    'https://www.paab.ca/fr',
                  )}"
                />
              `
            : ''
        }
      </mj-column>
    </mj-section>
    <mj-section
      ${fullWidth ? 'full-width="full-width"' : ''}
      background-color="${background2}"
      padding="10px"
    >
      <mj-column>
        <mj-image
          width="182px"
          alt="Janssen ${immunology ? 'Immunology' : ''}"
          src="./images/logo-janssen${invertJansen ? '-white' : ''}${
    immunology ? '-immunology' : ''
  }.png"
        />
      </mj-column>
    </mj-section>
  `;

export function mailto({
  to = '{{userEmailAddress}}',
  subject,
  body,
}: {
  to: string;
  subject?: string;
  body?: string;
}) {
  var url = `mailto:${to}`;
  Object.entries({ subject, body })
    .filter(([key, value]) => !!value)
    .forEach(function ([key, value], index) {
      if (index === 0) {
        url += '?';
      } else {
        url += '&';
      }
      url += key + '=' + encodeURIComponent(value ?? '');
    });
  return url;
}

export function tel(input: string) {
  return `<span style="white-space: nowrap; text-decoration: none;">${input.replace(
    /-/g,
    '<span>&#8209;</span>&zwnj;',
  )}</span>`;
}
