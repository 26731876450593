import axios from 'axios';
import type { VeevaLoginResponse } from '@mailcrunch/veeva-vault/lib/login';
import type { listDocuments } from '@mailcrunch/veeva-vault/lib/documents/listDocuments';
import type { createDraftDocument } from '@mailcrunch/veeva-vault/lib/documents/createDraftDocument';
import type { uploadAssets } from '@mailcrunch/veeva-vault/lib/documents/uploadAssets';
import type { ListDocumentsResponse } from '@mailcrunch/veeva-vault/lib/documents/types';

export class VeevaVaultService {
  static getSessionTokenByUsernamePassword = ({
    apiHost,
    username,
    password,
  }: {
    apiHost: string;
    username: string;
    password: string;
  }) => {
    return axios
      .post<VeevaLoginResponse>(
        `https://us-central1-email-builder-242000.cloudfunctions.net/veevaLogin`,
        {
          apiHost,
          username,
          password,
        },
      )
      .then((response) => response.data.sessionId);
  };

  static listDocuments = (
    options: Omit<Parameters<typeof listDocuments>[0], 'apiVersion'>,
  ) => {
    return axios
      .post<ListDocumentsResponse>(
        `https://us-central1-email-builder-242000.cloudfunctions.net/veevaListDocuments`,
        options,
      )
      .then((response) => response.data);
  };

  static createDocumentRevision = (
    options: Omit<
      Parameters<typeof createDraftDocument>[0],
      'apiVersion' | 'file'
    > & {
      fileContents: string;
      approve: boolean;
    },
  ) => {
    return axios
      .post<ListDocumentsResponse>(
        `https://us-central1-email-builder-242000.cloudfunctions.net/veevaCreateDocumentRevision`,
        options,
      )
      .then((response) => response.data);
  };

  static uploadDocumentAssets = (
    options: Omit<Parameters<typeof uploadAssets>[0], 'apiVersion' | 'file'> & {
      file: File | Blob;
    },
  ) => {
    const formData = new FormData();
    formData.append('file', options.file, options.fileName);
    formData.append('apiHost', options.apiHost);
    formData.append('documentId', options.documentId.toString());
    formData.append('fileName', options.fileName);
    formData.append('sessionId', options.sessionId);

    return axios
      .post<ListDocumentsResponse>(
        `https://us-central1-email-builder-242000.cloudfunctions.net/veevaUploadDocumentAssets`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((response) => response.data);
  };
}
