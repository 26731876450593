export const sendMail = ({
  toAddress,
  subject,
  html,
}: {
  toAddress: string;
  subject: string;
  html: string;
}) =>
  fetch('/api/functions/sendMail', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      toAddress,
      subject,
      html,
    }),
  });
