import { PartialBy } from '../types/PartialBy';

export class ProjectConflictError extends Error {}

export class ProjectOutOfDateError extends Error {}

export type UpdateProjectError = ProjectOutOfDateError;

export type ProjectFile = {
  relativePath: string;
  remoteUrl: string;
};

export type UploadingAndUploadedFile = PartialBy<ProjectFile, 'remoteUrl'>;

export interface Project {
  id: string;
  name: string;
  src: string;
  files?: ProjectFile[];
  archived?: boolean;
  createdAt?: string;
  createdBy?: string;
  lastModified?: string;
  lastModifiedBy?: string;
  thumbnail?: {
    remoteUrl: string;
    lastModified: string;
  };
  assetNameTransformTemplate?: string | undefined | null;
}

export interface ProjectMethodsAndStuff {
  loading: boolean;
  uploadFile: (file: File, path: string) => Promise<string>;
  uploadAndSetProjectThumbnail: (blob: Blob) => Promise<string>;
  uploadingAndUploadedImages: UploadingAndUploadedFile[];
  updateProjectSrc: (src: string) => Promise<string | undefined>;
  isSavingProject: boolean;
  addFiles: (newFiles: Array<ProjectFile>) => Promise<void>;
  removeFile: (fileToRemove: ProjectFile) => Promise<void>;
  duplicateProject: ({
    orgId,
    newProjectId,
    newProjectName,
  }: {
    orgId: string;
    newProjectId: string;
    newProjectName: string;
  }) => Promise<string>;
  renameProject: (newName: string) => Promise<any>;
  setProjectAssetNameTransformTemplate: (
    assetNameTransformTemplate: string,
  ) => void;
  errors: unknown[];
}
