import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import {
  FullStory,
  init as initFullStory,
  isInitialized as isFullstoryInitialized,
} from '@fullstory/browser';

const RUNTIME_ENV = process.env.REACT_APP_RUNTIME_ENV || 'localdev';

Sentry.init({
  dsn: 'https://20775f6371994b04bae85ba5854ddeb1@sentry.io/1894903',
  environment: RUNTIME_ENV,
});

if (!isFullstoryInitialized()) {
  initFullStory({
    orgId: 'PKM9J',
    devMode: RUNTIME_ENV !== 'production',
  });
}

FullStory('getSessionAsync').then((sessionUrl) => {
  Sentry.setContext('fullstory', {
    sessionUrl,
  });
});

ReactGA.initialize('UA-150276238-1');
