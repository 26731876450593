export const config = {
  firebase: {
    apiKey: 'AIzaSyAxXVVETE-wX6iJ5GnqiighsqCrNbYe87M',
    authDomain: 'email-builder-c70fb.firebaseapp.com',
    databaseURL: 'https://email-builder-c70fb.firebaseio.com',
    projectId: 'email-builder-c70fb',
    storageBucket: 'email-builder-c70fb.appspot.com',
    messagingSenderId: '811324541085',
    appId: '1:811324541085:web:0e6993eb9ea22531',
  },
};
