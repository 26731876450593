import querystring from 'querystring';
import firebase from 'firebase/app';
import { useCallback, useMemo, useReducer } from 'react';
import { pathToRegexp } from 'path-to-regexp';
import URL from 'url-parse';
import { fileToBuffer } from './imageUpload/fileToMemoryImage';
import { getChecksum } from './utils/getChecksum';
import _ from 'lodash';

const MAX_FILE_SIZE_MB = 3;
const FILETYPE_WHITELIST = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
];

const storage = firebase.storage;
export interface UploadStatus {
  progress?: number;
  state: firebase.storage.TaskState;
  downloadUrl?: string;
}

export interface UploadStatusMap {
  [relativePath: string]: UploadStatus;
}

type UploadStatusAction =
  | {
      type: 'progress';
      data: {
        relativePath: string;
        progress: number;
      };
    }
  | {
      type: 'error';
      data: {
        relativePath: string;
      };
    }
  | {
      type: 'complete';
      data: {
        relativePath: string;
        downloadUrl: string;
      };
    }
  | {
      type: 'remove';
      data: {
        relativePath: string;
      };
    };

const firebaseDownloadUrlRoute = pathToRegexp(
  `/v0/b/:storageBucket/o/:filePath`,
);

export const getGcsFilePathFromFirebaseDownloadUrl = (
  firebaseDownloadUrl: string,
) => {
  const matches = firebaseDownloadUrlRoute.exec(
    new URL(firebaseDownloadUrl).pathname,
  );
  const storageBucket = matches && matches[1];
  const filePath =
    matches &&
    (decodeURIComponent || querystring.unescape)(matches[2]).split('?')[0];
  return `https://${storageBucket}.storage.googleapis.com/${filePath}`;
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  return Object.assign(blob, {
    lastModified: new Date().getTime(),
    name: fileName,
  });
};

export const useFileUpload = (orgId: string, projectId: string) => {
  const projectRootStorageRef = useMemo(
    () => storage().ref(`orgs/${orgId}/projects/${projectId}`),
    [orgId, projectId],
  );

  const [uploadStatusMap, dispatchUploadStatusUpdate] = useReducer(
    (state: UploadStatusMap, action: UploadStatusAction) => {
      switch (action.type) {
        case 'progress':
          return {
            ...state,
            [action.data.relativePath]: {
              progress: action.data.progress,
              state: storage.TaskState.RUNNING,
            },
          };
        case 'error':
          return {
            ...state,
            [action.data.relativePath]: {
              state: storage.TaskState.ERROR,
            },
          };
        case 'complete':
          return {
            ...state,
            [action.data.relativePath]: {
              state: storage.TaskState.SUCCESS,
              downloadUrl: action.data.downloadUrl,
            },
          };
        case 'remove':
          return _.omit(state, action.data.relativePath);
        default:
          throw new Error();
      }
    },
    {},
  );

  const uploadFile = useCallback(
    async (file: File, relativePath: string) =>
      // promise to return the uploaded file's download-url
      new Promise<string>(async (resolve, reject) => {
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
          console.warn(
            `File size exceeds limit of ${MAX_FILE_SIZE_MB}, aborting upload.`,
          );
          return;
        }
        if (!FILETYPE_WHITELIST.includes(file.type)) {
          console.warn(
            `File type of "${
              file.type
            }" cannot be uploaded. File type must be one of (${FILETYPE_WHITELIST.join(
              ', ',
            )})`,
          );
          return;
        }

        const checksum = getChecksum(await fileToBuffer(file));

        console.log(
          'upload path',
          relativePath
            // remove leading `.` ()`./images` -> `/images`)
            .replace(/^\.\//, '')
            // add checksum to end of filename (before extension)
            .replace(/(\.\w+)(?!.*\.\w+)/, `-${checksum}-$1`),
        );
        const remoteRelativePath = relativePath
          .replace(/^\.\//, '')
          .replace(/(\.\w+)(?!.*\.\w+)/, `-${checksum}-$1`);

        const uploadTask = projectRootStorageRef
          .child(remoteRelativePath)
          .put(file);

        uploadTask.on(
          'state_changed',
          function (snapshot) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatchUploadStatusUpdate({
              type: 'progress',
              data: { relativePath, progress },
            });
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
              case storage.TaskState.RUNNING:
                console.log('Upload is running');
                break;
              default:
            }
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatchUploadStatusUpdate({
              type: 'error',
              data: {
                relativePath,
              },
            });
            reject(error);
            console.warn(`Error uploading file.`, error);
          },
          async function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (firebaseDownloadUrl) {
                const gcsUrl = getGcsFilePathFromFirebaseDownloadUrl(
                  firebaseDownloadUrl,
                );
                dispatchUploadStatusUpdate({
                  type: 'complete',
                  data: {
                    relativePath,
                    downloadUrl: gcsUrl,
                  },
                });

                resolve(gcsUrl);
                console.log('File available at', gcsUrl);
              });
          },
        );
      }),
    [projectRootStorageRef],
  );

  const removeFile = useCallback(async (relativePath: string) => {
    dispatchUploadStatusUpdate({
      type: 'remove',
      data: {
        relativePath,
      },
    });
  }, []);

  console.log('uploadStatusMap', uploadStatusMap);

  return [uploadFile, uploadStatusMap, removeFile] as const;
};
