import { Redirect, Route, Switch } from 'react-router-dom';
import { Org } from './Org/Org';

import { routes } from './routes';
import { Project } from './Org/Projects/Project';
import { ProjectsPage } from './Org/Projects/ProjectsPage';
import { HistoryPage } from './Org/Projects/HistoryPage/HistoryPage';
import { OrgSettings } from './Org/OrgSettings/OrgSettings';

import { GraphQLProjectContextProvider } from './utils/useProject/useProject';
import { EmailViewer } from 'src/EmailViewer';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import { useAuthContext } from 'src/auth';
import { LoadingScreen } from 'src/LoadingScreen';
import { HandleLoginSuccess } from './HandleLoginSuccess/HandleLoginSuccess';

export const RoutesIndex = () => {
  const { user, isLoggedOut, initializing } = useAuthContext();
  const [mostRecentOrgSlug, setMostRecentOrgSlug] = useLocalStorage<
    string | null
  >(`${user?.uid}:mostRecentOrg`, null);

  if (isLoggedOut) {
    return <Redirect to={routes.login.getPath()} />;
  }
  if (initializing) {
    return <LoadingScreen />;
  }

  return (
    <Switch>
      <Route
        exact
        path={routes.root.definition}
        render={() => {
          if (mostRecentOrgSlug) {
            return (
              <Redirect
                to={routes.projects.getPath({ orgSlug: mostRecentOrgSlug })}
              />
            );
          } else {
            return <HandleLoginSuccess />;
          }
        }}
      />
      <Route
        exact={false}
        path={routes.org.definition}
        render={({ match }) => {
          setMostRecentOrgSlug(match.params.orgSlug);
          return (
            <Switch>
              <Route
                exact
                path={routes.org.definition}
                render={() => <Org />}
              />
              <Route
                path={routes.orgSettings.definition}
                render={({ match }) => (
                  <OrgSettings orgSlug={match.params.orgSlug} />
                )}
              />

              <Route
                exact
                path={routes.projects.definition}
                render={({ match }) => (
                  <ProjectsPage
                    orgSlug={match.params.orgSlug}
                    key={match.params.orgSlug}
                  />
                )}
              />
              <Route
                exact
                path={routes.projectEditor.definition}
                render={({ match }) => {
                  return (
                    <GraphQLProjectContextProvider
                      projectSlug={match.params.projectSlug}
                      orgSlug={match.params.orgSlug}
                    >
                      <Project
                        projectSlug={match.params.projectSlug}
                        orgSlug={match.params.orgSlug}
                      />
                    </GraphQLProjectContextProvider>
                  );
                }}
              />
              <Route
                exact
                path={routes.projectViewer.definition}
                render={({ match }) => {
                  return (
                    <GraphQLProjectContextProvider
                      projectSlug={match.params.projectSlug}
                      orgSlug={match.params.orgSlug}
                    >
                      <EmailViewer />
                    </GraphQLProjectContextProvider>
                  );
                }}
              />

              <Route
                exact
                path={routes.history.definition}
                render={({ match }) => (
                  <HistoryPage
                    projectSlug={match.params.projectSlug}
                    orgSlug={match.params.orgSlug}
                  />
                )}
              />
            </Switch>
          );
        }}
      />
    </Switch>
  );
};
