import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';

export const WithDisclosure = ({
  children,
  defaultIsOpen,
}: {
  children: (args: UseDisclosureReturn) => JSX.Element | null;
  defaultIsOpen?: boolean;
}) => {
  const disclosure = useDisclosure({ defaultIsOpen });

  return children(disclosure);
};
