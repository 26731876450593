/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import logo from 'src/mailcrunch-logo.png';
import { Login } from '../Login/Login';

export const LandingPage = () => {
  return (
    <div css={tw`flex flex-col mx-6 w-full`}>
      <div css={tw`flex flex-row items-center mt-4`}>
        <img src={logo} alt="logo" css={tw`w-8 mr-2`} />
        <span css={tw`mx-2 text-xl text-gray-800 text-center font-semibold`}>
          Mailcrunch
        </span>
      </div>

      <div css={tw`flex flex-col items-center justify-center`}>
        <h1 css={tw`text-8xl text-center text-gray-800 font-extrabold pt-28`}>
          Welcome to <span css={tw`text-blue-500`}>Mailcrunch</span>
        </h1>
        <div css={tw`mt-20`}>
          <Login shouldRedirectToWhereTheUserWas={true} />
        </div>
      </div>
    </div>
  );
};
