/* eslint-disable no-useless-escape */
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const CUSTOM_TEMPLATE_LANGUAGE_ID = 'email-template-language';

export const customLanguageConfiguration: monaco.languages.LanguageConfiguration = {
  wordPattern: /(-?\d*\.\d\w*)|([^\`\~\!\@\$\^\&\*\(\)\=\+\[\{\]\}\\\|\;\:\'\"\,\.\<\>\/\s]+)/g,

  comments: {
    // symbols used for start and end of a block comment.
    // @ts-ignore
    blockComment: ['<%/*', '*/%>', '<%#', '%>', '<?#', '?>', '<?/*', '*/?>'],
  },

  // symbols used as brackets
  brackets: [
    ['<!--', '-->'],
    ['<', '>'],
    ['{', '}'],
    ['[', ']'],
    ['(', ')'],
  ],

  // symbols that are auto closed when typing
  autoClosingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: "'", close: "'" },
    { open: '"', close: '"' },
    { open: '`', close: '`', notIn: ['string', 'comment'] },
    { open: '/*', close: ' */', notIn: ['string'] },
    { open: '/**', close: ' */', notIn: ['string'] },
    { open: '<!--', close: '-->', notIn: ['comment', 'string'] },
    { open: '<%', close: '%>' },
  ],

  // symbols that that can be used to surround a selection
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '<', close: '>' },
    { open: "'", close: "'" },
    { open: '"', close: '"' },
    { open: '`', close: '`' },
  ],

  folding: {
    markers: {
      start: new RegExp('^\\s*<!--\\s*#region\\b.*-->'),
      end: new RegExp('^\\s*<!--\\s*#endregion\\b.*-->'),
    },
  },
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const customLanguage = <monaco.languages.IMonarchLanguage>{
  defaultToken: '',

  tokenPostfix: '.ejs',
  ignoreCase: true,

  tokenizer: {
    root: [
      [/<%/, { token: '@rematch', next: '@ejs.root' }],
      [/<!DOCTYPE/, 'metatag', '@doctype'],
      [/<!--/, 'comment', '@comment'],
      [
        /(<)((?:[\w\-]+:)?[\w\-]+)(\s*)(\/>)/,
        ['delimiter', 'tag', '', 'delimiter'],
      ],
      [/(<)(script)/, ['delimiter', { token: 'tag', next: '@script' }]],
      [/(<)(mj-style)/, ['delimiter', { token: 'tag', next: '@style' }]],
      [/(<)(style)/, ['delimiter', { token: 'tag', next: '@style' }]],
      [
        /(<)((?:[\w\-]+:)?[\w\-]+)/,
        ['delimiter', { token: 'tag', next: '@otherTag' }],
      ],
      [
        /(<\/)((?:[\w\-]+:)?[\w\-]+)/,
        ['delimiter', { token: 'tag', next: '@otherTag' }],
      ],
      [/</, 'delimiter'],
      [/[^<]+/],
    ],
    ejs: [
      [
        /<%\-?/,
        {
          token: 'ejs.delimiter',
          nextEmbedded: 'javascript',
        },
      ],
      [
        /<%/,
        {
          token: 'ejs.delimiter',
          nextEmbedded: 'javascript',
        },
      ],
      [
        /%>/,
        {
          token: 'ejs.delimiter',
          next: '@pop',
          nextEmbedded: '@pop',
        },
      ],
    ],
    doctype: [
      [/[^>]+/, 'metatag.content'],
      [/>/, 'metatag', '@pop'],
    ],
    comment: [
      [/-->/, 'comment', '@pop'],
      [/[^-]+/, 'comment.content'],
      [/./, 'comment.content'],
    ],
    otherTag: [
      [/\/?>/, 'delimiter', '@pop'],
      [
        /([\w\-]+)(=)(")/,
        [
          'attribute.name',
          'delimiter',
          {
            token: 'attribute.value',
            next: '@someHtmlTagAttributeValue',
          },
        ],
      ],
      [/[ \t\r\n]+/],
    ],
    someHtmlTagAttributeValue: [
      [/<%/, { token: '@rematch', next: '@ejs.root' }],
      [/[^"]/, 'attribute.value'],
      [
        /"/,
        {
          token: 'attribute.value',
          next: '@pop',
        },
      ],
    ],

    // -- BEGIN <script> tags handling
    // After <script
    script: [
      [/type/, 'attribute.name', '@scriptAfterType'],
      [/"([^"]*)"/, 'attribute.value'],
      [/'([^']*)'/, 'attribute.value'],
      [/[\w\-]+/, 'attribute.name'],
      [/=/, 'delimiter'],
      [
        />/,
        {
          token: 'delimiter',
          next: '@scriptEmbedded',
          nextEmbedded: 'javascript',
        },
      ],
      [/[ \t\r\n]+/],
      [
        /(<\/)(script\s*)(>)/,
        ['delimiter', 'tag', { token: 'delimiter', next: '@pop' }],
      ],
    ],
    // After <script ... type
    scriptAfterType: [
      [/=/, 'delimiter', '@scriptAfterTypeEquals'],
      [
        />/,
        {
          token: 'delimiter',
          next: '@scriptEmbedded',
          nextEmbedded: 'javascript',
        },
      ],
      [/[ \t\r\n]+/],
      [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    // After <script ... type =
    scriptAfterTypeEquals: [
      [
        /"([^"]*)"/,
        { token: 'attribute.value', switchTo: '@scriptWithCustomType.$1' },
      ],
      [
        /'([^']*)'/,
        { token: 'attribute.value', switchTo: '@scriptWithCustomType.$1' },
      ],
      [
        />/,
        {
          token: 'delimiter',
          next: '@scriptEmbedded',
          nextEmbedded: 'javascript',
        },
      ],
      [/[ \t\r\n]+/],
      [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    // After <script ... type = $S2
    scriptWithCustomType: [
      [
        />/,
        {
          token: 'delimiter',
          next: '@scriptEmbedded.$S2',
          nextEmbedded: '$S2',
        },
      ],
      [/"([^"]*)"/, 'attribute.value'],
      [/'([^']*)'/, 'attribute.value'],
      [/[\w\-]+/, 'attribute.name'],
      [/=/, 'delimiter'],
      [/[ \t\r\n]+/],
      [/<\/script\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    scriptEmbedded: [
      [/<\/script/, { token: '@rematch', next: '@pop', nextEmbedded: '@pop' }],
      [/[^<]+/, ''],
    ],
    // -- END <script> tags handling
    // -- BEGIN <style> tags handling
    // After <style
    style: [
      [/type/, 'attribute.name', '@styleAfterType'],
      [/"([^"]*)"/, 'attribute.value'],
      [/'([^']*)'/, 'attribute.value'],
      [/[\w\-]+/, 'attribute.name'],
      [/=/, 'delimiter'],
      [
        />/,
        {
          token: 'delimiter',
          next: '@styleEmbedded',
          nextEmbedded: 'text/css',
        },
      ],
      [/[ \t\r\n]+/],
      [
        /(<\/)(style\s*)(>)/,
        ['delimiter', 'tag', { token: 'delimiter', next: '@pop' }],
      ],
      [
        /(<\/)(mj-style\s*)(>)/,
        ['delimiter', 'tag', { token: 'delimiter', next: '@pop' }],
      ],
    ],
    // After <style ... type
    styleAfterType: [
      [/=/, 'delimiter', '@styleAfterTypeEquals'],
      [
        />/,
        {
          token: 'delimiter',
          next: '@styleEmbedded',
          nextEmbedded: 'text/css',
        },
      ],
      [/[ \t\r\n]+/],
      [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    // After <style ... type =
    styleAfterTypeEquals: [
      [
        /"([^"]*)"/,
        { token: 'attribute.value', switchTo: '@styleWithCustomType.$1' },
      ],
      [
        /'([^']*)'/,
        { token: 'attribute.value', switchTo: '@styleWithCustomType.$1' },
      ],
      [
        />/,
        {
          token: 'delimiter',
          next: '@styleEmbedded',
          nextEmbedded: 'text/css',
        },
      ],
      [/[ \t\r\n]+/],
      [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    // After <style ... type = $S2
    styleWithCustomType: [
      [
        />/,
        { token: 'delimiter', next: '@styleEmbedded.$S2', nextEmbedded: '$S2' },
      ],
      [/"([^"]*)"/, 'attribute.value'],
      [/'([^']*)'/, 'attribute.value'],
      [/[\w\-]+/, 'attribute.name'],
      [/=/, 'delimiter'],
      [/[ \t\r\n]+/],
      [/<\/style\s*>/, { token: '@rematch', next: '@pop' }],
    ],
    styleEmbedded: [
      [/<\/style/, { token: '@rematch', next: '@pop', nextEmbedded: '@pop' }],
      [/[^<]+/, ''],
    ],
  },
};
