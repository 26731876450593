/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/core';
import React, { Suspense } from 'react';
import { Button } from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { ReactComponent as EditIcon } from '@mailcrunch/assets/fontawesome/duotone/code.svg';
import { ReactComponent as CopyIcon } from '@mailcrunch/assets/fontawesome/duotone/copy.svg';
import { Link } from 'react-router-dom';
import { FiArchive as ArchiveIcon } from 'react-icons/fi';

import { WithDisclosure } from 'src/components/WithDisclosure';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import { DuplicateProjectModalContent } from '../DuplicateProjectModalContent';
import { GraphQLProjectContextProvider } from 'src/utils/useProject/useProject';
import { LoadingScreen } from 'src/LoadingScreen';
import { CreateProject } from '../CreateProject';

import {
  useProjectListPageQuery,
  useUpdateProjectArchiveStatusMutation,
} from 'src/generated/graphql';
import { routes } from 'src/routes';
import { ProjectListItemThumbnail } from './ProjectListItemThumbnail';
import { ProjectsFilter } from 'src/generated/graphql';

const actionButtonClassName = [
  tw`
  pl-2 py-1 pr-3
  flex flex-row items-center
  text-xs font-semibold
  rounded-full
  `,
  css`
    border: 1px solid #ffffffff;
    &:hover {
      /* box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1); */
      /* border: 1px solid #2b6cb0; */
    }
  `,
];

const fancyGradientButtonStyles = css`
  position: relative;
  z-index: 1;

  background-image: linear-gradient(
    45deg,
    hsla(229deg, 76%, 56%, 0),
    hsla(282deg, 71%, 65%, 0) 80%
  );
  &:hover {
    color: #ffffff;
    background-image: linear-gradient(
      45deg,
      hsla(229deg, 76%, 56%, 1),
      hsla(282deg, 71%, 65%, 1) 80%
    );
  }

  &::before,
  &::after {
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    &::before {
      background-image: linear-gradient(
        90deg,
        hsl(229deg 76% 56%),
        transparent
      );
    }
    &::after {
      background-image: linear-gradient(
        90deg,
        hsl(282deg 71% 65%),
        transparent
      );
    }
    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 50px;
      z-index: -1;
      filter: blur(14px);
      transform: scale(0.8);
    }
  }
  &:active {
    &::before,
    &::after {
      transform: scale(1);
    }
  }
`;

const pageSize = 30;

export const ProjectList = ({
  orgSlug,
  onSelectProject,
  filterByName,
  projectFilter,
  cursor,
  hasMore,
  setCursor,
  setHasMore,
}: {
  orgSlug: string;
  onSelectProject: ({ id, slug }: { id: string; slug: string }) => void;
  filterByName: string;
  projectFilter: ProjectsFilter;
  cursor: string | null | undefined;
  hasMore: boolean;
  setCursor: (cursor: string | null | undefined) => void;
  setHasMore: (hasMore: boolean) => void;
}) => {
  const { data, loading: loadingProjects, fetchMore } = useProjectListPageQuery(
    {
      variables: {
        organizationSlug: orgSlug,
        filter: {
          archivedOrNotArchived: projectFilter.archivedOrNotArchived,
          relationship: projectFilter.relationship,
        },
        filterByName: filterByName,
        limit: pageSize,
      },
    },
  );

  const [setIsArchived] = useUpdateProjectArchiveStatusMutation();

  if (loadingProjects) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div css={tw`flex flex-col w-full`}>
        {data?.organization?.projects?.length === 0 && filterByName !== '' ? (
          <div
            css={tw`flex flex-col items-center justify-center border rounded-md m-4 min-h-[380px]`}
          >
            <h1 css={tw`p-2 font-medium text-2xl pb-6`}>No Results Found</h1>
            <p css={tw`mb-5`}>
              Your search for "{filterByName}" did not return any results.
            </p>
            <CreateProject orgSlug={orgSlug} />
          </div>
        ) : data?.organization?.projects?.length === 0 &&
          filterByName === '' ? (
          <div
            css={tw`flex flex-col items-center justify-center border rounded-md m-4 min-h-[380px]`}
          >
            <h1 css={tw`p-2 font-medium text-2xl pb-6`}>No projects, yet!</h1>
            <CreateProject orgSlug={orgSlug} />
          </div>
        ) : null}

        {data?.organization?.projects.map((project) => (
          <div
            key={project.id}
            className="group"
            css={[
              tw`border-gray-100 border-none border-solid border-b bg-white`,
              css`
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: stretch;
                padding: 10px;
                color: inherit;
                cursor: pointer;
                border-width: 0;
                border-top-width: 0;

                &:not(:last-child) {
                  border-bottom-width: 1px;
                }
                &:hover {
                  background-color: #f7fafc;
                }
              `,
            ]}
            onClick={() =>
              onSelectProject({ id: project.id, slug: project.slug })
            }
          >
            <ProjectListItemThumbnail
              thumbnailUrl={project.latestRevision.thumbnail?.remoteUrl}
              projectId={project.id}
              orgId={project.organizationId}
            />
            <div css={tw`flex-grow pl-4 flex flex-col items-start mt-1`}>
              <div css={tw`font-semibold text-lg ml-1`}>
                {project.name || project.slug || project.id}
              </div>

              <p
                css={tw`text-sm m-0 flex justify-between w-full mt-1 ml-2 pr-5`}
              >
                <span>
                  <small>Created by</small>{' '}
                  <span>{project.createdBy.name}</span>
                </span>

                <Link
                  css={css`
                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                  to={routes.history.getPath({
                    orgSlug,
                    projectSlug: project.slug,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <small>Last updated</small>{' '}
                  {formatDistanceToNow(
                    new Date(project.latestRevision.createdAt),
                  ).replace(/about/, ' ')}{' '}
                  <small>ago</small>
                </Link>
              </p>

              <div css={tw`flex flex-row w-full mt-auto mb-1`}>
                <Link
                  to={routes.projectEditor.getPath({
                    orgSlug,
                    projectSlug: project.slug,
                  })}
                  css={[
                    actionButtonClassName,
                    tw`no-underline hover:shadow-lg border-none bg-transparent`,
                    css`
                      .edit-icon {
                        * {
                          fill: #f56565;
                        }
                        .fa-primary {
                          fill: #a3bffa;
                        }
                      }
                      &:hover {
                        .edit-icon {
                          * {
                            fill: #f6e05e;
                            opacity: 1;
                          }
                          .fa-primary {
                            fill: #a3bffa;
                          }
                        }
                      }
                    `,
                    fancyGradientButtonStyles,
                  ]}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EditIcon
                    className="edit-icon"
                    css={css`
                      width: 14px;
                      margin-right: 0.5em;
                    `}
                  />
                  Code
                </Link>

                <WithDisclosure>
                  {({ isOpen, onClose, onOpen }) => (
                    <React.Fragment>
                      <button
                        onClick={onOpen}
                        css={[
                          actionButtonClassName,
                          tw`no-underline hover:shadow-lg border-none bg-transparent ml-2`,
                          fancyGradientButtonStyles,
                          css`
                            .icon {
                              * {
                                fill: #a3bffa;
                              }
                            }
                            &:hover {
                              .icon {
                                * {
                                  fill: #a3bffa;
                                  opacity: 0.8;
                                }
                                .fa-secondary {
                                  fill: #f6e05e;
                                }
                              }
                            }
                          `,
                        ]}
                      >
                        <CopyIcon
                          className="icon"
                          css={css`
                            width: 10px;
                            margin-left: 0.25rem;
                            margin-right: 0.25rem;
                          `}
                        />
                        Duplicate
                      </button>
                      <Modal
                        isOpen={isOpen}
                        onClose={onClose}
                        closeOnOverlayClick
                        size="sm"
                      >
                        <ModalOverlay />
                        {isOpen && (
                          <Suspense fallback={<LoadingScreen />}>
                            <GraphQLProjectContextProvider
                              projectSlug={project.slug}
                              orgSlug={orgSlug}
                            >
                              <DuplicateProjectModalContent
                                currentProjectId={project.id}
                                currentProjectName={project.name}
                                currentOrgSlug={orgSlug}
                                onClose={onClose}
                              />
                            </GraphQLProjectContextProvider>
                          </Suspense>
                        )}
                      </Modal>
                    </React.Fragment>
                  )}
                </WithDisclosure>

                {!project.archived ? (
                  <button
                    css={[
                      actionButtonClassName,
                      tw`ml-auto text-gray-600 bg-transparent cursor-pointer`,
                      css`
                        border-color: transparent;
                        &:hover {
                          color: #ffffff;
                          background: linear-gradient(
                            45deg,
                            rgb(129 130 145),
                            rgb(108 108 137) 80%
                          );
                          .icon * {
                            fill: #ffffff;
                          }
                        }
                      `,
                    ]}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await setIsArchived({
                        variables: {
                          projectId: project.id,
                          isArchived: !project.archived,
                        },
                      });
                    }}
                    title="Archive project"
                  >
                    <ArchiveIcon
                      className="archive-icon icon"
                      css={css`
                        width: 14px;
                        margin-left: 0.25rem;
                        margin-right: 0.25rem;
                        * {
                          fill: #bbb;
                        }
                      `}
                    />
                    Archive
                  </button>
                ) : (
                  <button
                    css={[
                      actionButtonClassName,
                      tw`ml-auto text-gray-600`,
                      css`
                        border-color: transparent;
                        &:hover {
                          border-color: #cbd5e0;
                          color: #718096;
                        }
                      `,
                    ]}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await setIsArchived({
                        variables: {
                          projectId: project.id,
                          isArchived: false,
                        },
                      });
                    }}
                    title="Archive project"
                  >
                    <ArchiveIcon
                      className="archive-icon"
                      css={css`
                        width: 14px;
                        margin-right: 0.5em;
                        * {
                          fill: #bbb;
                        }
                      `}
                    />
                    Unarchive
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div css={tw`flex flex-col items-center justify-center mt-auto`}>
        {!loadingProjects && (
          <Button
            css={css`
              background-color: #5d88f2;
              color: #fff;
              margin-bottom: 1.5rem;
              margin-top: 1rem;
              :hover {
                background-color: #6e95f8;
              }
              :disabled {
                background-color: #8f8d8d;
                cursor: not-allowed;
                :hover {
                  background-color: #6f6d6d;
                }
              }
            `}
            disabled={
              !hasMore ||
              loadingProjects ||
              (data?.organization?.projects.length ?? 0) < pageSize
            }
            onClick={() => {
              setCursor(data?.organization?.projects.slice(-1)[0].id);

              fetchMore({
                variables: {
                  cursor: data?.organization?.projects.slice(-1)[0].id,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  if (
                    (fetchMoreResult?.organization?.projects.length ?? 0) <
                    pageSize
                  ) {
                    setHasMore(false);
                  }
                  return Object.assign({}, prev, {
                    organization: {
                      ...prev.organization,
                      projects: [
                        ...(prev.organization?.projects ?? []),
                        ...(fetchMoreResult?.organization?.projects ?? []),
                      ],
                    },
                  });
                },
              });
            }}
          >
            {!hasMore ||
            (data?.organization?.projects.length ?? 0) < pageSize ? (
              <span>No more projects to load</span>
            ) : (
              <span>Load more</span>
            )}
          </Button>
        )}
      </div>
    </>
  );
};
