import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// references https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useDebouncedEffect(
  callback: EffectCallback,
  delay: number,
  deps: DependencyList,
) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setTimeout(() => savedCallback.current(), delay);
    return () => clearTimeout(id);
  }, [delay, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
}
