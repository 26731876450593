import { useEffect, useState } from 'react';
import getIsOnline from 'is-online';
import constate from 'constate';

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    const checkIsOnline = () => getIsOnline().then(setIsOnline);
    const interval = setInterval(checkIsOnline, 15_000);

    checkIsOnline();

    return () => clearInterval(interval);
  }, []);

  return {
    isOnline,
  };
};

export const [IsOnlineContextProvider, useIsOnlineContext] = constate(
  useIsOnline,
);
