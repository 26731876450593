import React from 'react';
import { useOrgPageQuery } from 'src/generated/graphql';

export const Org = () => {
  const { data } = useOrgPageQuery();
  return (
    <>
      Org
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
};
