/** @jsxImportSource @emotion/react */
import _ from 'lodash';
import { css } from '@emotion/core';
import tw from 'twin.macro';
import { CircularProgress } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageListEntry } from './ImageListEntry';
import {
  ProjectFile,
  useProjectContext,
} from 'src/utils/useProject/useProject';

export const ImageList: React.FC<{
  addFilesToProject: (files: ProjectFile[]) => {};
  removeFileFromProject: (file: ProjectFile) => void;
  onSelectImage: (path: string) => void;
}> = ({ addFilesToProject, onSelectImage: onClick, removeFileFromProject }) => {
  const [, { uploadFile, uploadingAndUploadedImages }] = useProjectContext();

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: useCallback(
      async (acceptedFiles: File[]) => {
        console.log('received dropped files');
        const uploadedFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            const relativePath = `./images/${file.name}`;
            return {
              relativePath,
              remoteUrl: await uploadFile(file, relativePath),
            };
          }),
        );
        addFilesToProject(uploadedFiles);
      },
      [uploadFile, addFilesToProject],
    ),
  });

  return (
    <div {...getRootProps()} css={[tw`relative flex flex-row w-full h-full`]}>
      <div
        css={css`
          background-color: #3d424b;
          color: #ffffff;
          width: 100%;
          height: 100%;
          overflow-y: auto;
        `}
      >
        <div
          css={[
            tw`w-full h-full items-center justify-center overflow-y-auto`,
            css`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
            `,
          ]}
        >
          {isDragActive && (
            <div
              css={tw`w-full h-full flex justify-center items-center absolute pointer-events-none`}
              style={{ background: '#3d424bee' }}
            >
              Drop to upload
            </div>
          )}
          {uploadingAndUploadedImages.length ? (
            _.orderBy(uploadingAndUploadedImages, ['relativePath', 'asc']).map(
              (x, i) => (
                <div
                  key={x.relativePath + 'uploading'}
                  css={css`
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                  `}
                >
                  {'remoteUrl' in x && x.remoteUrl ? (
                    <ImageListEntry
                      key={x.relativePath + 'uploaded'}
                      relativePath={x.relativePath}
                      imageUrl={x.remoteUrl}
                      onClick={onClick}
                      onRequestDeleteFile={removeFileFromProject}
                    />
                  ) : (
                    <div css={tw`py-6 self-center`}>
                      <CircularProgress isIndeterminate color="gray.400" />
                    </div>
                  )}
                </div>
              ),
            )
          ) : (
            <div
              css={tw`w-full h-full flex justify-center items-center text-center`}
            >
              Drag images here to upload
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
