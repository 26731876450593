import _ from 'lodash';
import { useCallback, useState } from 'react';

export const useWindowManager = () => {
  const [windows, setWindows] = useState<Array<Window>>([]);
  const createWindow = useCallback(() => {
    const newWindow = window.open('/display');
    if (newWindow) {
      setWindows(windows.concat(newWindow));
      const cleanup = () => {
        setWindows(_.without(windows, newWindow));
      };
      newWindow.addEventListener('close', cleanup);
      return () => {
        newWindow.removeEventListener('close', cleanup);
      };
    }
  }, [windows]);
  return {
    windows,
    createWindow,
  };
};
