import React from 'react';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());

export const FirebaseLoginWidget = () => {
  return (
    <div
      ref={(el) => {
        if (!el) return;
        // The start method will wait until the DOM is loaded.
        ui.start(el, {
          signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID,
          ],
          signInFlow: 'popup',
          // tosUrl and privacyPolicyUrl accept either url string or a callback
          // function.
          // Terms of service url/callback.
          // tosUrl: '<your-tos-url>',
          // // Privacy policy url/callback.
          // privacyPolicyUrl: function () {
          //   window.location.assign('<your-privacy-policy-url>');
          // },
        });
      }}
    />
  );
};
