import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
};

export type Asset = {
  __typename?: 'Asset';
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdByUserId: Scalars['String'];
  id: Scalars['ID'];
  relativePath: Scalars['String'];
  remoteUrl: Scalars['String'];
};

export type AssetInput = {
  relativePath: Scalars['String'];
  remoteUrl: Scalars['String'];
};

export enum AuthTokenType {
  Firebase = 'firebase'
}

export type CreateOrganizationPayload = ErrorNoDuplicates | Membership;

export type CreateProjectPayload = ErrorNoDuplicates | Project;

export type ErrorNoDuplicates = {
  __typename?: 'ErrorNoDuplicates';
  message: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  organization: Organization;
  organizationId: Scalars['String'];
  role: MembershipRole;
  user: User;
  userId: Scalars['ID'];
};

export enum MembershipRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<Membership>;
  addAssetsToProject?: Maybe<Project>;
  createOrganization?: Maybe<CreateOrganizationPayload>;
  createProject?: Maybe<CreateProjectPayload>;
  createStandingInvitation?: Maybe<StandingInvitation>;
  deactivateStandingInvitation?: Maybe<StandingInvitation>;
  deleteInvitation?: Maybe<StandingInvitation>;
  deleteOrganization?: Maybe<Organization>;
  duplicateProject?: Maybe<Project>;
  initializeCurrentUser?: Maybe<User>;
  removeAssetsFromProject?: Maybe<Project>;
  removeUserFromOrganization?: Maybe<Membership>;
  renameProject?: Maybe<Project>;
  restoreProjectToRevision?: Maybe<Project>;
  setOrgLogoUrl?: Maybe<Organization>;
  setOrgName?: Maybe<Organization>;
  setOrgSlug?: Maybe<SetOrgSlugPayload>;
  setRevisionComment?: Maybe<Revision>;
  setRevisionThumbnailByURL?: Maybe<Revision>;
  updateMembershipRole?: Maybe<Membership>;
  updateProjectArchiveStatus?: Maybe<Project>;
  updateProjectAssetNameTransformTemplate?: Maybe<Project>;
  updateProjectSrc?: Maybe<Project>;
};


export type MutationAcceptInvitationArgs = {
  code: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationAddAssetsToProjectArgs = {
  files: Array<AssetInput>;
  projectId: Scalars['ID'];
};


export type MutationCreateOrganizationArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  src?: InputMaybe<Scalars['String']>;
};


export type MutationCreateStandingInvitationArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  role: MembershipRole;
};


export type MutationDeactivateStandingInvitationArgs = {
  code: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteInvitationArgs = {
  code: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDuplicateProjectArgs = {
  currentProjectId: Scalars['ID'];
  newProjectName: Scalars['String'];
  newProjectSlug?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationInitializeCurrentUserArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  token: Scalars['String'];
  tokenType: AuthTokenType;
};


export type MutationRemoveAssetsFromProjectArgs = {
  assetIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};


export type MutationRemoveUserFromOrganizationArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRenameProjectArgs = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationRestoreProjectToRevisionArgs = {
  projectId: Scalars['ID'];
  revisionToRevertToId: Scalars['ID'];
};


export type MutationSetOrgLogoUrlArgs = {
  newLogoUrl: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationSetOrgNameArgs = {
  newName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationSetOrgSlugArgs = {
  newSlug: Scalars['String'];
  organizationId?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationSetRevisionCommentArgs = {
  comment: Scalars['String'];
  revisionId: Scalars['ID'];
};


export type MutationSetRevisionThumbnailByUrlArgs = {
  revisionId: Scalars['ID'];
  thumbnailRemoteUrl: Scalars['String'];
};


export type MutationUpdateMembershipRoleArgs = {
  organizationId: Scalars['ID'];
  role: MembershipRole;
  userId: Scalars['ID'];
};


export type MutationUpdateProjectArchiveStatusArgs = {
  isArchived: Scalars['Boolean'];
  projectId: Scalars['ID'];
};


export type MutationUpdateProjectAssetNameTransformTemplateArgs = {
  assetNameTransformTemplate: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationUpdateProjectSrcArgs = {
  projectId: Scalars['ID'];
  src: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['DateTime'];
  dateCreated: Scalars['DateTime'];
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  memberships: Array<Membership>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  slug: Scalars['String'];
  standingInvitations: Array<StandingInvitation>;
};


export type OrganizationProjectArgs = {
  projectId?: InputMaybe<Scalars['ID']>;
};


export type OrganizationProjectsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  filter?: ProjectsFilter;
  filterByName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type OrganizationDataForNonMembers = {
  __typename?: 'OrganizationDataForNonMembers';
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  createdByUserId: Scalars['String'];
  id: Scalars['ID'];
  lastUpdatedAt: Scalars['DateTime'];
  latestRevision: Revision;
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['String'];
  revision: Revision;
  revisions: Array<Revision>;
  slug: Scalars['String'];
};


export type ProjectRevisionArgs = {
  revisionId: Scalars['ID'];
};


export type ProjectRevisionsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export enum ProjectFilterArchivedOrNotArchived {
  Archived = 'ARCHIVED',
  NotArchived = 'NOT_ARCHIVED'
}

export enum ProjectFilterRelationship {
  All = 'ALL',
  My = 'MY'
}

export type ProjectsFilter = {
  archivedOrNotArchived: ProjectFilterArchivedOrNotArchived;
  relationship: ProjectFilterRelationship;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  isOrganizationSlugAvailable?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  organizationDataForNonMembers?: Maybe<OrganizationDataForNonMembers>;
  project?: Maybe<Project>;
};


export type QueryIsOrganizationSlugAvailableArgs = {
  slug: Scalars['String'];
};


export type QueryOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['ID']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationDataForNonMembersArgs = {
  invitationCode: Scalars['String'];
  organizationSlug: Scalars['String'];
};


export type QueryProjectArgs = {
  organizationSlug: Scalars['String'];
  slug: Scalars['String'];
};

export type Revision = {
  __typename?: 'Revision';
  assetNameTransformTemplate?: Maybe<Scalars['String']>;
  assets: Array<Asset>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['ID'];
  name: Scalars['String'];
  src: Scalars['String'];
  thumbnail?: Maybe<Asset>;
};

export type SetOrgSlugPayload = ErrorNoDuplicates | Organization;

export type StandingInvitation = {
  __typename?: 'StandingInvitation';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: User;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDeactivated: Scalars['Boolean'];
  /** The role a user would have using this invitation */
  role: MembershipRole;
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseUID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  memberships: Array<Membership>;
  name?: Maybe<Scalars['String']>;
};

export type OrganizationLogoQueryVariables = Exact<{
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationLogoQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, slug: string, logoUrl?: string | null } | null };

export type AcceptInvitationMutationVariables = Exact<{
  code: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation?: { __typename?: 'Membership', id: string, userId: string, role: MembershipRole } | null };

export type HandleLoginSuccessQueryVariables = Exact<{ [key: string]: never; }>;


export type HandleLoginSuccessQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, email: string, name?: string | null, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, slug: string } }> } | null };

export type InitializeCurrentUserMutationVariables = Exact<{
  name: Scalars['String'];
  token: Scalars['String'];
  tokenType: AuthTokenType;
}>;


export type InitializeCurrentUserMutation = { __typename?: 'Mutation', initializeCurrentUser?: { __typename?: 'User', id: string, email: string, name?: string | null, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, slug: string } }> } | null };

export type HandleLoginSuccessCurrentUserFragmentFragment = { __typename?: 'User', id: string, email: string, name?: string | null, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, slug: string } }> };

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization?: { __typename?: 'ErrorNoDuplicates', message: string } | { __typename?: 'Membership', id: string, role: MembershipRole, organization: { __typename?: 'Organization', id: string, slug: string, name?: string | null, logoUrl?: string | null } } | null };

export type IsOrganizationSlugAvailableQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type IsOrganizationSlugAvailableQuery = { __typename?: 'Query', isOrganizationSlugAvailable?: boolean | null };

export type OrgPageQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgPageQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, email: string, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, name?: string | null } }> } | null };

export type OrgSettingPageQueryVariables = Exact<{
  organizationSlug: Scalars['String'];
}>;


export type OrgSettingPageQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, email: string, memberships: Array<{ __typename?: 'Membership', id: string, role: MembershipRole, organizationId: string, organization: { __typename?: 'Organization', id: string, slug: string, name?: string | null, logoUrl?: string | null } }> } | null, organization?: { __typename?: 'Organization', id: string, slug: string, memberships: Array<{ __typename?: 'Membership', id: string, role: MembershipRole }> } | null };

export type SetOrgNameMutationVariables = Exact<{
  newName: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type SetOrgNameMutation = { __typename?: 'Mutation', setOrgName?: { __typename?: 'Organization', id: string, name?: string | null } | null };

export type SetOrgLogoUrlMutationVariables = Exact<{
  newLogoUrl: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type SetOrgLogoUrlMutation = { __typename?: 'Mutation', setOrgLogoUrl?: { __typename?: 'Organization', id: string, logoUrl?: string | null } | null };

export type DeleteOrganizationMutationVariables = Exact<{
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization?: { __typename?: 'Organization', id: string } | null };

export type OrgMembersPageQueryVariables = Exact<{
  organizationSlug: Scalars['String'];
}>;


export type OrgMembersPageQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, name?: string | null, memberships: Array<{ __typename?: 'Membership', id: string, role: MembershipRole, organizationId: string }> } | null, organization?: { __typename?: 'Organization', id: string, slug: string, name?: string | null, dateCreated: any, createdAt: any, memberships: Array<{ __typename?: 'Membership', id: string, role: MembershipRole, user: { __typename?: 'User', id: string, name?: string | null, email: string, avatarUrl?: string | null } }> } | null };

export type OrgStandingInvitationsQueryVariables = Exact<{
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type OrgStandingInvitationsQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, standingInvitations: Array<{ __typename?: 'StandingInvitation', id: string, role: MembershipRole, code: string, createdAt: any }> } | null };

export type StandingInvitationVisibleDataFragment = { __typename?: 'StandingInvitation', id: string, role: MembershipRole, code: string, createdAt: any };

export type CreateStandingInvitationMutationVariables = Exact<{
  role: MembershipRole;
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type CreateStandingInvitationMutation = { __typename?: 'Mutation', createStandingInvitation?: { __typename?: 'StandingInvitation', id: string, role: MembershipRole, code: string, createdAt: any } | null };

export type DeleteStandingInvitationMutationVariables = Exact<{
  code: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
}>;


export type DeleteStandingInvitationMutation = { __typename?: 'Mutation', deleteInvitation?: { __typename?: 'StandingInvitation', id: string, role: MembershipRole, code: string } | null };

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveUserFromOrganizationMutation = { __typename?: 'Mutation', removeUserFromOrganization?: { __typename?: 'Membership', id: string } | null };

export type UpdateMembershipRoleMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
  role: MembershipRole;
}>;


export type UpdateMembershipRoleMutation = { __typename?: 'Mutation', updateMembershipRole?: { __typename?: 'Membership', id: string, role: MembershipRole, userId: string } | null };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String'];
  slug: Scalars['String'];
  organizationSlug: Scalars['String'];
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject?: { __typename?: 'ErrorNoDuplicates', message: string } | { __typename?: 'Project', id: string } | null };

export type OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', memberships: Array<{ __typename?: 'Membership', organization: { __typename?: 'Organization', id: string, slug: string, name?: string | null } }> } | null };

export type HistoryPageRevisionFragment = { __typename?: 'Revision', id: string, src: string, name: string, comment?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string }> };

export type HistoryPageQueryVariables = Exact<{
  slug: Scalars['String'];
  organizationSlug: Scalars['String'];
  cursor?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type HistoryPageQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, slug: string, name: string, revisions: Array<{ __typename?: 'Revision', id: string, src: string, name: string, comment?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string }> }>, latestRevision: { __typename?: 'Revision', id: string, name: string } } | null };

export type SetRevisionCommentMutationVariables = Exact<{
  revisionId: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type SetRevisionCommentMutation = { __typename?: 'Mutation', setRevisionComment?: { __typename?: 'Revision', id: string, comment?: string | null } | null };

export type RestoreProjectToRevisionMutationVariables = Exact<{
  projectId: Scalars['ID'];
  revisionToRevertToId: Scalars['ID'];
}>;


export type RestoreProjectToRevisionMutation = { __typename?: 'Mutation', restoreProjectToRevision?: { __typename?: 'Project', id: string, revisions: Array<{ __typename?: 'Revision', id: string, src: string, name: string, comment?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string }> }> } | null };

export type OrgUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgUrlQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, logoUrl?: string | null, slug: string } }> } | null };

export type ProjectListPageQueryVariables = Exact<{
  organizationSlug: Scalars['String'];
  filter: ProjectsFilter;
  cursor?: InputMaybe<Scalars['ID']>;
  filterByName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ProjectListPageQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, slug: string, projects: Array<{ __typename?: 'Project', id: string, archived: boolean, createdAt: any, organizationId: string, slug: string, name: string, createdBy: { __typename?: 'User', id: string, name?: string | null }, latestRevision: { __typename?: 'Revision', id: string, createdAt: any, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null } }> } | null };

export type UpdateProjectArchiveStatusMutationVariables = Exact<{
  isArchived: Scalars['Boolean'];
  projectId: Scalars['ID'];
}>;


export type UpdateProjectArchiveStatusMutation = { __typename?: 'Mutation', updateProjectArchiveStatus?: { __typename?: 'Project', id: string, archived: boolean } | null };

export type UpdateAndViewProjectPreviewQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type UpdateAndViewProjectPreviewQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, project?: { __typename?: 'Project', id: string, latestRevision: { __typename?: 'Revision', id: string, src: string, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string }> } } | null } | null };

export type SetRevisionThumbnailMutationVariables = Exact<{
  revisionId: Scalars['ID'];
  thumbnailRemoteUrl: Scalars['String'];
}>;


export type SetRevisionThumbnailMutation = { __typename?: 'Mutation', setRevisionThumbnailByURL?: { __typename?: 'Revision', id: string, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null } | null };

export type TopNavQueryVariables = Exact<{ [key: string]: never; }>;


export type TopNavQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, name?: string | null, email: string, avatarUrl?: string | null, memberships: Array<{ __typename?: 'Membership', id: string, organization: { __typename?: 'Organization', id: string, slug: string, name?: string | null, logoUrl?: string | null } }> } | null };

export type TopNavOrgFragment = { __typename?: 'Organization', id: string, slug: string, name?: string | null, logoUrl?: string | null };

export type UseProjectQueryVariables = Exact<{
  projectSlug: Scalars['String'];
  orgSlug: Scalars['String'];
}>;


export type UseProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: string, createdAt: any, archived: boolean, slug: string, name: string, createdBy: { __typename?: 'User', id: string, name?: string | null }, latestRevision: { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> } } | null };

export type ProjectLatestRevisionFragment = { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> };

export type UpdateProjectSrcMutationVariables = Exact<{
  projectId: Scalars['ID'];
  src: Scalars['String'];
}>;


export type UpdateProjectSrcMutation = { __typename?: 'Mutation', updateProjectSrc?: { __typename?: 'Project', id: string, latestRevision: { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> } } | null };

export type RenameProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  newName: Scalars['String'];
}>;


export type RenameProjectMutation = { __typename?: 'Mutation', renameProject?: { __typename?: 'Project', id: string, name: string } | null };

export type AddAssetsToProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  files: Array<AssetInput> | AssetInput;
}>;


export type AddAssetsToProjectMutation = { __typename?: 'Mutation', addAssetsToProject?: { __typename?: 'Project', id: string, latestRevision: { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> } } | null };

export type RemoveAssetsFromProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  assetIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type RemoveAssetsFromProjectMutation = { __typename?: 'Mutation', removeAssetsFromProject?: { __typename?: 'Project', id: string, latestRevision: { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> } } | null };

export type UpdateProjectAssetNameTransformTemplateMutationVariables = Exact<{
  assetNameTransformTemplate: Scalars['String'];
  projectId: Scalars['ID'];
}>;


export type UpdateProjectAssetNameTransformTemplateMutation = { __typename?: 'Mutation', updateProjectAssetNameTransformTemplate?: { __typename?: 'Project', id: string, latestRevision: { __typename?: 'Revision', id: string, name: string, src: string, assetNameTransformTemplate?: string | null, createdAt: any, createdBy: { __typename?: 'User', id: string, name?: string | null }, thumbnail?: { __typename?: 'Asset', id: string, remoteUrl: string } | null, assets: Array<{ __typename?: 'Asset', id: string, relativePath: string, remoteUrl: string, createdAt: any }> } } | null };

export type DuplicateProjectMutationVariables = Exact<{
  newProjectName: Scalars['String'];
  organizationSlug: Scalars['String'];
  currentProjectId: Scalars['ID'];
}>;


export type DuplicateProjectMutation = { __typename?: 'Mutation', duplicateProject?: { __typename?: 'Project', id: string, slug: string } | null };

export const HandleLoginSuccessCurrentUserFragmentFragmentDoc = gql`
    fragment HandleLoginSuccessCurrentUserFragment on User {
  id
  email
  name
  memberships {
    id
    organization {
      id
      slug
    }
  }
}
    `;
export const StandingInvitationVisibleDataFragmentDoc = gql`
    fragment StandingInvitationVisibleData on StandingInvitation {
  id
  role
  code
  createdAt
}
    `;
export const HistoryPageRevisionFragmentDoc = gql`
    fragment HistoryPageRevision on Revision {
  id
  src
  name
  comment
  createdAt
  createdBy {
    id
    name
  }
  thumbnail {
    id
    relativePath
    remoteUrl
  }
  assets {
    id
    relativePath
    remoteUrl
  }
}
    `;
export const TopNavOrgFragmentDoc = gql`
    fragment TopNavOrg on Organization {
  id
  slug
  name
  logoUrl
}
    `;
export const ProjectLatestRevisionFragmentDoc = gql`
    fragment ProjectLatestRevision on Revision {
  id
  name
  src
  assetNameTransformTemplate
  createdAt
  createdBy {
    id
    name
  }
  thumbnail {
    id
    remoteUrl
  }
  assets {
    id
    relativePath
    remoteUrl
    createdAt
  }
}
    `;
export const OrganizationLogoDocument = gql`
    query OrganizationLogo($organizationSlug: String) {
  organization(organizationSlug: $organizationSlug) {
    id
    slug
    logoUrl
  }
}
    `;

/**
 * __useOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationLogoQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useOrganizationLogoQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationLogoQuery, OrganizationLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationLogoQuery, OrganizationLogoQueryVariables>(OrganizationLogoDocument, options);
      }
export function useOrganizationLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationLogoQuery, OrganizationLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationLogoQuery, OrganizationLogoQueryVariables>(OrganizationLogoDocument, options);
        }
export type OrganizationLogoQueryHookResult = ReturnType<typeof useOrganizationLogoQuery>;
export type OrganizationLogoLazyQueryHookResult = ReturnType<typeof useOrganizationLogoLazyQuery>;
export type OrganizationLogoQueryResult = Apollo.QueryResult<OrganizationLogoQuery, OrganizationLogoQueryVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($code: String!, $organizationSlug: String) {
  acceptInvitation(code: $code, organizationSlug: $organizationSlug) {
    id
    userId
    role
  }
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const HandleLoginSuccessDocument = gql`
    query HandleLoginSuccess {
  currentUser {
    ...HandleLoginSuccessCurrentUserFragment
  }
}
    ${HandleLoginSuccessCurrentUserFragmentFragmentDoc}`;

/**
 * __useHandleLoginSuccessQuery__
 *
 * To run a query within a React component, call `useHandleLoginSuccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandleLoginSuccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandleLoginSuccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useHandleLoginSuccessQuery(baseOptions?: Apollo.QueryHookOptions<HandleLoginSuccessQuery, HandleLoginSuccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HandleLoginSuccessQuery, HandleLoginSuccessQueryVariables>(HandleLoginSuccessDocument, options);
      }
export function useHandleLoginSuccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HandleLoginSuccessQuery, HandleLoginSuccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HandleLoginSuccessQuery, HandleLoginSuccessQueryVariables>(HandleLoginSuccessDocument, options);
        }
export type HandleLoginSuccessQueryHookResult = ReturnType<typeof useHandleLoginSuccessQuery>;
export type HandleLoginSuccessLazyQueryHookResult = ReturnType<typeof useHandleLoginSuccessLazyQuery>;
export type HandleLoginSuccessQueryResult = Apollo.QueryResult<HandleLoginSuccessQuery, HandleLoginSuccessQueryVariables>;
export const InitializeCurrentUserDocument = gql`
    mutation InitializeCurrentUser($name: String!, $token: String!, $tokenType: AuthTokenType!) {
  initializeCurrentUser(name: $name, token: $token, tokenType: $tokenType) {
    ...HandleLoginSuccessCurrentUserFragment
  }
}
    ${HandleLoginSuccessCurrentUserFragmentFragmentDoc}`;
export type InitializeCurrentUserMutationFn = Apollo.MutationFunction<InitializeCurrentUserMutation, InitializeCurrentUserMutationVariables>;

/**
 * __useInitializeCurrentUserMutation__
 *
 * To run a mutation, you first call `useInitializeCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeCurrentUserMutation, { data, loading, error }] = useInitializeCurrentUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      token: // value for 'token'
 *      tokenType: // value for 'tokenType'
 *   },
 * });
 */
export function useInitializeCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<InitializeCurrentUserMutation, InitializeCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitializeCurrentUserMutation, InitializeCurrentUserMutationVariables>(InitializeCurrentUserDocument, options);
      }
export type InitializeCurrentUserMutationHookResult = ReturnType<typeof useInitializeCurrentUserMutation>;
export type InitializeCurrentUserMutationResult = Apollo.MutationResult<InitializeCurrentUserMutation>;
export type InitializeCurrentUserMutationOptions = Apollo.BaseMutationOptions<InitializeCurrentUserMutation, InitializeCurrentUserMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($name: String!, $slug: String!) {
  createOrganization(name: $name, slug: $slug) {
    ... on Membership {
      id
      role
      organization {
        ...TopNavOrg
      }
    }
    ... on ErrorNoDuplicates {
      message
    }
  }
}
    ${TopNavOrgFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const IsOrganizationSlugAvailableDocument = gql`
    query IsOrganizationSlugAvailable($slug: String!) {
  isOrganizationSlugAvailable(slug: $slug)
}
    `;

/**
 * __useIsOrganizationSlugAvailableQuery__
 *
 * To run a query within a React component, call `useIsOrganizationSlugAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOrganizationSlugAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOrganizationSlugAvailableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useIsOrganizationSlugAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsOrganizationSlugAvailableQuery, IsOrganizationSlugAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsOrganizationSlugAvailableQuery, IsOrganizationSlugAvailableQueryVariables>(IsOrganizationSlugAvailableDocument, options);
      }
export function useIsOrganizationSlugAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsOrganizationSlugAvailableQuery, IsOrganizationSlugAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsOrganizationSlugAvailableQuery, IsOrganizationSlugAvailableQueryVariables>(IsOrganizationSlugAvailableDocument, options);
        }
export type IsOrganizationSlugAvailableQueryHookResult = ReturnType<typeof useIsOrganizationSlugAvailableQuery>;
export type IsOrganizationSlugAvailableLazyQueryHookResult = ReturnType<typeof useIsOrganizationSlugAvailableLazyQuery>;
export type IsOrganizationSlugAvailableQueryResult = Apollo.QueryResult<IsOrganizationSlugAvailableQuery, IsOrganizationSlugAvailableQueryVariables>;
export const OrgPageDocument = gql`
    query OrgPage {
  currentUser {
    id
    email
    memberships {
      id
      organization {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useOrgPageQuery__
 *
 * To run a query within a React component, call `useOrgPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgPageQuery(baseOptions?: Apollo.QueryHookOptions<OrgPageQuery, OrgPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgPageQuery, OrgPageQueryVariables>(OrgPageDocument, options);
      }
export function useOrgPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgPageQuery, OrgPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgPageQuery, OrgPageQueryVariables>(OrgPageDocument, options);
        }
export type OrgPageQueryHookResult = ReturnType<typeof useOrgPageQuery>;
export type OrgPageLazyQueryHookResult = ReturnType<typeof useOrgPageLazyQuery>;
export type OrgPageQueryResult = Apollo.QueryResult<OrgPageQuery, OrgPageQueryVariables>;
export const OrgSettingPageDocument = gql`
    query OrgSettingPage($organizationSlug: String!) {
  currentUser {
    id
    email
    memberships {
      id
      role
      organizationId
      organization {
        id
        slug
        name
        logoUrl
      }
    }
  }
  organization(organizationSlug: $organizationSlug) {
    id
    slug
    memberships {
      id
      role
    }
  }
}
    `;

/**
 * __useOrgSettingPageQuery__
 *
 * To run a query within a React component, call `useOrgSettingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSettingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSettingPageQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useOrgSettingPageQuery(baseOptions: Apollo.QueryHookOptions<OrgSettingPageQuery, OrgSettingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgSettingPageQuery, OrgSettingPageQueryVariables>(OrgSettingPageDocument, options);
      }
export function useOrgSettingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgSettingPageQuery, OrgSettingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgSettingPageQuery, OrgSettingPageQueryVariables>(OrgSettingPageDocument, options);
        }
export type OrgSettingPageQueryHookResult = ReturnType<typeof useOrgSettingPageQuery>;
export type OrgSettingPageLazyQueryHookResult = ReturnType<typeof useOrgSettingPageLazyQuery>;
export type OrgSettingPageQueryResult = Apollo.QueryResult<OrgSettingPageQuery, OrgSettingPageQueryVariables>;
export const SetOrgNameDocument = gql`
    mutation SetOrgName($newName: String!, $organizationSlug: String) {
  setOrgName(newName: $newName, organizationSlug: $organizationSlug) {
    id
    name
  }
}
    `;
export type SetOrgNameMutationFn = Apollo.MutationFunction<SetOrgNameMutation, SetOrgNameMutationVariables>;

/**
 * __useSetOrgNameMutation__
 *
 * To run a mutation, you first call `useSetOrgNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgNameMutation, { data, loading, error }] = useSetOrgNameMutation({
 *   variables: {
 *      newName: // value for 'newName'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useSetOrgNameMutation(baseOptions?: Apollo.MutationHookOptions<SetOrgNameMutation, SetOrgNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrgNameMutation, SetOrgNameMutationVariables>(SetOrgNameDocument, options);
      }
export type SetOrgNameMutationHookResult = ReturnType<typeof useSetOrgNameMutation>;
export type SetOrgNameMutationResult = Apollo.MutationResult<SetOrgNameMutation>;
export type SetOrgNameMutationOptions = Apollo.BaseMutationOptions<SetOrgNameMutation, SetOrgNameMutationVariables>;
export const SetOrgLogoUrlDocument = gql`
    mutation SetOrgLogoUrl($newLogoUrl: String!, $organizationSlug: String) {
  setOrgLogoUrl(newLogoUrl: $newLogoUrl, organizationSlug: $organizationSlug) {
    id
    logoUrl
  }
}
    `;
export type SetOrgLogoUrlMutationFn = Apollo.MutationFunction<SetOrgLogoUrlMutation, SetOrgLogoUrlMutationVariables>;

/**
 * __useSetOrgLogoUrlMutation__
 *
 * To run a mutation, you first call `useSetOrgLogoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgLogoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgLogoUrlMutation, { data, loading, error }] = useSetOrgLogoUrlMutation({
 *   variables: {
 *      newLogoUrl: // value for 'newLogoUrl'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useSetOrgLogoUrlMutation(baseOptions?: Apollo.MutationHookOptions<SetOrgLogoUrlMutation, SetOrgLogoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrgLogoUrlMutation, SetOrgLogoUrlMutationVariables>(SetOrgLogoUrlDocument, options);
      }
export type SetOrgLogoUrlMutationHookResult = ReturnType<typeof useSetOrgLogoUrlMutation>;
export type SetOrgLogoUrlMutationResult = Apollo.MutationResult<SetOrgLogoUrlMutation>;
export type SetOrgLogoUrlMutationOptions = Apollo.BaseMutationOptions<SetOrgLogoUrlMutation, SetOrgLogoUrlMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation DeleteOrganization($organizationSlug: String) {
  deleteOrganization(organizationSlug: $organizationSlug) {
    id
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const OrgMembersPageDocument = gql`
    query OrgMembersPage($organizationSlug: String!) {
  currentUser {
    id
    name
    memberships {
      id
      role
      organizationId
    }
  }
  organization(organizationSlug: $organizationSlug) {
    id
    slug
    name
    dateCreated
    createdAt
    memberships {
      id
      role
      user {
        id
        name
        email
        avatarUrl
      }
    }
  }
}
    `;

/**
 * __useOrgMembersPageQuery__
 *
 * To run a query within a React component, call `useOrgMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMembersPageQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useOrgMembersPageQuery(baseOptions: Apollo.QueryHookOptions<OrgMembersPageQuery, OrgMembersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgMembersPageQuery, OrgMembersPageQueryVariables>(OrgMembersPageDocument, options);
      }
export function useOrgMembersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgMembersPageQuery, OrgMembersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgMembersPageQuery, OrgMembersPageQueryVariables>(OrgMembersPageDocument, options);
        }
export type OrgMembersPageQueryHookResult = ReturnType<typeof useOrgMembersPageQuery>;
export type OrgMembersPageLazyQueryHookResult = ReturnType<typeof useOrgMembersPageLazyQuery>;
export type OrgMembersPageQueryResult = Apollo.QueryResult<OrgMembersPageQuery, OrgMembersPageQueryVariables>;
export const OrgStandingInvitationsDocument = gql`
    query OrgStandingInvitations($organizationSlug: String) {
  organization(organizationSlug: $organizationSlug) {
    id
    standingInvitations {
      ...StandingInvitationVisibleData
    }
  }
}
    ${StandingInvitationVisibleDataFragmentDoc}`;

/**
 * __useOrgStandingInvitationsQuery__
 *
 * To run a query within a React component, call `useOrgStandingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgStandingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgStandingInvitationsQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useOrgStandingInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<OrgStandingInvitationsQuery, OrgStandingInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgStandingInvitationsQuery, OrgStandingInvitationsQueryVariables>(OrgStandingInvitationsDocument, options);
      }
export function useOrgStandingInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgStandingInvitationsQuery, OrgStandingInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgStandingInvitationsQuery, OrgStandingInvitationsQueryVariables>(OrgStandingInvitationsDocument, options);
        }
export type OrgStandingInvitationsQueryHookResult = ReturnType<typeof useOrgStandingInvitationsQuery>;
export type OrgStandingInvitationsLazyQueryHookResult = ReturnType<typeof useOrgStandingInvitationsLazyQuery>;
export type OrgStandingInvitationsQueryResult = Apollo.QueryResult<OrgStandingInvitationsQuery, OrgStandingInvitationsQueryVariables>;
export const CreateStandingInvitationDocument = gql`
    mutation CreateStandingInvitation($role: MembershipRole!, $organizationSlug: String) {
  createStandingInvitation(role: $role, organizationSlug: $organizationSlug) {
    ...StandingInvitationVisibleData
  }
}
    ${StandingInvitationVisibleDataFragmentDoc}`;
export type CreateStandingInvitationMutationFn = Apollo.MutationFunction<CreateStandingInvitationMutation, CreateStandingInvitationMutationVariables>;

/**
 * __useCreateStandingInvitationMutation__
 *
 * To run a mutation, you first call `useCreateStandingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandingInvitationMutation, { data, loading, error }] = useCreateStandingInvitationMutation({
 *   variables: {
 *      role: // value for 'role'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useCreateStandingInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateStandingInvitationMutation, CreateStandingInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStandingInvitationMutation, CreateStandingInvitationMutationVariables>(CreateStandingInvitationDocument, options);
      }
export type CreateStandingInvitationMutationHookResult = ReturnType<typeof useCreateStandingInvitationMutation>;
export type CreateStandingInvitationMutationResult = Apollo.MutationResult<CreateStandingInvitationMutation>;
export type CreateStandingInvitationMutationOptions = Apollo.BaseMutationOptions<CreateStandingInvitationMutation, CreateStandingInvitationMutationVariables>;
export const DeleteStandingInvitationDocument = gql`
    mutation DeleteStandingInvitation($code: String!, $organizationSlug: String) {
  deleteInvitation(code: $code, organizationSlug: $organizationSlug) {
    id
    role
    code
  }
}
    `;
export type DeleteStandingInvitationMutationFn = Apollo.MutationFunction<DeleteStandingInvitationMutation, DeleteStandingInvitationMutationVariables>;

/**
 * __useDeleteStandingInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteStandingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandingInvitationMutation, { data, loading, error }] = useDeleteStandingInvitationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useDeleteStandingInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStandingInvitationMutation, DeleteStandingInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStandingInvitationMutation, DeleteStandingInvitationMutationVariables>(DeleteStandingInvitationDocument, options);
      }
export type DeleteStandingInvitationMutationHookResult = ReturnType<typeof useDeleteStandingInvitationMutation>;
export type DeleteStandingInvitationMutationResult = Apollo.MutationResult<DeleteStandingInvitationMutation>;
export type DeleteStandingInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteStandingInvitationMutation, DeleteStandingInvitationMutationVariables>;
export const RemoveUserFromOrganizationDocument = gql`
    mutation RemoveUserFromOrganization($organizationId: ID!, $userId: ID!) {
  removeUserFromOrganization(organizationId: $organizationId, userId: $userId) {
    id
  }
}
    `;
export type RemoveUserFromOrganizationMutationFn = Apollo.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;

/**
 * __useRemoveUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationMutation, { data, loading, error }] = useRemoveUserFromOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>(RemoveUserFromOrganizationDocument, options);
      }
export type RemoveUserFromOrganizationMutationHookResult = ReturnType<typeof useRemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationResult = Apollo.MutationResult<RemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export const UpdateMembershipRoleDocument = gql`
    mutation UpdateMembershipRole($organizationId: ID!, $userId: ID!, $role: MembershipRole!) {
  updateMembershipRole(
    organizationId: $organizationId
    userId: $userId
    role: $role
  ) {
    id
    role
    userId
  }
}
    `;
export type UpdateMembershipRoleMutationFn = Apollo.MutationFunction<UpdateMembershipRoleMutation, UpdateMembershipRoleMutationVariables>;

/**
 * __useUpdateMembershipRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipRoleMutation, { data, loading, error }] = useUpdateMembershipRoleMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateMembershipRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipRoleMutation, UpdateMembershipRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipRoleMutation, UpdateMembershipRoleMutationVariables>(UpdateMembershipRoleDocument, options);
      }
export type UpdateMembershipRoleMutationHookResult = ReturnType<typeof useUpdateMembershipRoleMutation>;
export type UpdateMembershipRoleMutationResult = Apollo.MutationResult<UpdateMembershipRoleMutation>;
export type UpdateMembershipRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipRoleMutation, UpdateMembershipRoleMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $slug: String!, $organizationSlug: String!) {
  createProject(name: $name, slug: $slug, organizationSlug: $organizationSlug) {
    ... on Project {
      id
    }
    ... on ErrorNoDuplicates {
      message
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      organizationSlug: // value for 'organizationSlug'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const OrganizationsDocument = gql`
    query Organizations {
  currentUser {
    memberships {
      organization {
        id
        slug
        name
      }
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const HistoryPageDocument = gql`
    query HistoryPage($slug: String!, $organizationSlug: String!, $cursor: ID, $limit: Int) {
  project(slug: $slug, organizationSlug: $organizationSlug) {
    id
    slug
    name
    revisions(cursor: $cursor, limit: $limit) {
      ...HistoryPageRevision
    }
    latestRevision {
      id
      name
    }
  }
}
    ${HistoryPageRevisionFragmentDoc}`;

/**
 * __useHistoryPageQuery__
 *
 * To run a query within a React component, call `useHistoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      organizationSlug: // value for 'organizationSlug'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useHistoryPageQuery(baseOptions: Apollo.QueryHookOptions<HistoryPageQuery, HistoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryPageQuery, HistoryPageQueryVariables>(HistoryPageDocument, options);
      }
export function useHistoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryPageQuery, HistoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryPageQuery, HistoryPageQueryVariables>(HistoryPageDocument, options);
        }
export type HistoryPageQueryHookResult = ReturnType<typeof useHistoryPageQuery>;
export type HistoryPageLazyQueryHookResult = ReturnType<typeof useHistoryPageLazyQuery>;
export type HistoryPageQueryResult = Apollo.QueryResult<HistoryPageQuery, HistoryPageQueryVariables>;
export const SetRevisionCommentDocument = gql`
    mutation SetRevisionComment($revisionId: ID!, $comment: String!) {
  setRevisionComment(revisionId: $revisionId, comment: $comment) {
    id
    comment
  }
}
    `;
export type SetRevisionCommentMutationFn = Apollo.MutationFunction<SetRevisionCommentMutation, SetRevisionCommentMutationVariables>;

/**
 * __useSetRevisionCommentMutation__
 *
 * To run a mutation, you first call `useSetRevisionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRevisionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRevisionCommentMutation, { data, loading, error }] = useSetRevisionCommentMutation({
 *   variables: {
 *      revisionId: // value for 'revisionId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useSetRevisionCommentMutation(baseOptions?: Apollo.MutationHookOptions<SetRevisionCommentMutation, SetRevisionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRevisionCommentMutation, SetRevisionCommentMutationVariables>(SetRevisionCommentDocument, options);
      }
export type SetRevisionCommentMutationHookResult = ReturnType<typeof useSetRevisionCommentMutation>;
export type SetRevisionCommentMutationResult = Apollo.MutationResult<SetRevisionCommentMutation>;
export type SetRevisionCommentMutationOptions = Apollo.BaseMutationOptions<SetRevisionCommentMutation, SetRevisionCommentMutationVariables>;
export const RestoreProjectToRevisionDocument = gql`
    mutation RestoreProjectToRevision($projectId: ID!, $revisionToRevertToId: ID!) {
  restoreProjectToRevision(
    projectId: $projectId
    revisionToRevertToId: $revisionToRevertToId
  ) {
    id
    revisions {
      ...HistoryPageRevision
    }
  }
}
    ${HistoryPageRevisionFragmentDoc}`;
export type RestoreProjectToRevisionMutationFn = Apollo.MutationFunction<RestoreProjectToRevisionMutation, RestoreProjectToRevisionMutationVariables>;

/**
 * __useRestoreProjectToRevisionMutation__
 *
 * To run a mutation, you first call `useRestoreProjectToRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreProjectToRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreProjectToRevisionMutation, { data, loading, error }] = useRestoreProjectToRevisionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      revisionToRevertToId: // value for 'revisionToRevertToId'
 *   },
 * });
 */
export function useRestoreProjectToRevisionMutation(baseOptions?: Apollo.MutationHookOptions<RestoreProjectToRevisionMutation, RestoreProjectToRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreProjectToRevisionMutation, RestoreProjectToRevisionMutationVariables>(RestoreProjectToRevisionDocument, options);
      }
export type RestoreProjectToRevisionMutationHookResult = ReturnType<typeof useRestoreProjectToRevisionMutation>;
export type RestoreProjectToRevisionMutationResult = Apollo.MutationResult<RestoreProjectToRevisionMutation>;
export type RestoreProjectToRevisionMutationOptions = Apollo.BaseMutationOptions<RestoreProjectToRevisionMutation, RestoreProjectToRevisionMutationVariables>;
export const OrgUrlDocument = gql`
    query OrgUrl {
  currentUser {
    id
    memberships {
      id
      organization {
        id
        logoUrl
        slug
      }
    }
  }
}
    `;

/**
 * __useOrgUrlQuery__
 *
 * To run a query within a React component, call `useOrgUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgUrlQuery(baseOptions?: Apollo.QueryHookOptions<OrgUrlQuery, OrgUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgUrlQuery, OrgUrlQueryVariables>(OrgUrlDocument, options);
      }
export function useOrgUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgUrlQuery, OrgUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgUrlQuery, OrgUrlQueryVariables>(OrgUrlDocument, options);
        }
export type OrgUrlQueryHookResult = ReturnType<typeof useOrgUrlQuery>;
export type OrgUrlLazyQueryHookResult = ReturnType<typeof useOrgUrlLazyQuery>;
export type OrgUrlQueryResult = Apollo.QueryResult<OrgUrlQuery, OrgUrlQueryVariables>;
export const ProjectListPageDocument = gql`
    query ProjectListPage($organizationSlug: String!, $filter: ProjectsFilter!, $cursor: ID, $filterByName: String, $limit: Int) {
  organization(organizationSlug: $organizationSlug) {
    id
    slug
    projects(
      filter: $filter
      cursor: $cursor
      filterByName: $filterByName
      limit: $limit
    ) {
      id
      archived
      createdAt
      createdBy {
        id
        name
      }
      organizationId
      slug
      name
      latestRevision {
        id
        thumbnail {
          id
          remoteUrl
        }
        createdAt
      }
    }
  }
}
    `;

/**
 * __useProjectListPageQuery__
 *
 * To run a query within a React component, call `useProjectListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectListPageQuery({
 *   variables: {
 *      organizationSlug: // value for 'organizationSlug'
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      filterByName: // value for 'filterByName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProjectListPageQuery(baseOptions: Apollo.QueryHookOptions<ProjectListPageQuery, ProjectListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectListPageQuery, ProjectListPageQueryVariables>(ProjectListPageDocument, options);
      }
export function useProjectListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectListPageQuery, ProjectListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectListPageQuery, ProjectListPageQueryVariables>(ProjectListPageDocument, options);
        }
export type ProjectListPageQueryHookResult = ReturnType<typeof useProjectListPageQuery>;
export type ProjectListPageLazyQueryHookResult = ReturnType<typeof useProjectListPageLazyQuery>;
export type ProjectListPageQueryResult = Apollo.QueryResult<ProjectListPageQuery, ProjectListPageQueryVariables>;
export const UpdateProjectArchiveStatusDocument = gql`
    mutation UpdateProjectArchiveStatus($isArchived: Boolean!, $projectId: ID!) {
  updateProjectArchiveStatus(isArchived: $isArchived, projectId: $projectId) {
    id
    archived
  }
}
    `;
export type UpdateProjectArchiveStatusMutationFn = Apollo.MutationFunction<UpdateProjectArchiveStatusMutation, UpdateProjectArchiveStatusMutationVariables>;

/**
 * __useUpdateProjectArchiveStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectArchiveStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectArchiveStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectArchiveStatusMutation, { data, loading, error }] = useUpdateProjectArchiveStatusMutation({
 *   variables: {
 *      isArchived: // value for 'isArchived'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateProjectArchiveStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectArchiveStatusMutation, UpdateProjectArchiveStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectArchiveStatusMutation, UpdateProjectArchiveStatusMutationVariables>(UpdateProjectArchiveStatusDocument, options);
      }
export type UpdateProjectArchiveStatusMutationHookResult = ReturnType<typeof useUpdateProjectArchiveStatusMutation>;
export type UpdateProjectArchiveStatusMutationResult = Apollo.MutationResult<UpdateProjectArchiveStatusMutation>;
export type UpdateProjectArchiveStatusMutationOptions = Apollo.BaseMutationOptions<UpdateProjectArchiveStatusMutation, UpdateProjectArchiveStatusMutationVariables>;
export const UpdateAndViewProjectPreviewDocument = gql`
    query UpdateAndViewProjectPreview($organizationId: ID!, $projectId: ID!) {
  organization(organizationId: $organizationId) {
    id
    project(projectId: $projectId) {
      id
      latestRevision {
        id
        thumbnail {
          id
          remoteUrl
        }
        src
        assets {
          id
          relativePath
          remoteUrl
        }
      }
    }
  }
}
    `;

/**
 * __useUpdateAndViewProjectPreviewQuery__
 *
 * To run a query within a React component, call `useUpdateAndViewProjectPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateAndViewProjectPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateAndViewProjectPreviewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateAndViewProjectPreviewQuery(baseOptions: Apollo.QueryHookOptions<UpdateAndViewProjectPreviewQuery, UpdateAndViewProjectPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateAndViewProjectPreviewQuery, UpdateAndViewProjectPreviewQueryVariables>(UpdateAndViewProjectPreviewDocument, options);
      }
export function useUpdateAndViewProjectPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateAndViewProjectPreviewQuery, UpdateAndViewProjectPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateAndViewProjectPreviewQuery, UpdateAndViewProjectPreviewQueryVariables>(UpdateAndViewProjectPreviewDocument, options);
        }
export type UpdateAndViewProjectPreviewQueryHookResult = ReturnType<typeof useUpdateAndViewProjectPreviewQuery>;
export type UpdateAndViewProjectPreviewLazyQueryHookResult = ReturnType<typeof useUpdateAndViewProjectPreviewLazyQuery>;
export type UpdateAndViewProjectPreviewQueryResult = Apollo.QueryResult<UpdateAndViewProjectPreviewQuery, UpdateAndViewProjectPreviewQueryVariables>;
export const SetRevisionThumbnailDocument = gql`
    mutation SetRevisionThumbnail($revisionId: ID!, $thumbnailRemoteUrl: String!) {
  setRevisionThumbnailByURL(
    revisionId: $revisionId
    thumbnailRemoteUrl: $thumbnailRemoteUrl
  ) {
    id
    thumbnail {
      id
      remoteUrl
    }
  }
}
    `;
export type SetRevisionThumbnailMutationFn = Apollo.MutationFunction<SetRevisionThumbnailMutation, SetRevisionThumbnailMutationVariables>;

/**
 * __useSetRevisionThumbnailMutation__
 *
 * To run a mutation, you first call `useSetRevisionThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRevisionThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRevisionThumbnailMutation, { data, loading, error }] = useSetRevisionThumbnailMutation({
 *   variables: {
 *      revisionId: // value for 'revisionId'
 *      thumbnailRemoteUrl: // value for 'thumbnailRemoteUrl'
 *   },
 * });
 */
export function useSetRevisionThumbnailMutation(baseOptions?: Apollo.MutationHookOptions<SetRevisionThumbnailMutation, SetRevisionThumbnailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRevisionThumbnailMutation, SetRevisionThumbnailMutationVariables>(SetRevisionThumbnailDocument, options);
      }
export type SetRevisionThumbnailMutationHookResult = ReturnType<typeof useSetRevisionThumbnailMutation>;
export type SetRevisionThumbnailMutationResult = Apollo.MutationResult<SetRevisionThumbnailMutation>;
export type SetRevisionThumbnailMutationOptions = Apollo.BaseMutationOptions<SetRevisionThumbnailMutation, SetRevisionThumbnailMutationVariables>;
export const TopNavDocument = gql`
    query TopNav {
  currentUser {
    id
    name
    email
    avatarUrl
    memberships {
      id
      organization {
        ...TopNavOrg
      }
    }
  }
}
    ${TopNavOrgFragmentDoc}`;

/**
 * __useTopNavQuery__
 *
 * To run a query within a React component, call `useTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopNavQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopNavQuery(baseOptions?: Apollo.QueryHookOptions<TopNavQuery, TopNavQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopNavQuery, TopNavQueryVariables>(TopNavDocument, options);
      }
export function useTopNavLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopNavQuery, TopNavQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopNavQuery, TopNavQueryVariables>(TopNavDocument, options);
        }
export type TopNavQueryHookResult = ReturnType<typeof useTopNavQuery>;
export type TopNavLazyQueryHookResult = ReturnType<typeof useTopNavLazyQuery>;
export type TopNavQueryResult = Apollo.QueryResult<TopNavQuery, TopNavQueryVariables>;
export const UseProjectDocument = gql`
    query UseProject($projectSlug: String!, $orgSlug: String!) {
  project(slug: $projectSlug, organizationSlug: $orgSlug) {
    id
    createdAt
    archived
    slug
    name
    createdBy {
      id
      name
    }
    latestRevision {
      ...ProjectLatestRevision
    }
  }
}
    ${ProjectLatestRevisionFragmentDoc}`;

/**
 * __useUseProjectQuery__
 *
 * To run a query within a React component, call `useUseProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectQuery({
 *   variables: {
 *      projectSlug: // value for 'projectSlug'
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useUseProjectQuery(baseOptions: Apollo.QueryHookOptions<UseProjectQuery, UseProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectQuery, UseProjectQueryVariables>(UseProjectDocument, options);
      }
export function useUseProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectQuery, UseProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectQuery, UseProjectQueryVariables>(UseProjectDocument, options);
        }
export type UseProjectQueryHookResult = ReturnType<typeof useUseProjectQuery>;
export type UseProjectLazyQueryHookResult = ReturnType<typeof useUseProjectLazyQuery>;
export type UseProjectQueryResult = Apollo.QueryResult<UseProjectQuery, UseProjectQueryVariables>;
export const UpdateProjectSrcDocument = gql`
    mutation UpdateProjectSrc($projectId: ID!, $src: String!) {
  updateProjectSrc(projectId: $projectId, src: $src) {
    id
    latestRevision {
      ...ProjectLatestRevision
    }
  }
}
    ${ProjectLatestRevisionFragmentDoc}`;
export type UpdateProjectSrcMutationFn = Apollo.MutationFunction<UpdateProjectSrcMutation, UpdateProjectSrcMutationVariables>;

/**
 * __useUpdateProjectSrcMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSrcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSrcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSrcMutation, { data, loading, error }] = useUpdateProjectSrcMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      src: // value for 'src'
 *   },
 * });
 */
export function useUpdateProjectSrcMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectSrcMutation, UpdateProjectSrcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectSrcMutation, UpdateProjectSrcMutationVariables>(UpdateProjectSrcDocument, options);
      }
export type UpdateProjectSrcMutationHookResult = ReturnType<typeof useUpdateProjectSrcMutation>;
export type UpdateProjectSrcMutationResult = Apollo.MutationResult<UpdateProjectSrcMutation>;
export type UpdateProjectSrcMutationOptions = Apollo.BaseMutationOptions<UpdateProjectSrcMutation, UpdateProjectSrcMutationVariables>;
export const RenameProjectDocument = gql`
    mutation RenameProject($projectId: ID!, $newName: String!) {
  renameProject(projectId: $projectId, name: $newName) {
    id
    name
  }
}
    `;
export type RenameProjectMutationFn = Apollo.MutationFunction<RenameProjectMutation, RenameProjectMutationVariables>;

/**
 * __useRenameProjectMutation__
 *
 * To run a mutation, you first call `useRenameProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameProjectMutation, { data, loading, error }] = useRenameProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useRenameProjectMutation(baseOptions?: Apollo.MutationHookOptions<RenameProjectMutation, RenameProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameProjectMutation, RenameProjectMutationVariables>(RenameProjectDocument, options);
      }
export type RenameProjectMutationHookResult = ReturnType<typeof useRenameProjectMutation>;
export type RenameProjectMutationResult = Apollo.MutationResult<RenameProjectMutation>;
export type RenameProjectMutationOptions = Apollo.BaseMutationOptions<RenameProjectMutation, RenameProjectMutationVariables>;
export const AddAssetsToProjectDocument = gql`
    mutation AddAssetsToProject($projectId: ID!, $files: [AssetInput!]!) {
  addAssetsToProject(projectId: $projectId, files: $files) {
    id
    latestRevision {
      ...ProjectLatestRevision
    }
  }
}
    ${ProjectLatestRevisionFragmentDoc}`;
export type AddAssetsToProjectMutationFn = Apollo.MutationFunction<AddAssetsToProjectMutation, AddAssetsToProjectMutationVariables>;

/**
 * __useAddAssetsToProjectMutation__
 *
 * To run a mutation, you first call `useAddAssetsToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetsToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetsToProjectMutation, { data, loading, error }] = useAddAssetsToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useAddAssetsToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddAssetsToProjectMutation, AddAssetsToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAssetsToProjectMutation, AddAssetsToProjectMutationVariables>(AddAssetsToProjectDocument, options);
      }
export type AddAssetsToProjectMutationHookResult = ReturnType<typeof useAddAssetsToProjectMutation>;
export type AddAssetsToProjectMutationResult = Apollo.MutationResult<AddAssetsToProjectMutation>;
export type AddAssetsToProjectMutationOptions = Apollo.BaseMutationOptions<AddAssetsToProjectMutation, AddAssetsToProjectMutationVariables>;
export const RemoveAssetsFromProjectDocument = gql`
    mutation RemoveAssetsFromProject($projectId: ID!, $assetIds: [ID!]!) {
  removeAssetsFromProject(projectId: $projectId, assetIds: $assetIds) {
    id
    latestRevision {
      ...ProjectLatestRevision
    }
  }
}
    ${ProjectLatestRevisionFragmentDoc}`;
export type RemoveAssetsFromProjectMutationFn = Apollo.MutationFunction<RemoveAssetsFromProjectMutation, RemoveAssetsFromProjectMutationVariables>;

/**
 * __useRemoveAssetsFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveAssetsFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssetsFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssetsFromProjectMutation, { data, loading, error }] = useRemoveAssetsFromProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useRemoveAssetsFromProjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAssetsFromProjectMutation, RemoveAssetsFromProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAssetsFromProjectMutation, RemoveAssetsFromProjectMutationVariables>(RemoveAssetsFromProjectDocument, options);
      }
export type RemoveAssetsFromProjectMutationHookResult = ReturnType<typeof useRemoveAssetsFromProjectMutation>;
export type RemoveAssetsFromProjectMutationResult = Apollo.MutationResult<RemoveAssetsFromProjectMutation>;
export type RemoveAssetsFromProjectMutationOptions = Apollo.BaseMutationOptions<RemoveAssetsFromProjectMutation, RemoveAssetsFromProjectMutationVariables>;
export const UpdateProjectAssetNameTransformTemplateDocument = gql`
    mutation UpdateProjectAssetNameTransformTemplate($assetNameTransformTemplate: String!, $projectId: ID!) {
  updateProjectAssetNameTransformTemplate(
    assetNameTransformTemplate: $assetNameTransformTemplate
    projectId: $projectId
  ) {
    id
    latestRevision {
      ...ProjectLatestRevision
    }
  }
}
    ${ProjectLatestRevisionFragmentDoc}`;
export type UpdateProjectAssetNameTransformTemplateMutationFn = Apollo.MutationFunction<UpdateProjectAssetNameTransformTemplateMutation, UpdateProjectAssetNameTransformTemplateMutationVariables>;

/**
 * __useUpdateProjectAssetNameTransformTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAssetNameTransformTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAssetNameTransformTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAssetNameTransformTemplateMutation, { data, loading, error }] = useUpdateProjectAssetNameTransformTemplateMutation({
 *   variables: {
 *      assetNameTransformTemplate: // value for 'assetNameTransformTemplate'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateProjectAssetNameTransformTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectAssetNameTransformTemplateMutation, UpdateProjectAssetNameTransformTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectAssetNameTransformTemplateMutation, UpdateProjectAssetNameTransformTemplateMutationVariables>(UpdateProjectAssetNameTransformTemplateDocument, options);
      }
export type UpdateProjectAssetNameTransformTemplateMutationHookResult = ReturnType<typeof useUpdateProjectAssetNameTransformTemplateMutation>;
export type UpdateProjectAssetNameTransformTemplateMutationResult = Apollo.MutationResult<UpdateProjectAssetNameTransformTemplateMutation>;
export type UpdateProjectAssetNameTransformTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateProjectAssetNameTransformTemplateMutation, UpdateProjectAssetNameTransformTemplateMutationVariables>;
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($newProjectName: String!, $organizationSlug: String!, $currentProjectId: ID!) {
  duplicateProject(
    newProjectName: $newProjectName
    organizationSlug: $organizationSlug
    currentProjectId: $currentProjectId
  ) {
    id
    slug
  }
}
    `;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<DuplicateProjectMutation, DuplicateProjectMutationVariables>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      newProjectName: // value for 'newProjectName'
 *      organizationSlug: // value for 'organizationSlug'
 *      currentProjectId: // value for 'currentProjectId'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument, options);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>;