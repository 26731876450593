import './initializeTelemetry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './initializeFirestore';
// import * as serviceWorker from './serviceWorker';
import App from './App';
import { veeva } from './compile/ejsHelpers';

const RUNTIME_ENV = process.env.REACT_APP_RUNTIME_ENV || 'localdev';

// exposing veeva into window to make subjectlines easier
// TODO: emove this once we make it easier to set veeva subject lines from the editor
Object.assign(window, { veeva: veeva, RUNTIME_ENV });

const container = document.getElementById('root');

if (!container) {
  throw new Error('Could not find root container');
}

const root = createRoot(container);

root.render(<App />);

// serviceWorker.register();
