/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/core';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import {
  MembershipRole,
  OrgMembersPageQuery,
  useCreateStandingInvitationMutation,
  useDeleteStandingInvitationMutation,
  useOrgMembersPageQuery,
  useOrgStandingInvitationsQuery,
  useRemoveUserFromOrganizationMutation,
  useUpdateMembershipRoleMutation,
} from 'src/generated/graphql';
import { LoadingScreen } from 'src/LoadingScreen';
import { BiError } from 'react-icons/bi';
import {
  MdContentCopy,
  MdOutlineDelete,
  MdPersonAddAlt1,
} from 'react-icons/md';
import { routes } from 'src/routes';

export const OrgMembers = ({ orgSlug }: { orgSlug: string }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [inviteRole, setInviteRole] = useState<MembershipRole>(
    MembershipRole.Member,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: orgStandingInvitationsData } = useOrgStandingInvitationsQuery({
    variables: {
      organizationSlug: orgSlug,
    },
  });

  const { data, loading } = useOrgMembersPageQuery({
    variables: {
      organizationSlug: orgSlug,
    },
  });

  const [
    createStandingInvitations,
    { loading: creatingStandingInvitations },
  ] = useCreateStandingInvitationMutation({
    variables: {
      organizationSlug: orgSlug,
      role: inviteRole,
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  const organizationId = data?.organization?.id;
  const organization = data?.organization;

  if (!organizationId || !organization) {
    return <div>No data</div>;
  }

  const isCurrentUserAdmin =
    data.currentUser?.memberships.find(
      ({ organizationId }) => organizationId === data?.organization?.id,
    )?.role === 'ADMIN';

  return (
    <>
      <div css={tw`flex`}>
        <Input
          placeholder="Search..."
          autoFocus={true}
          inputMode="search"
          css={tw`mr-6`}
          onChange={(e) => {
            e.preventDefault();
            setSearchQuery(e.target.value);
          }}
        />

        {isCurrentUserAdmin && (
          <div>
            <div css={tw`flex items-center justify-center w-full`}>
              <Button
                variant="outline"
                onClick={onOpen}
                leftIcon={<MdPersonAddAlt1 size={16} />}
              >
                Invite members
              </Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader pb={0}>Existing Invite Links</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {orgStandingInvitationsData?.organization?.standingInvitations.map(
                    (standingInvitation) => (
                      <OrgInvitationData
                        invitationCode={standingInvitation.code}
                        role={standingInvitation.role}
                        orgSlug={orgSlug}
                        organizationId={organizationId}
                      />
                    ),
                  )}
                </ModalBody>
                <ModalHeader pb={0}>Create New Invite Link</ModalHeader>
                <ModalBody css={tw`flex items-center justify-between mb-4`}>
                  <Select
                    onChange={(e) => {
                      e.preventDefault();
                      setInviteRole(e.target.value as MembershipRole);
                    }}
                    value={inviteRole}
                  >
                    {Object.values(MembershipRole).map((role) => (
                      <option value={role} key={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                  <Button
                    colorScheme="blue"
                    disabled={creatingStandingInvitations}
                    size="sm"
                    ml={8}
                    px="6"
                    onClick={() => {
                      createStandingInvitations({
                        variables: {
                          organizationSlug: orgSlug,
                          role: inviteRole,
                        },
                        update: (cache, { data }) => {
                          if (!orgStandingInvitationsData?.organization) return;
                          cache.modify({
                            id: cache.identify(
                              orgStandingInvitationsData.organization,
                            ),
                            fields: {
                              standingInvitations: (
                                existingStandingInvitations,
                              ) => [
                                ...existingStandingInvitations,
                                data?.createStandingInvitation,
                              ],
                            },
                          });
                        },
                      });
                    }}
                  >
                    Create
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </div>
        )}
      </div>

      <table css={tw`mt-4 w-full`}>
        <thead>
          <th css={tw`text-left p-4 font-semibold text-base`}>Name</th>
          <th css={tw`text-left p-4 font-semibold`}>Role</th>
          <th></th>
        </thead>
        <tbody>
          {data?.organization?.memberships
            .filter(function (membership) {
              if (!searchQuery) return true;
              return _.some([
                membership?.user.name
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()),
                membership?.user.email
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()),
              ]);
            })
            .map((membership) => (
              <OrgMembershipTableRow
                membership={membership}
                isCurrentUserAdmin={isCurrentUserAdmin}
                organization={organization}
              />
            ))}
        </tbody>
      </table>
    </>
  );
};

const OrgInvitationData = ({
  invitationCode,
  role,
  orgSlug,
  organizationId,
}: {
  invitationCode: string;
  role: MembershipRole;
  orgSlug: string;
  organizationId: string;
}) => {
  const invitationLink = `${
    window.location.origin
  }${routes.acceptInvitation.getPath({
    orgSlug,
    invitationCode,
  })}`;

  const [copySuccessMessage, setCopySuccessMessage] = useState(false);

  const [deleteStandingInvitation] = useDeleteStandingInvitationMutation({
    variables: {
      organizationSlug: orgSlug,
      code: invitationCode,
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopySuccessMessage(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [copySuccessMessage]);

  return (
    <div css={tw`flex flex-col`}>
      <div css={tw`text-base `}>
        Invite as <span tw="font-semibold text-sm">{role}</span>
      </div>
      <div
        tw="grid justify-center items-center mb-2"
        css={css`
          grid-template-columns: minmax(0, 1fr) min-content;
        `}
      >
        <Tooltip
          hasArrow
          label="Copied!"
          isOpen={copySuccessMessage}
          borderRadius={4}
          placement="top"
        >
          <Button
            size="sm"
            variant="outline"
            css={tw`bg-transparent hover:bg-transparent font-normal `}
            onClick={() => {
              navigator.clipboard.writeText(invitationLink);
              setCopySuccessMessage(true);
            }}
          >
            <MdContentCopy
              size={16}
              css={tw`flex-shrink-0 text-gray-600 hover:text-gray-400 mr-2`}
            />
            <div css={tw`truncate text-sm`}>{invitationLink}</div>
          </Button>
        </Tooltip>
        <Button
          variant="ghost"
          size="sm"
          css={tw`bg-transparent hover:bg-transparent flex-shrink-0 p-1 ml-2`}
          onClick={() => {
            deleteStandingInvitation({
              variables: {
                organizationSlug: orgSlug,
                code: invitationCode,
              },
              update: (cache, { data }) => {
                cache.modify({
                  id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                  }),
                  fields: {
                    standingInvitations: (existingStandingInvitations) => {
                      return existingStandingInvitations.filter(
                        (standingInvitation: any) => {
                          return standingInvitation.code !== invitationCode;
                        },
                      );
                    },
                  },
                });
              },
            });
          }}
        >
          <MdOutlineDelete
            size={18}
            css={tw`flex-shrink-0 text-gray-600 hover:text-red-400`}
          />
        </Button>
      </div>
      <p css={tw`ml-2 mb-2 text-green-400`}>{copySuccessMessage}</p>
    </div>
  );
};

const OrgMembershipTableRow = ({
  isCurrentUserAdmin,
  membership,
  organization,
}: {
  isCurrentUserAdmin: boolean;
  membership: NonNullable<
    OrgMembersPageQuery['organization']
  >['memberships'][number];
  organization: NonNullable<OrgMembersPageQuery['organization']>;
}) => {
  const [
    removeUserFromOrganization,
    { loading: removingUser },
  ] = useRemoveUserFromOrganizationMutation();

  const [
    updateMembershipRole,
    { error: updateMembershipRoleError, data: updateMembershipRoleData },
  ] = useUpdateMembershipRoleMutation();

  const [
    shouldPromptMembershipRemoval,
    setShouldPromptMembershipRemoval,
  ] = useState<boolean>(false);

  const cancelRemoveOrgMember = useRef<any>();
  console.log({ updateMembershipRoleError, updateMembershipRoleData });

  return (
    <>
      <tr
        key={membership.id}
        css={[
          tw`border-t last:border-b`,
          css`
            & > td {
              padding: 1rem;
            }
          `,
        ]}
      >
        <td>
          <div css={tw`flex flex-row items-center`}>
            <img
              src={
                membership.user.avatarUrl ??
                `https://source.boringavatars.com/beam/120/${membership.user.id}?colors=FFBD87,FFD791,F7E8A6,D9E8AE,BFE3C0
`
              }
              alt=""
              css={css`
                border-radius: 50%;
                width: 40px;
              `}
            />
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-left: 1rem;
                & hover {
                  background-color: #f7f7f7;
                }
              `}
            >
              <span>{membership.user.name}</span>
              <span>{membership.user.email}</span>
            </div>
          </div>
        </td>
        <td>
          <Select
            disabled={!isCurrentUserAdmin || removingUser}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value === membership.role) return;
              updateMembershipRole({
                variables: {
                  organizationId: organization.id,
                  userId: membership.user.id,
                  role: e.target.value as MembershipRole,
                },
                optimisticResponse: {
                  updateMembershipRole: {
                    __typename: 'Membership',
                    id: membership.id,
                    role: e.target.value as MembershipRole,
                    userId: membership.user.id,
                  },
                },
              });
            }}
            value={membership.role}
          >
            {Object.values(MembershipRole).map((role) => (
              <option value={role} key={role}>
                {role}
              </option>
            ))}
          </Select>
        </td>
        <td>
          <button
            css={css`
              background-color: #c4c1c1;
              color: #fff;
              border-radius: 4px;
              padding: 0.2rem 0.4rem;
              &:hover {
                background-color: #969595;
              }
              :disabled {
                color: #fff;
                background-color: #efecec;
                cursor: not-allowed;
              }
            `}
            disabled={!isCurrentUserAdmin || removingUser}
            onClick={() => {
              setShouldPromptMembershipRemoval(true);
            }}
          >
            {removingUser ? 'Removing...' : 'Remove'}
          </button>

          {shouldPromptMembershipRemoval && (
            <AlertDialog
              isOpen={true}
              leastDestructiveRef={cancelRemoveOrgMember}
              onClose={() => {
                setShouldPromptMembershipRemoval(false);
              }}
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader>
                  Remove user from organization?
                </AlertDialogHeader>
                <AlertDialogBody>
                  Are you sure you want to remove "{membership.user.name}" from
                  the organization?
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    ref={cancelRemoveOrgMember}
                    mr={3}
                    size={'sm'}
                    onClick={() => {
                      setShouldPromptMembershipRemoval(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    size={'sm'}
                    onClick={async (e) => {
                      e.preventDefault();

                      setShouldPromptMembershipRemoval(false);

                      await removeUserFromOrganization({
                        variables: {
                          organizationId: organization.id,
                          userId: membership.user.id,
                        },

                        update: (cache) => {
                          if (!membership.user.id) return;
                          cache.modify({
                            id: cache.identify(organization),
                            fields: {
                              memberships: (memberships, { readField }) => {
                                return memberships.filter(
                                  (m: any) =>
                                    readField('id', m) !== membership.id,
                                );
                              },
                            },
                          });
                        },
                      });
                    }}
                  >
                    Remove
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </td>
      </tr>
      {updateMembershipRoleError && (
        <tr>
          <td colSpan={3} css={tw`text-sm pb-4`}>
            <p css={tw`flex gap-2 mx-2`}>
              <BiError
                size={14}
                css={tw`flex-shrink-0 mt-[2px] text-red-700`}
              />
              {updateMembershipRoleError?.message}
            </p>
          </td>
        </tr>
      )}
    </>
  );
};
