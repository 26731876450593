import firebase from 'firebase/app';

enum FetchStatus {
  Pending,
  Resolved,
  Rejected,
}

export function createResource<Data, Args>(
  getData: (args: Args) => Promise<Data>,
) {
  const cache: {
    [key: string]: {
      status: FetchStatus;
      value: any;
    };
  } = {};

  return (args: Args): Data => {
    const key = JSON.stringify(args || null);
    if (!cache[key]) {
      cache[key] = {
        status: FetchStatus.Pending,
        value: getData(args)
          .then((value) => {
            cache[key] = { status: FetchStatus.Resolved, value };
          })
          .catch((value) => {
            cache[key] = { status: FetchStatus.Rejected, value };
          }),
      };
    }
    switch (cache[key].status) {
      case FetchStatus.Pending:
        throw cache[key].value;
      case FetchStatus.Resolved:
        return cache[key].value;
      case FetchStatus.Rejected:
        throw cache[key].value;
      default:
        throw new Error('unknown status');
    }
  };
}

export const getProjectCollectionReference = (orgId: string) =>
  firebase
    .firestore()
    .collection('organizations')
    .doc(orgId)
    .collection('projects');
