/** @jsxImportSource @emotion/react */

import _ from 'lodash';
import path from 'path-browserify';
import tw, { css, theme } from 'twin.macro';
import React, { useCallback } from 'react';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { formatDistanceToNow } from 'date-fns';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { MdArrowBack as BackIcon } from 'react-icons/md';
import { SegmentedControl } from '@mantine/core';

import { BiLinkExternal as OpenNewWindowIcon } from 'react-icons/bi';

import {
  ProjectOutOfDateError,
  UploadingAndUploadedFile,
  useProjectContext,
} from 'src/utils/useProject/useProject';
import { Language, compile } from 'src/compile';
import {
  PreviewOptions,
  PreviewOptionsMutations,
} from 'src/utils/crossWindow/useCrossWindowPreviewOptions';
import { SendEmailButton } from './SendEmailButton';
import { DuplicateProjectModalContent } from '../../Org/Projects/DuplicateProjectModalContent';
import { ToolBarMenuButton } from './ToolBarMenuButton';
import { Checkbox, Modal, ModalOverlay } from '@chakra-ui/react';
import { VeevaMenu } from './VeevaMenu/VeevaMenu';
import { useIsOnlineContext } from 'src/utils/useIsOnline';
import { getImageBlobByUrl } from 'src/utils/getImageBlobByUrl';
import { WithDisclosure } from 'src/components/WithDisclosure';
import { NavLink } from 'react-router-dom';
import { routes } from '../../routes';
import { Menu, MenuItem } from './NestableMenu';
import { SavingConfig } from './SavingConfig';
import { isMac } from 'src/utils/isMac';

export enum Preview {
  Preview = 'Preview',
  Html = 'Html',
  // None = 'None',
}

const mustacheDelimiters = /{{([\s\S]+?)}}/g;

export const ToolBar: React.FC<{
  previewOptions: PreviewOptions;
  previewOptionsMutations: PreviewOptionsMutations;
  createWindow: () => void;
  editorRef: React.RefObject<monacoEditor.editor.IStandaloneCodeEditor>;
  projectSlug: string;
  orgSlug: string;
  src: string;
  saveProjectSrc: (str: string) => Promise<void>;
  companionPane: Preview;
  setCompanionPane: (value: Preview) => void;
  wordWrap: monacoEditor.editor.IEditorConstructionOptions['wordWrap'];
  setWordWrap: (
    value: monacoEditor.editor.IEditorConstructionOptions['wordWrap'],
  ) => void;
  autoSaveFrequency: number | null;
  setAutoSaveFrequency: (value: number | null) => void;
}> = ({
  projectSlug,
  orgSlug,
  editorRef,
  src,
  saveProjectSrc,
  companionPane,
  setCompanionPane,
  previewOptions,
  previewOptionsMutations,
  createWindow,
  wordWrap,
  setWordWrap,
  autoSaveFrequency,
  setAutoSaveFrequency,
}) => {
  const [
    project,
    {
      uploadingAndUploadedImages,
      renameProject,
      errors: projectDataErrors,
      setProjectAssetNameTransformTemplate,
    },
  ] = useProjectContext();
  const { isOnline } = useIsOnlineContext();

  const compileLanguageForExport = useCallback(
    ({
      src,
      language,
      files,
    }: {
      src: string;
      language: Language;
      files: UploadingAndUploadedFile[];
    }) => {
      return compile({
        src,
        language,
        files,
        shouldMinify: previewOptions.shouldMinify,
        previewVeevaTags: false,
        remoteImages: false,
        isForRenderedPreview: false,
        shouldAddChecksum: false,
      });
    },
    [previewOptions.shouldMinify],
  );

  return (
    <div
      css={[
        tw`flex items-stretch relative text-sm`,
        css`
          justify-content: space-between;
          z-index: 2;
          border-bottom: 1px solid rgb(227, 229, 232);
          color: #5c6470;
          & > * {
            flex-grow: 1;
            text-align: center;
          }
        `,
        // styles for NestableMenu
        css`
          .menu-trigger {
            align-self: stretch;
            &:hover {
              background-color: ${theme`colors.gray.100`};
            }
          }
          .menu-trigger > .label ~ [aria-hidden='true'] {
            // hide the ugly arrow
            display: none;
          }
          .menu-trigger + * .menu {
            min-width: 140px;
            outline: none !important;
            position: relative;
            z-index: 50;
            display: flex;
            flex-direction: column;
            overscroll-behavior: contain;
            border-radius: 3px;
            border-width: 1px;
            border-style: solid;
            border-color: hsl(204, 20%, 88%);
            background-color: hsl(204, 20%, 100%);
            color: hsl(204, 10%, 10%);
            overflow: visible;

            .menu-item {
              display: flex;
              cursor: pointer;
              scroll-margin: 0.5rem;
              align-items: center;
              gap: 0.5rem;
              padding: 0.5rem;
              font-size: 12px;
              &:hover {
                background-color: ${theme`colors.gray.100`};
              }
              .label {
                text-align: left;
                flex-grow: 1;
              }
              &[aria-disabled='true'] {
                opacity: 0.25;
              }
            }
          }
        `,
      ]}
    >
      <NavLink
        css={[
          tw`flex items-center justify-center`,
          css`
            border-radius: 2px;
            padding: 4px 8px;
            &:hover {
              background-color: #eee;
            }
          `,
        ]}
        to={routes.projects.getPath({ orgSlug: orgSlug })}
      >
        <BackIcon
          width={9}
          css={[
            tw`inline-block mr-1`,
            css`
              margin-top: -2px;
            `,
          ]}
        />
        Project List
      </NavLink>
      <Menu
        label="Project"
        css={[tw`flex justify-center items-center self-center`]}
      >
        <MenuItem
          label={`Save Project (${isMac ? `⌘` : 'Ctrl'}+S)`}
          onClick={() => {
            saveProjectSrc(src);
          }}
        />

        <SavingConfig
          autoSaveFrequency={autoSaveFrequency}
          setAutoSaveFrequency={setAutoSaveFrequency}
        />
        <MenuItem
          label="Download"
          onClick={async () => {
            const zip = new JSZip();
            const namingTemplateString = window.prompt(
              'Please specify the filename template',
              project.assetNameTransformTemplate ??
                // TODO: read from org.defaultAssetNameTransformTemplate
                (orgSlug === 'maven'
                  ? `BRANDNAME_${projectSlug.toUpperCase()}_{{ filename }}_{{ language }}`
                  : projectSlug),
            );
            const shouldTransformFilenames =
              namingTemplateString &&
              /{{\s*filename\s*}}/.test(namingTemplateString);

            if (!namingTemplateString) {
              return;
            }
            await setProjectAssetNameTransformTemplate(namingTemplateString);

            const namingTemplate = _.template(namingTemplateString, {
              interpolate: mustacheDelimiters,
            });

            const projectFolder = zip.folder(namingTemplateString);

            const transformRelativePath = ({
              relativePath,
              language,
            }: {
              relativePath: string;
              language: Language;
            }) => {
              // XXX: DO NOT USE path.format
              // it behaves differently depending on node / build environment
              // i.e. path.format({ base: 'some-image', ext: '.gif' }); will return `some-image.gif` when run in local browser, but just `some-image` (without the .gif) remotely
              const transformedPath =
                path.dirname(relativePath) +
                '/' +
                namingTemplate({
                  filename: path.basename(
                    relativePath,
                    path.extname(relativePath),
                  ),
                  language,
                }) +
                path.extname(relativePath);

              return transformedPath;
            };

            await Promise.all(
              [Language.English, Language.French].map(async (language) => {
                const folderName = shouldTransformFilenames
                  ? namingTemplate({
                      filename: '',
                      language,
                    })
                  : `${namingTemplateString}_${language}`;

                const projectFolderLang = projectFolder.folder(folderName);
                const { html, matchedImages } = await compileLanguageForExport(
                  shouldTransformFilenames
                    ? {
                        src: src
                          // match on double quotes
                          .replace(
                            /"((?:\.\/)?(images\/[^"]+))"/g,
                            (_fullMatch, relativePath) =>
                              `"${transformRelativePath({
                                relativePath,
                                language,
                              })}"`,
                          )
                          // match on single quotes
                          .replace(
                            /'((?:\.\/)?(images\/[^']+))'/g,
                            (_fullMatch, relativePath) =>
                              `'${transformRelativePath({
                                relativePath,
                                language,
                              })}'`,
                          )
                          // match on parens with no quotes inside
                          .replace(
                            /\(((?:\.\/)?(images\/[^)]+))\)/g,
                            (_fullMatch, relativePath) =>
                              `(${transformRelativePath({
                                relativePath,
                                language,
                              })})`,
                          ),
                        language,
                        files: uploadingAndUploadedImages.map(
                          ({ relativePath, ...rest }) => ({
                            ...rest,
                            relativePath: transformRelativePath({
                              relativePath,
                              language,
                            }),
                          }),
                        ),
                      }
                    : {
                        src,
                        language,
                        files: uploadingAndUploadedImages,
                      },
                );

                projectFolderLang.file(folderName + '.html', html);

                return Promise.all(
                  matchedImages.map(async (image) => {
                    projectFolderLang.file(
                      image.relativePath.replace(/^\.\//, ''),
                      getImageBlobByUrl(image.remoteUrl),
                    );
                  }),
                );
              }),
            );

            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(
              content,
              `${namingTemplate({
                filename: '',
                language: '',
              })}.zip`,
            );
          }}
        />
        <WithDisclosure>
          {({ isOpen, onClose, onOpen }) => (
            <React.Fragment>
              <MenuItem onClick={onOpen} label="Duplicate" />
              <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick
                size="sm"
              >
                <ModalOverlay />
                <DuplicateProjectModalContent
                  currentProjectId={project.id}
                  currentProjectName={project.name}
                  currentOrgSlug={orgSlug}
                  onClose={onClose}
                />
              </Modal>
            </React.Fragment>
          )}
        </WithDisclosure>
        <MenuItem
          onClick={() => {
            const newName = window.prompt('New project name', project.name);
            if (newName) {
              renameProject(newName);
            }
          }}
          label="Rename"
        />
        <MenuItem
          as={() => (
            <NavLink
              role="menuitem"
              className="menu-item"
              tabIndex={-1}
              to={routes.history.getPath({
                orgSlug: orgSlug,
                projectSlug: projectSlug,
              })}
            >
              History
            </NavLink>
          )}
          label="History"
        />
        <SendEmailButton
          defaultSubject={projectSlug}
          getHTML={async () => {
            const { html } = await compile({
              src,
              language: previewOptions.language,
              files: project.files ?? [],
              shouldMinify: previewOptions.shouldMinify,
              previewVeevaTags: previewOptions.previewVeevaTags,
              remoteImages: true,
              isForRenderedPreview: false,
            });
            return html;
          }}
        />
        <div css={tw`text-gray-500 text-xs text-left p-2 pt-0`}>
          Last saved{' '}
          {project.lastModified
            ? formatDistanceToNow(new Date(project.lastModified)).replace(
                /about/,
                ' ',
              )
            : 'unknown'}{' '}
          ago
        </div>
      </Menu>
      {!isOnline && (
        <div
          css={[
            tw`absolute w-full left-0 px-4 py-1 text-gray-800 bg-gray-200 opacity-75`,
            css`
              top: 100%;
            `,
          ]}
        >
          Offline
        </div>
      )}
      {projectDataErrors.some(
        (error) => error instanceof ProjectOutOfDateError,
      ) && (
        <div
          css={[
            tw`absolute w-full left-0 px-4 py-1 text-gray-800 bg-red-200`,
            css`
              top: 100%;
              opacity: 0.9;
            `,
          ]}
        >
          Failed to save project. There's a more recent version on the server.
          Please copy out local changes or they will be lost.
        </div>
      )}

      <VeevaMenu
        projectId={projectSlug}
        orgId={orgSlug}
        getProjectResources={async () => {
          const { html, matchedImages } = await compileLanguageForExport({
            src,
            language: previewOptions.language,
            files: uploadingAndUploadedImages,
          });

          return {
            html,
            matchedImages,
          };
        }}
      />

      <Checkbox
        colorScheme="gray"
        size="sm"
        css={tw`pl-4 hover:bg-gray-100`}
        onChange={(e) => setWordWrap(e.target.checked ? 'on' : 'off')}
        isChecked={wordWrap === 'on'}
      >
        Wrap
      </Checkbox>

      <Checkbox
        colorScheme="gray"
        size="sm"
        css={tw`pl-4 hover:bg-gray-100`}
        onChange={(e) =>
          previewOptionsMutations.setShouldMinify(e.target.checked)
        }
        isChecked={previewOptions.shouldMinify}
      >
        Minify
      </Checkbox>

      <Checkbox
        colorScheme="gray"
        size="sm"
        css={tw`pl-4 hover:bg-gray-100`}
        onChange={(e) =>
          previewOptionsMutations.setPreviewVeevaTags(e.target.checked)
        }
        isChecked={previewOptions.previewVeevaTags}
      >
        Preview Veeva Tags
      </Checkbox>

      <SegmentedControl
        size="xs"
        color="blue"
        data={Object.values(Language).map((language) => {
          return {
            label: language,
            value: language,
          };
        })}
        value={previewOptions.language}
        onChange={(value) => {
          previewOptionsMutations.setLanguage(value as Language);
        }}
      />

      <span style={{ width: '10px', display: 'inline-block' }} />

      <SegmentedControl
        size="xs"
        color="blue"
        data={Object.values(Preview).map((language) => {
          return {
            label: language,
            value: language,
          };
        })}
        value={companionPane}
        onChange={(value) => {
          setCompanionPane(value as Preview);
        }}
      />

      <ToolBarMenuButton onClick={createWindow}>
        <OpenNewWindowIcon width="11px" style={{ display: 'inline-block' }} />
      </ToolBarMenuButton>
    </div>
  );
};
