/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { matchPath } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import tw from 'twin.macro';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactComponent as DownIcon } from '@mailcrunch/assets/fontawesome/regular/chevron-down.svg';
import { ReactComponent as CheckIcon } from '@mailcrunch/assets/fontawesome/regular/check.svg';

import { useAuthContext } from '../auth';
import { useTopNavQuery } from 'src/generated/graphql';
import { routes } from '../routes';
import logo from 'src/mailcrunch-logo.png';
import { CreateOrganization } from '../Org/CreateOrganization';
import { PAGE_WRAPPER_STYLES } from 'src/styles/pageStyles';
import { useMemo } from 'react';

export const TopNav = ({ orgSlug }: { orgSlug: string }) => {
  const { data, loading, error } = useTopNavQuery();

  const { logout } = useAuthContext();

  const location = useLocation();

  const currentRoutePathGetter = useMemo(() => {
    return (
      [routes.projects, routes.memberships, routes.orgSettings].find(
        (route) => {
          return matchPath(location.pathname, {
            path: route.definition,
            exact: false,
          });
        },
      )?.getPath ?? routes.projects.getPath
    );
  }, [location]);

  if (error) {
    throw error;
  }

  const currentUser = data?.currentUser;

  const currentOrgLogoUrl = currentUser?.memberships?.find((membership) => {
    return membership.organization.slug === orgSlug;
  })?.organization.logoUrl;

  return (
    <>
      <div css={[tw`flex px-6`, PAGE_WRAPPER_STYLES]}>
        <div css={tw`flex flex-row items-center justify-center mt-4 mb-0`}>
          <img src={logo} alt="logo" css={tw`w-8 mr-2`} />
          <span css={tw`mx-4 text-3xl text-gray-200 text-center font-light`}>
            /
          </span>
          <Popover closeOnBlur={true} closeOnEsc={true}>
            <PopoverTrigger>
              <button
                css={tw`flex flex-row justify-center items-center font-semibold py-1 px-2 rounded-md`}
              >
                <img
                  src={
                    currentOrgLogoUrl ??
                    `https://source.boringavatars.com/marble/120/${orgSlug}?colors=00C253,F9E575,8FF594,32CECE,91D3EC
`
                  }
                  alt=""
                  css={tw`w-7 h-7 rounded-full overflow-hidden bg-gray-300 mr-2`}
                />
                {orgSlug}
                <span css={tw`hover:bg-gray-200 p-2 rounded-md ml-2`}>
                  <DownIcon
                    className="icon"
                    css={css`
                      width: 10px;
                    `}
                  />
                </span>
              </button>
            </PopoverTrigger>
            <PopoverContent
              css={tw`flex flex-col items-start justify-start px-2 pt-2 mr-2 overflow-hidden overflow-y-scroll`}
              maxWidth="240"
              minWidth="180"
              maxHeight="300"
              shadow="lg"
              borderRadius="10px"
            >
              {loading && <div css={tw`text-center mx-auto`}>loading...</div>}
              {currentUser?.memberships.map((membership) => {
                const currentOrgSlug = membership.organization.slug;

                return (
                  <NavLink
                    key={membership.id}
                    css={tw`rounded-[4px] py-1 hover:bg-gray-200 w-full`}
                    to={currentRoutePathGetter({ orgSlug: currentOrgSlug })}
                    onClick={(e) => e.currentTarget.blur()}
                  >
                    <span css={tw`flex items-center px-2 py-1`}>
                      <img
                        src={
                          membership?.organization?.logoUrl ??
                          `https://source.boringavatars.com/marble/120/${membership.organization.slug}?colors=00C253,F9E575,8FF594,32CECE,91D3EC
`
                        }
                        alt=""
                        css={tw`w-6 h-6 rounded-full overflow-hidden bg-gray-300 mr-2`}
                      />
                      {membership.organization.slug}
                      {membership.organization.slug === orgSlug && (
                        <CheckIcon
                          className="icon"
                          css={css`
                            width: 12px;
                            margin-left: auto;
                          `}
                        />
                      )}
                    </span>
                  </NavLink>
                );
              })}

              <div
                css={tw`flex justify-center items-center sticky bottom-0 bg-white w-full`}
              >
                {currentUser && (
                  <CreateOrganization
                    currentUserId={currentUser.id}
                    buttonSize={'sm'}
                  />
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>

        <div
          css={tw`mt-4 mb-0 flex flex-row items-center justify-center ml-auto`}
        >
          <Popover>
            <PopoverTrigger>
              <button
                css={tw`w-7 h-7 rounded-full overflow-hidden bg-gray-300`}
              >
                {currentUser?.avatarUrl && (
                  <img
                    css={tw`w-full h-full`}
                    src={currentUser.avatarUrl}
                    alt="user avatar"
                  />
                )}
              </button>
            </PopoverTrigger>
            <PopoverContent
              css={tw`flex flex-col items-center justify-center py-4 px-8 w-max mr-2`}
            >
              <span>{currentUser?.name}</span>
              <span>{currentUser?.email}</span>
              <Button size="sm" css={tw`mt-4`} onClick={() => logout()}>
                Logout
              </Button>
              <PopoverArrow />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <div css={[tw`flex flex-row px-6`, PAGE_WRAPPER_STYLES]}>
        <TopNavLink
          activeClassName="active"
          css={tw`-ml-3`}
          to={routes.projects.getPath({ orgSlug })}
        >
          Projects
        </TopNavLink>
        <TopNavLink to={routes.orgSettings.getPath({ orgSlug })}>
          Settings
        </TopNavLink>
      </div>
      <hr />
    </>
  );
};

const TopNavLink = styled(NavLink)`
  padding: 16px 12px;
  color: #666;
  &.active,
  &:hover {
    color: #000;
  }
  &.active {
    display: block;
    position: relative;
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      height: 0;
      left: 12px;
      right: 12px;
      bottom: 0;
      border-bottom: 2px solid;
    }
  }
`;
