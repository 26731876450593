import { SerializedStyles } from '@emotion/utils';
import Stylis from '@emotion/stylis';
import { EmotionCache, Options } from 'create-emotion';
import { Dictionary } from 'lodash';

const removeLabel = (context: number, content: string) => {
  if (
    context === 1 &&
    // charcode for l
    content.charCodeAt(0) === 108 &&
    // charcode for b
    content.charCodeAt(2) === 98
    // this ignores label
  ) {
    return '';
  }
};

type UnaryFn<Arg, Return> = (arg: Arg) => Return;

var weakMemoize = function <Arg extends object, Return>(
  func: UnaryFn<Arg, Return>,
): UnaryFn<Arg, Return> {
  var cache = new WeakMap();
  return function (arg) {
    if (cache.has(arg)) return cache.get(arg);
    var ret = func(arg);
    cache.set(arg, ret);
    return ret;
  };
};

let getServerStylisCache = weakMemoize(() => {
  let getCache = weakMemoize(() => ({}));
  let prefixTrueCache = {};
  let prefixFalseCache = {};
  return (prefix: any) => {
    if (prefix === undefined || prefix === true) {
      return prefixTrueCache;
    }
    if (prefix === false) {
      return prefixFalseCache;
    }
    return getCache(prefix);
  };
});

export const makeInsert = (options: Options, cache: EmotionCache) => {
  let rootServerStylisCache: Dictionary<string> = {};

  let insert: (
    selector: string,
    serialized: SerializedStyles,
    sheet: StyleSheet,
    shouldCache: boolean,
  ) => string | void;

  let stylis = new Stylis({ prefix: options.prefix });

  stylis.use(removeLabel);
  let serverStylisCache = rootServerStylisCache;
  if (options.stylisPlugins || options.prefix !== undefined) {
    stylis.use(options.stylisPlugins);
    // $FlowFixMe
    serverStylisCache = getServerStylisCache(
      options.stylisPlugins || rootServerStylisCache,
    )(options.prefix);
  }
  let getRules = (selector: string, serialized: SerializedStyles): string => {
    let name = serialized.name;
    if (serverStylisCache[name] === undefined) {
      serverStylisCache[name] = stylis(selector, serialized.styles);
    }
    return serverStylisCache[name];
  };

  insert = (
    selector: string,
    serialized: SerializedStyles,
    sheet: StyleSheet,
    shouldCache: boolean,
  ): string | void => {
    let name = serialized.name;
    let rules = getRules(selector, serialized);
    if (cache.compat === undefined) {
      // in regular mode, we don't set the styles on the inserted cache
      // since we don't need to and that would be wasting memory
      // we return them so that they are rendered in a style tag
      if (shouldCache) {
        cache.inserted[name] = true;
      }
      if (
        // using === development instead of !== production
        // because if people do ssr in tests, the source maps showing up would be annoying
        process.env.NODE_ENV === 'development' &&
        serialized.map !== undefined
      ) {
        return rules + serialized.map;
      }
      return rules;
    } else {
      // in compat mode, we put the styles on the inserted cache so
      // that emotion-server can pull out the styles
      // except when we don't want to cache it which was in Global but now
      // is nowhere but we don't want to do a major right now
      // and just in case we're going to leave the case here
      // it's also not affecting client side bundle size
      // so it's really not a big deal

      if (shouldCache) {
        cache.inserted[name] = rules;
      } else {
        return rules;
      }
    }
  };

  return insert;
};
