/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import React, { useMemo } from 'react';
import { useAuthContext } from '../auth';
import queryString from 'querystring';
import { useRouter } from 'src/useRouter';
import { LoadingScreen } from '../LoadingScreen';
import { FirebaseLoginWidget } from './FirebaseLoginWidget';
import { HandleLoginSuccess } from 'src/HandleLoginSuccess/HandleLoginSuccess';

export const Login = ({
  shouldRedirectToWhereTheUserWas = true,
}: {
  shouldRedirectToWhereTheUserWas: boolean;
}) => {
  const route = useRouter();
  const { user, initializing, error } = useAuthContext();
  const whereTheUserWasBeforeLoggingIn = useMemo(() => {
    const params = queryString.parse(route.location.search.slice(1));
    return typeof params.postLoginRedirect === 'string'
      ? params.postLoginRedirect
      : null;
  }, [route.location.search]);

  if (initializing) {
    console.log('Login - initializing');
    return <LoadingScreen />;
  }
  if (error) {
    console.log('Login - error');
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    console.log('Login - have user, redirect?');
    if (!shouldRedirectToWhereTheUserWas) {
      return null;
    }

    return (
      <HandleLoginSuccess
        whereTheUserWasBeforeLoggingIn={whereTheUserWasBeforeLoggingIn}
      />
    );
  }

  console.log('Login - no user found?');

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <FirebaseLoginWidget />
    </div>
  );
};
