/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Select } from '@chakra-ui/react';

export const autoSaveOptions = [
  { label: 'every minute', value: 1 * 60_000 },
  { label: 'every 5 minutes', value: 5 * 60_000 },
  { label: 'disabled', value: 'null' },
] as const;

export const SavingConfig = ({
  autoSaveFrequency,
  setAutoSaveFrequency,
}: {
  autoSaveFrequency: number | null;
  setAutoSaveFrequency: (value: number | null) => void;
}) => {
  return (
    <div css={tw`flex p-2 hover:bg-gray-200`}>
      <Select
        size="sm"
        variant="unstyled"
        cursor="pointer"
        onChange={(e) => {
          e.preventDefault();
          setAutoSaveFrequency(
            e.target.value === 'null' ? null : Number(e.target.value),
          );
        }}
      >
        {autoSaveOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
            selected={
              autoSaveFrequency === option.value ||
              (autoSaveFrequency === null && option.value === 'null')
            }
          >
            Auto save {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
};
