import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import firebase from 'firebase/app';

import { getProjectCollectionReference } from '../../resources';
import { blobToFile, useFileUpload } from '../../useFileUpload';
import { shallowMergeList } from '../shallowMergeList';
import { useAuth } from '../../auth';
import { useMemo } from 'react';
import { getDefaultSource } from './getDefaultSource';
import {
  Project,
  ProjectFile,
  ProjectMethodsAndStuff,
  ProjectOutOfDateError,
  UpdateProjectError,
} from './typesAndErrors';
import { useToast } from '@chakra-ui/react';

export const useFirebaseProject = ({
  projectId,
  orgId,
}: {
  projectId: string;
  orgId: string;
}): [Project, ProjectMethodsAndStuff] => {
  const toast = useToast();
  const projectDoc = getProjectCollectionReference(orgId).doc(projectId);
  const { data: projectData, refetch, error } = useQuery(
    ['projectData', { projectId, orgId }],
    async () => {
      const response = await projectDoc.get({ source: 'server' });
      const data = response.data();
      return Object.assign(
        {
          id: projectId,
          name: data?.name,
          files: [],
          src: getDefaultSource(projectId),
          assetNameTransformTemplate: undefined,
        },
        data,
      );
    },
    {
      suspense: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false, // this is too trigger happy and fires on re-receiving focus from iframe
      refetchOnMount: false, // don't refetch every time this hook is rendered (this re-renders rather frequently)
    },
  );

  if (!projectData) {
    throw new Error(
      `Could not fetch project data for ${JSON.stringify({
        projectId,
        orgId,
      })}`,
    );
  }

  const [
    updateProjectError,
    setUpdateProjectError,
  ] = useState<UpdateProjectError | null>(null);
  const errors = useMemo(() => [updateProjectError, error].filter(Boolean), [
    updateProjectError,
    error,
  ]);

  const [isSavingProject, setIsSavingProject] = useState(false);

  React.useEffect(() => console.log('projectDoc changed'), [projectDoc]);
  React.useEffect(() => console.log('projectData changed'), [projectData]);
  const { user } = useAuth();
  const [uploadFile, uploadStatusMap] = useFileUpload(orgId, projectId);
  const uid = user?.uid;
  const userDisplayName = user?.displayName;
  const lastModifiedAccordingToLocalState =
    projectData.lastModified?.seconds ?? null;

  const updateProjectDoc = useCallback(
    async (values: Partial<Project>, shouldSetLastModifiedBy = true) => {
      if (
        projectData.files &&
        projectData.files.length &&
        values.files &&
        values.files.length < projectData.files.length &&
        window.location.hostname === 'localhost'
      ) {
        // image uploads sometimes get wiped, why?
        console.error(
          'about to overwrite existing files with new files that have fewer files',
        );
        debugger;
      }
      setIsSavingProject(true);

      const lastModifiedAccordingToServer =
        (await projectDoc.get({ source: 'server' })).get('lastModified')
          ?.seconds ?? null;

      if (lastModifiedAccordingToServer !== lastModifiedAccordingToLocalState) {
        setUpdateProjectError(
          new ProjectOutOfDateError(
            `Cannot save project. Local version is out of date.`,
          ),
        );
        return;
      } else {
        setUpdateProjectError(null);
        await projectDoc.set(
          {
            ...values,
            createdAt:
              projectData.createdAt || firebase.firestore.Timestamp.now(),
            createdBy: (projectData.createdBy || userDisplayName) ?? null,
            ...(shouldSetLastModifiedBy
              ? {
                  lastModified: firebase.firestore.Timestamp.now(),
                  // xxx(HACK): user might not have a username
                  lastModifiedBy: userDisplayName || uid,
                  lastModifiedByUid: uid,
                }
              : {}),
          },
          {
            merge: true,
          },
        );
        await refetch();
        setIsSavingProject(false);
        return projectDoc.id;
      }
    },
    [
      projectData.files,
      projectData.createdAt,
      projectData.createdBy,
      lastModifiedAccordingToLocalState,
      projectDoc,
      userDisplayName,
      uid,
      refetch,
    ],
  );

  const updateProjectSrc = async (src: string) => {
    console.log('updateProjectSrc');
    const returnValue = await updateProjectDoc({ src });
    toast({
      title: 'Project saved',
      status: 'success',
      isClosable: false,
      position: 'bottom-right',
      variant: 'subtle',
      duration: 3000,
    });
    return returnValue;
  };

  const duplicateProject = useCallback(
    async ({
      orgId,
      newProjectId,
      newProjectName,
    }: {
      orgId: string;
      newProjectId: string;
      newProjectName: string;
    }) => {
      const orgProjectsRef = getProjectCollectionReference(orgId);
      const newProjectDocRef = orgProjectsRef.doc(newProjectId);
      const newProjectDoc = await newProjectDocRef.get();
      if (newProjectDoc.exists) {
        throw new Error('Project name already exists');
      }
      const now = firebase.firestore.Timestamp.now();
      await newProjectDocRef.set(
        _.omitBy(
          {
            ...projectData,
            name: newProjectName,
            lastModified: now,
            createdAt: now,
            lastModifiedByUid: uid,
            lastModifiedBy: userDisplayName,
            createdBy: userDisplayName,
            createdByUid: uid,
            assetNameTransformTemplate: null,
          },
          (x) => x === undefined,
        ),
      );
      return newProjectId;
    },
    [projectData, uid, userDisplayName],
  );

  // XXX(filesUploadeInCurrentSession): work around how projectData doesn't actually update, we fetch the data once
  // when we load it, and furthur updates don't actually result in the data updating
  // This is a bit unexpected, but re-fetching after every update is rather inefficient
  // possibly something we can fix once we switch to react-query
  const [
    filesUploadeInCurrentSession,
    setFilesUploadeInCurrentSession,
  ] = useState<readonly ProjectFile[]>([]);
  const filesUploadedBeforeCurrentSession = projectData.files;
  const addFiles = useCallback(
    async (newFiles: Array<ProjectFile>) => {
      const newValueForFilesUploadedInCurrentSession = _.uniqBy(
        [...newFiles, ...filesUploadeInCurrentSession],
        // each relative path can only map to one file
        (x) => x.relativePath,
      );
      setFilesUploadeInCurrentSession(newValueForFilesUploadedInCurrentSession);

      const files = _.uniqBy(
        [
          ...newValueForFilesUploadedInCurrentSession,
          ...filesUploadedBeforeCurrentSession,
        ],
        // each relative path can only map to one file
        (x) => x.relativePath,
      );

      await updateProjectDoc({
        files,
      });
      return;
    },
    [
      filesUploadeInCurrentSession,
      filesUploadedBeforeCurrentSession,
      updateProjectDoc,
    ],
  );

  const removeFile = useCallback(
    async (fileToRemove: ProjectFile) => {
      const newValueForFilesUploadedInCurrentSession = filesUploadeInCurrentSession.filter(
        (x: ProjectFile) => x.relativePath !== fileToRemove.relativePath,
      );
      setFilesUploadeInCurrentSession(newValueForFilesUploadedInCurrentSession);

      const files = _.uniqBy(
        [
          ...newValueForFilesUploadedInCurrentSession,
          ...filesUploadedBeforeCurrentSession,
        ],
        // each relative path can only map to one file
        (x) => x.relativePath,
      ).filter(
        (x: ProjectFile) => x.relativePath !== fileToRemove.relativePath,
      );

      await updateProjectDoc({
        files,
      });
    },
    [
      filesUploadeInCurrentSession,
      filesUploadedBeforeCurrentSession,
      updateProjectDoc,
    ],
  );

  // images that were uploaded this session; these include images
  // that couldn't be uploaded and completed uploads

  const uploadStatuses = Object.entries(uploadStatusMap).map(
    ([relativePath, uploadStatus]) => ({
      relativePath,
      uploadStatus,
      ...(uploadStatus.downloadUrl
        ? { remoteUrl: uploadStatus.downloadUrl }
        : {}),
    }),
  );

  const uploadingAndUploadedImages = shallowMergeList(
    (projectData.files || []) as ProjectFile[],
    uploadStatuses,
    (x) => x.relativePath,
  );

  const uploadAndSetProjectThumbnail = useCallback(
    async (blob: Blob) => {
      console.log('updating project thumbnail');
      const remoteUrl = await uploadFile(
        blobToFile(blob, 'thumbnail.png'),
        'thumbnail.png',
      );
      const now = firebase.firestore.Timestamp.now();

      updateProjectDoc({ thumbnail: { remoteUrl, lastModified: now } }, false);

      return remoteUrl;
    },
    [updateProjectDoc, uploadFile],
  );

  const renameProject = useCallback(
    async (newName: string) => {
      return updateProjectDoc({ name: newName });
    },
    [updateProjectDoc],
  );

  const setProjectAssetNameTransformTemplate = useCallback(
    (assetNameTransformTemplate: string) => {
      updateProjectDoc({ assetNameTransformTemplate });
    },
    [updateProjectDoc],
  );

  return [
    projectData,
    {
      uploadFile,
      uploadAndSetProjectThumbnail,
      uploadingAndUploadedImages,
      updateProjectSrc,
      isSavingProject,
      addFiles,
      removeFile,
      duplicateProject,
      renameProject,
      setProjectAssetNameTransformTemplate,
      errors,
      loading: false,
    },
  ];
};
