/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import tw from 'twin.macro';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import _ from 'lodash';
import { Prompt } from 'react-router-dom';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import SplitPane from 'react-split-pane';
import { Spinner } from '@chakra-ui/react';
import {
  Project,
  UploadingAndUploadedFile,
  useProjectContext,
} from 'src/utils/useProject/useProject';
import { CompileOptions, compile } from 'src/compile';
import { useCrossWindowPreviewOptions } from 'src/utils/crossWindow/useCrossWindowPreviewOptions';
import { useCrossWindowCompileSender } from 'src/utils/crossWindow/useCrossWindowCompileSender';
import { useWindowManager } from 'src/utils/crossWindow/useWindowManager';
import { useDebouncedEffect } from 'src/utils/useDebouncedEffect';
import Editor from './Editor';
import { splitpaneStyles } from './Workbench.styles';
import { Preview, ToolBar } from './ToolBar/ToolBar';
import { HTMLPreviewEditor } from './HTMLPreviewEditor';
import { usePrevious } from 'src/utils/usePrevious';
import { SideContent, SideNavbar } from './SideNavbar';
import { SideDisplay } from './SideDisplay';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import { autoSaveOptions } from './ToolBar/SavingConfig';
import { formatDistanceToNowStrict } from 'date-fns';
import { IS_MAC } from 'src/utils/IS_MAC';

export const FirebaseWorkbench = ({
  projectSlug,
  orgSlug,
}: {
  projectSlug: string;
  orgSlug: string;
}) => {
  const [
    project,
    { updateProjectSrc, uploadingAndUploadedImages },
  ] = useProjectContext();

  return (
    <Workbench
      projectSlug={projectSlug}
      orgSlug={orgSlug}
      project={project}
      updateProjectSrc={updateProjectSrc}
      uploadingAndUploadedImages={uploadingAndUploadedImages}
    />
  );
};

export const Workbench = ({
  projectSlug,
  orgSlug,
  project,
  uploadingAndUploadedImages,
  updateProjectSrc,
  isSavingProject,
}: {
  projectSlug: string;
  orgSlug: string;
  project: Project;
  uploadingAndUploadedImages: UploadingAndUploadedFile[];
  updateProjectSrc: (src: string) => Promise<string | undefined | void>;
  isSavingProject: boolean;
}) => {
  const [, forceRender] = useReducer((state) => (state += 1), 1);
  const editorRef = useRef<monacoEditor.editor.IStandaloneCodeEditor>(
    null,
  ) as React.MutableRefObject<monacoEditor.editor.IStandaloneCodeEditor>;
  const previewIframeRef = useRef<HTMLIFrameElement>(null);

  const [autoSaveFrequency, setAutoSaveFrequency] = useLocalStorage<
    number | null
  >('autoSaveFrequency', autoSaveOptions[0].value);

  const [src, setSrc] = useState(project.src);
  const previousSrc = usePrevious(src);

  const hasUnsavedChanges = src !== project.src;

  useDebouncedEffect(
    () => {
      if (hasUnsavedChanges && autoSaveFrequency !== null) {
        updateProjectSrc(src);
      }
    },
    autoSaveFrequency ?? 0,
    [previousSrc, src],
  );

  const [companionPane, setCompanionPane] = useState(Preview.Preview);

  const [
    previewOptions,
    previewOptionsMutations,
  ] = useCrossWindowPreviewOptions({ orgId: orgSlug, projectId: projectSlug });

  const persistedWordWrapPreference = useMemo(
    () => window.localStorage.getItem('eb:wordWrap'),
    [],
  );
  const [wordWrap, setWordWrap] = useState<
    monacoEditor.editor.IEditorConstructionOptions['wordWrap']
  >(
    persistedWordWrapPreference &&
      ['off', 'on', 'wordWrapColumn', 'bounded'].includes(
        persistedWordWrapPreference,
      )
      ? (persistedWordWrapPreference as monacoEditor.editor.IEditorConstructionOptions['wordWrap'])
      : 'off',
  );

  useEffect(() => {
    const onKeyDown = function (e: KeyboardEvent) {
      if ((IS_MAC ? e.metaKey : e.ctrlKey) && e.key === 's') {
        e.preventDefault();
        updateProjectSrc(src);
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [updateProjectSrc, src]);

  const contentWindow = previewIframeRef.current?.contentWindow;

  useEffect(() => {
    // XXX: need to force rerender for dependencies relying on previewIframeRef.current
    // to rerender with updated value
    if (!contentWindow && previewIframeRef.current) {
      const ref = previewIframeRef.current;
      ref.addEventListener('load', forceRender);
      return () => {
        if (ref) {
          ref.addEventListener('load', forceRender);
        }
      };
    }
  }, [contentWindow]);

  const windowManager = useWindowManager();
  const recipients = useMemo(() => {
    return contentWindow
      ? [
          {
            window: contentWindow,
            syncCompileOptions: true,
          },
          ...windowManager.windows.map((w) => ({
            window: w,
            syncCompileOptions: false,
          })),
        ]
      : [];
  }, [contentWindow, windowManager.windows]);

  useCrossWindowCompileSender({
    src,
    files: uploadingAndUploadedImages,
    previewOptions,
    recipients,
  });

  const [isDragging, setIsDragging] = useState(false);

  const [compiledEditorHtml, setCompiledEditorHtml] = useState('');
  const compileCallback = useMemo(() => {
    return _.debounce(async (args: CompileOptions) => {
      const html = (await compile(args)).html;
      setCompiledEditorHtml(html);
    }, 350);
  }, []);

  useEffect(() => {
    if (companionPane !== Preview.Html) {
      return;
    }
    compileCallback({
      src,
      language: previewOptions.language,
      files: uploadingAndUploadedImages,
      remoteImages: true,
      shouldMinify: previewOptions.shouldMinify,
      isForRenderedPreview: false,
    });
  }, [
    companionPane,
    src,
    previewOptions.language,
    uploadingAndUploadedImages,
    previewOptions.shouldMinify,
    compileCallback,
  ]);

  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth);
  const [paneRatio, setPaneRatio] = useState(() => {
    if (Number(window.localStorage.getItem('eb:paneSize')) / 100 > 1) {
      return 0.5;
    } else {
      return Number(window.localStorage.getItem('eb:paneSize')) / 100 || 0.5;
    }
  });

  useEffect(() => {
    const listener = _.throttle(() => setWindowWidth(window.innerWidth), 300);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [paneRatio]);

  const [sideContent, setSideContent] = useState<SideContent>(null);
  const [sideContentWidth, setSideContentWidth] = useLocalStorage<number>(
    'sideContentWidth',
    300,
  );

  const mainEditorOptions: monacoEditor.editor.IEditorConstructionOptions = useMemo(
    () => ({
      wordWrap,
      readOnly: false,
    }),
    [wordWrap],
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      <Prompt
        when={hasUnsavedChanges}
        message={() =>
          `You have unsaved changes. Are you sure you want to navigate away from this page?`
        }
      />
      <ToolBar
        previewOptions={previewOptions}
        previewOptionsMutations={previewOptionsMutations}
        editorRef={editorRef}
        projectSlug={projectSlug}
        orgSlug={orgSlug}
        src={src}
        companionPane={companionPane}
        createWindow={windowManager.createWindow}
        setCompanionPane={(pane) => {
          setCompanionPane(pane);
          ReactGA.event({
            category: 'Workbench',
            action: 'Set CompanionPane',
            label: pane,
          });
        }}
        wordWrap={wordWrap}
        setWordWrap={(value) => {
          setWordWrap(value);
          value && window.localStorage.setItem('eb:wordWrap', value);
          ReactGA.event({
            category: 'Workbench',
            action: 'Set Word Wrap',
            label: value,
          });
        }}
        autoSaveFrequency={autoSaveFrequency}
        setAutoSaveFrequency={setAutoSaveFrequency}
        saveProjectSrc={updateProjectSrc}
      />
      <div css={tw`flex flex-col h-full`}>
        <div css={tw`flex flex-row h-full`}>
          <div
            css={tw`flex h-full flex-col items-center px-2 py-2 flex-shrink-0 gap-2 bg-gray-900`}
          >
            <SideNavbar
              sideContent={sideContent}
              setSideContent={setSideContent}
            />
          </div>
          <div css={tw`flex flex-col flex-grow relative`}>
            <SplitPane
              css={[splitpaneStyles, tw`bg-gray-800`]}
              split="vertical"
              minSize={200}
              maxSize={undefined}
              allowResize={sideContent !== null}
              size={sideContent === null ? 0 : sideContentWidth}
              onChange={setSideContentWidth}
            >
              <div css={tw`flex h-full flex-col items-center`}>
                <SideDisplay
                  content={sideContent}
                  editorRef={editorRef}
                  src={src}
                />
              </div>
              <div
                className="Workbench"
                css={css`
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  position: relative;
                `}
              >
                <SplitPane
                  css={splitpaneStyles}
                  split="vertical"
                  minSize={100}
                  maxSize={-100}
                  onDragStarted={() => setIsDragging(true)}
                  onDragFinished={(newSize) => {
                    setIsDragging(false);
                    window.localStorage.setItem(
                      'eb:paneSize',
                      JSON.stringify((newSize / windowWidth) * 100),
                    );
                    setPaneRatio(newSize / windowWidth);
                  }}
                  size={paneRatio * windowWidth}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 100%;
                      height: 100%;
                    `}
                  >
                    <Editor
                      editorRef={editorRef}
                      css={css`
                        flex: 1;
                        flex-basis: 1px;
                        width: 1px;
                      `}
                      src={src}
                      onChange={(newSrc) => setSrc(newSrc)}
                      options={mainEditorOptions}
                      editorDidMount={(mountedEditor) => {
                        mountedEditor.focus();
                      }}
                    />
                  </div>
                  <div
                    css={css`
                      display: flex;
                      width: 100%;
                      height: 100%;
                      ${isDragging &&
                      css`
                        pointer-events: none;
                      `}
                    `}
                  >
                    {companionPane === Preview.Html && (
                      <HTMLPreviewEditor
                        src={compiledEditorHtml}
                        css={css`
                          width: 1px;
                          flex: 1;
                          display: flex;
                          flex-basis: 1px;
                          min-width: 320px;
                        `}
                      />
                    )}
                    <iframe
                      ref={previewIframeRef}
                      src="/display"
                      css={css`
                        width: 1px;
                        min-width: 320px;
                        flex-basis: 1px;
                        background-color: #ffffff;
                        border: none;
                        flex: 1;
                        display: ${companionPane === Preview.Preview
                          ? 'block'
                          : 'none'};
                      `}
                      title="preview"
                    />
                  </div>
                </SplitPane>
              </div>
            </SplitPane>
          </div>
        </div>
      </div>
      <div css={tw`bg-gray-800 text-white text-sm py-1 px-3`}>
        {isSavingProject ? (
          <>
            <span css={tw`relative w-3 h-3 inline-block mr-2`}>
              <Spinner css={tw`absolute w-full h-full left-0`} />
            </span>
            Saving project...
          </>
        ) : hasUnsavedChanges ? (
          `Press ${IS_MAC ? '⌘' : 'Ctrl'} + S to save changes`
        ) : (
          !!project.lastModified && (
            <>
              Last saved{' '}
              {formatDistanceToNowStrict(new Date(project.lastModified))} ago by{' '}
              {project.lastModifiedBy}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default FirebaseWorkbench;
