import React from 'react';
import { LoadingScreen } from 'src/LoadingScreen';
import { useProjectContext } from 'src/utils/useProject/useProject';
import { Workbench } from 'src/Workbench/Workbench';

export const Project = ({
  projectSlug,
  orgSlug,
}: {
  projectSlug: string;
  orgSlug: string;
}) => {
  const [
    project,
    { updateProjectSrc, isSavingProject, uploadingAndUploadedImages, loading },
  ] = useProjectContext();

  if (loading) return <LoadingScreen />;
  if (!project) return <div>No project found</div>;

  return (
    <>
      <Workbench
        projectSlug={projectSlug}
        orgSlug={orgSlug}
        project={project}
        updateProjectSrc={updateProjectSrc}
        uploadingAndUploadedImages={uploadingAndUploadedImages}
        isSavingProject={isSavingProject}
      />
    </>
  );
};
