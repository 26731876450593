export const routes = {
  root: {
    definition: '/',
  },
  org: {
    definition: '/orgs/:orgSlug',
    getPath: ({ orgSlug }: { orgSlug: string }) => `/orgs/${orgSlug}`,
  },
  orgSettings: {
    definition: '/orgs/:orgSlug/settings',
    getPath: ({ orgSlug }: { orgSlug: string }) => `/orgs/${orgSlug}/settings`,
  },
  orgSettingsGeneral: {
    definition: '/orgs/:orgSlug/settings/general',
    getPath: ({ orgSlug }: { orgSlug: string }) =>
      `/orgs/${orgSlug}/settings/general`,
  },
  memberships: {
    definition: '/orgs/:orgSlug/settings/members',
    getPath: ({ orgSlug }: { orgSlug: string }) =>
      `/orgs/${orgSlug}/settings/members`,
  },
  projects: {
    definition: '/orgs/:orgSlug/projects',
    getPath: ({ orgSlug }: { orgSlug: string }) => `/orgs/${orgSlug}/projects`,
  },
  project: {
    definition: '/orgs/:orgSlug/projects/:projectSlug',
    getPath: ({
      orgSlug,
      projectSlug,
    }: {
      orgSlug: string;
      projectSlug: string;
    }) => `/orgs/${orgSlug}/projects/${projectSlug}`,
  },
  projectEditor: {
    definition: '/orgs/:orgSlug/projects/:projectSlug/editor',
    getPath: ({
      orgSlug,
      projectSlug,
    }: {
      orgSlug: string;
      projectSlug: string;
    }) => `/orgs/${orgSlug}/projects/${projectSlug}/editor`,
  },
  projectViewer: {
    definition: '/orgs/:orgSlug/projects/:projectSlug/viewer',
    getPath: ({
      orgSlug,
      projectSlug,
    }: {
      orgSlug: string;
      projectSlug: string;
    }) => `/orgs/${orgSlug}/projects/${projectSlug}/viewer`,
  },
  history: {
    definition: '/orgs/:orgSlug/projects/:projectSlug/history',
    getPath: ({
      orgSlug,
      projectSlug,
    }: {
      orgSlug: string;
      projectSlug: string;
    }) => `/orgs/${orgSlug}/projects/${projectSlug}/history`,
  },
  acceptInvitation: {
    definition: '/accept-invitation/:invitationToken',
    getPath: ({
      orgSlug,
      invitationCode,
    }: {
      orgSlug: string;
      invitationCode: string;
    }) => `/accept-invitation/${orgSlug}:${invitationCode}`,
  },
  login: {
    definition: '/login',
    getPath: ({ postLoginRedirect }: { postLoginRedirect?: string } = {}) =>
      `/login${
        postLoginRedirect
          ? `?postLoginRedirect=${window.encodeURIComponent(postLoginRedirect)}`
          : ''
      }`,
  },
} as const;
