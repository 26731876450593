import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { UploadingAndUploadedFile } from 'src/utils/useProject/useProject';
import { PreviewOptions } from './useCrossWindowPreviewOptions';

interface Recipient {
  window: Window;
  syncCompileOptions: boolean;
}

export const useCrossWindowCompileSender = ({
  src,
  files,
  previewOptions,
  recipients,
}: {
  src: string;
  files: UploadingAndUploadedFile[];
  previewOptions: PreviewOptions;
  recipients: Array<Recipient>;
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postMessageToAllRecipients = useCallback(
    _.debounce(
      (data: {
        src?: string;
        files?: UploadingAndUploadedFile[];
        previewOptions?: PreviewOptions;
      }) => {
        recipients.forEach((recipient) => {
          recipient.window.postMessage(
            { type: 'update', data },
            window.location.origin,
          );
        });
      },
      200,
    ),
    [recipients],
  );

  useEffect(() => postMessageToAllRecipients({ src, files }), [
    postMessageToAllRecipients,
    src,
    files,
  ]);

  useEffect(() => {
    recipients
      .filter((recipient) => recipient.syncCompileOptions)
      .forEach((recipient) => {
        recipient.window.postMessage(
          { type: 'update', data: { previewOptions } },
          window.location.origin,
        );
      });
  }, [previewOptions, recipients]);

  const [recipientsAwaitingInit, setRecipientsAwaitingInit] = useState<
    Recipient[]
  >([]);

  useEffect(() => {
    recipients.forEach((recipient) => {
      if (recipientsAwaitingInit.includes(recipient)) {
        // avoid this being called on the same window more than once
        return;
      }
      setRecipientsAwaitingInit(recipientsAwaitingInit.concat(recipient));
      const onMessage = (e: MessageEvent) => {
        if (e.data.type === 'init') {
          recipient.window.postMessage(
            {
              type: 'update',
              data: {
                files,
                src,
                previewOptions,
              },
            },
            window.location.origin,
          );
        }
      };
      recipient.window.addEventListener('message', onMessage);
      return () => {
        recipient.window.removeEventListener('message', onMessage);
      };
    });
  }, [src, files, recipients, recipientsAwaitingInit, previewOptions]);
};
