/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from 'src/routes';
import slugify from 'slugify';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { useCreateProjectMutation } from 'src/generated/graphql';

export const CreateProject = ({
  orgSlug,
  className,
}: {
  orgSlug: string;
  className?: string;
}) => {
  const [projectName, setProjectName] = useState('');
  const [error, setError] = useState<{ message: string } | null>();
  const [redirectPath, setRedirectPath] = useState<string>();

  const [createProject, { loading }] = useCreateProjectMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (redirectPath) {
    return <Redirect to={redirectPath} push />;
  }

  const isValid = projectName.trim().length > 0;

  return (
    <>
      <Button
        onClick={onOpen}
        _hover={{ bg: 'blue.500', color: 'white' }}
        css={tw`py-4 px-4 mx-4 flex-shrink-0`}
        size="sm"
      >
        Create Project
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="sm">
        <ModalOverlay />
        <ModalContent
          borderRadius="4px"
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const projectSlug = slugify(projectName.trim());

            if (!isValid) {
              setError({ message: 'Project name cannot be empty' });
              return;
            }

            await createProject({
              variables: {
                name: projectName.trim(),
                slug: projectSlug,
                organizationSlug: orgSlug,
              },
            }).then((response) => {
              if (
                response.data?.createProject?.__typename === 'ErrorNoDuplicates'
              ) {
                setError(response.data.createProject);
              } else {
                setRedirectPath(
                  routes.projectEditor.getPath({
                    orgSlug,
                    projectSlug,
                  }),
                );
              }
            });
          }}
        >
          <ModalHeader color="gray.800" mt="2">
            Create Project
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel
                htmlFor="project-name"
                css={tw`uppercase text-sm text-gray-500`}
              >
                Project Name
              </FormLabel>
              <Input
                id="project-name"
                placeholder="Project Name"
                css={tw`border-l border-t border-b px-2`}
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.currentTarget.value);
                  setError(null);
                }}
              />
            </FormControl>
            {error && (
              <div css={tw`py-2 text-sm text-red-700`}>{error.message}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              size="sm"
              textTransform="uppercase"
              fontWeight="400"
              mr={5}
              onClick={onClose}
              color="gray.600"
            >
              Close
            </Button>
            <Button
              type="submit"
              textTransform="uppercase"
              fontWeight="400"
              colorScheme="blue"
              size="sm"
              transition="none"
              isDisabled={loading || !isValid}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateProject;
