import { imgSrcToBlob } from 'blob-util';
import axios from 'axios';

export const getImageBlobByUrl = async (url: string | undefined) =>
  !url
    ? ''
    : url.match(/\.gif$/)
    ? (
        await axios.get(url, {
          responseType: 'blob',
        })
      ).data
    : imgSrcToBlob(url, undefined, 'Anonymous');
