import React, { useState } from 'react';
import tw from 'twin.macro';
import JSZip from 'jszip';
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { VeevaVaultService } from 'src/utils/veeva/VeevaVaultService';
import Select from 'react-select';
import { VeevaDocument } from '@mailcrunch/veeva-vault/lib/documents/types';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import { UploadingAndUploadedFile } from 'src/utils/useProject/useProject';
import { getImageBlobByUrl } from 'src/utils/getImageBlobByUrl';

export const useCreateVeevaDocumentRevision = ({
  apiHost,
  veevaSessionToken,
  getProjectResources,
  orgId,
  projectId,
}: {
  apiHost: string;
  veevaSessionToken: string | undefined;
  orgId: string;
  projectId: string;
  getProjectResources: () => Promise<{
    html: string;
    matchedImages: UploadingAndUploadedFile[];
  }>;
}) => {
  const modalStuff = useDisclosure();
  const showDocumentUploadUI = () => {
    modalStuff.onOpen();
  };
  return {
    showDocumentUploadUI,
    documentUploadModal: veevaSessionToken && modalStuff.isOpen && (
      <CreateVeevaDocumentRevisionModal
        {...modalStuff}
        veevaSessionToken={veevaSessionToken}
        apiHost={apiHost}
        getProjectResources={getProjectResources}
        orgId={orgId}
        projectId={projectId}
      />
    ),
  };
};

const CreateVeevaDocumentRevisionModal = ({
  apiHost,
  veevaSessionToken,
  isOpen,
  onClose,
  getProjectResources,
  orgId,
  projectId,
}: {
  apiHost: string;
  veevaSessionToken: string;
  isOpen: boolean;
  orgId: string;
  projectId: string;
  onClose: () => void;
  getProjectResources: () => Promise<{
    html: string;
    matchedImages: UploadingAndUploadedFile[];
  }>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string>('');
  const [shouldApproveDocument, setShouldApproveDocument] = useLocalStorage<
    boolean
  >(`veevaVault:userpreference:approveRevision`, false);
  const [selectedDocument, setSelectedDocument] = useLocalStorage<
    VeevaDocument | undefined
  >(`veevaVault:${orgId}/${projectId}:previouslySelectedDocument`, undefined);

  const { data, isFetching } = useQuery(
    ['queryVaultDocuments', { queryText }],
    () => {
      return VeevaVaultService.listDocuments({
        apiHost,
        sessionId: veevaSessionToken,
        search: queryText || undefined,
      });
    },
    {
      keepPreviousData: true,
    },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="sm">
      <ModalOverlay />
      <ModalContent
        borderRadius="4px"
        as="form"
        onSubmit={async (e) => {
          e.preventDefault();

          if (!selectedDocument) {
            throw new Error('No document selected');
          }
          setIsSubmitting(true);

          const { html, matchedImages } = await getProjectResources();
          await VeevaVaultService.createDocumentRevision({
            apiHost,
            approve: shouldApproveDocument,
            documentId: selectedDocument.id,
            fileName: `email-template.htm${'l'}`,
            fileContents: html,
            sessionId: veevaSessionToken,
          });

          const assetsZip = new JSZip();

          await Promise.all(
            matchedImages.map(async (image) => {
              assetsZip.file(
                image.relativePath
                  // veeva's simulator chokes on zips with file paths that start with "."
                  .replace(/^\.\//, ''),
                getImageBlobByUrl(image.remoteUrl),
              );
            }),
          );

          const fileName = `assets-${new Date().toISOString()}.zip`;
          const content = new File(
            [await assetsZip.generateAsync({ type: 'blob' })],
            fileName,
          );

          await VeevaVaultService.uploadDocumentAssets({
            apiHost,
            documentId: selectedDocument.id,
            fileName,
            file: content,
            sessionId: veevaSessionToken,
          });
          setIsSubmitting(false);
          onClose();
        }}
      >
        <ModalHeader color="gray.800" fontSize="1rem" fontWeight="700">
          Upload current email as a draft for
        </ModalHeader>
        <ModalBody>
          <Select
            isDisabled={isSubmitting}
            defaultValue={
              selectedDocument && {
                value: selectedDocument,
                label: selectedDocument.name__v,
              }
            }
            defaultInputValue={selectedDocument?.name__v}
            noOptionsMessage={() =>
              isFetching ? 'Searching..' : 'No documents found'
            }
            options={data?.documents.map(({ document }) => ({
              value: document,
              label: document.name__v,
            }))}
            onChange={(item) => {
              // item might be an array of options
              if (item && 'value' in item) {
                setSelectedDocument(item.value);
              }
            }}
            onInputChange={(value, { action }) => {
              if (action === 'input-change') {
                setQueryText(value);
              }
            }}
          />

          <Checkbox
            isDisabled={isSubmitting}
            css={tw`mt-2`}
            id="approveRevision"
            size="sm"
            isChecked={shouldApproveDocument}
            onChange={(e) => setShouldApproveDocument(e.target.checked)}
          >
            Set as Approved
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            size="sm"
            mr={3}
            onClick={onClose}
            color="gray.600"
          >
            Close
          </Button>
          <Button
            type="submit"
            isDisabled={isSubmitting}
            colorScheme="blue"
            size="sm"
            transition="none"
            _hover={{ boxShadow: '0 1px 3px 1px rgba(0,0,0,0.1)' }}
          >
            {isSubmitting ? 'Uploading' : 'Upload new revision'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
