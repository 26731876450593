import React, { useState } from 'react';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';

import Editor from './Editor';
import { useSetMarkers } from './useSetMarkers';

export const HTMLPreviewEditor: React.FC<{
  className?: string;
  src: string;
}> = ({ className, src }) => {
  const [
    editor,
    setEditor,
  ] = useState<monacoEditor.editor.IStandaloneCodeEditor | null>(null);

  useSetMarkers({ editor });

  return (
    <Editor
      src={src}
      className={className}
      onChange={() => {}}
      options={{
        readOnly: true,
        renderValidationDecorations: 'on',
      }}
      editorDidMount={(editorInstance) => {
        setEditor(editorInstance);
      }}
    />
  );
};
