/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { FullStory } from '@fullstory/browser';

import { useApolloClient } from '@apollo/client';
import { Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { matchPath } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import {
  AuthTokenType,
  useHandleLoginSuccessQuery,
  useInitializeCurrentUserMutation,
} from 'src/generated/graphql';
import { LoadingScreen } from 'src/LoadingScreen';
import { useAuthContext } from 'src/auth';
import { routes } from '../routes';
import { CreateOrganization } from '../Org/CreateOrganization';

export const HandleLoginSuccess = ({
  whereTheUserWasBeforeLoggingIn,
}: {
  whereTheUserWasBeforeLoggingIn?: string | null | undefined;
}) => {
  const history = useHistory();
  const { backendAccessToken } = useAuthContext();
  const apolloClient = useApolloClient();
  const isAcceptingInvitation =
    whereTheUserWasBeforeLoggingIn &&
    matchPath(whereTheUserWasBeforeLoggingIn, {
      path: routes.acceptInvitation.definition,
      exact: false,
    });

  const {
    data: handleLoginSuccessData,
    loading: loadingHandleLoginSuccessData,
  } = useHandleLoginSuccessQuery();

  const doesUserHaveAccessToCallbackUrl = useMemo(() => {
    if (
      !handleLoginSuccessData ||
      !handleLoginSuccessData.currentUser ||
      !whereTheUserWasBeforeLoggingIn
    ) {
      return false;
    }
    const currentUserOrgsSlugs = handleLoginSuccessData.currentUser.memberships.map(
      (membership) => membership.organization.slug,
    );
    const callbackUrlRoute = matchPath(whereTheUserWasBeforeLoggingIn, {
      path: routes.org.definition,
      exact: false,
    });
    const callbackUrlOrgSlug = (callbackUrlRoute?.params as { orgSlug: string })
      .orgSlug;
    return currentUserOrgsSlugs.includes(callbackUrlOrgSlug);
  }, [whereTheUserWasBeforeLoggingIn, handleLoginSuccessData]);

  const [
    initializeNewUser,
    { loading: loadingInitializeNewUser, error: errorInitializeNewUser },
  ] = useInitializeCurrentUserMutation();

  if (loadingHandleLoginSuccessData || loadingInitializeNewUser) {
    return <LoadingScreen />;
  }

  if (!backendAccessToken) {
    return (
      <div css={tw`w-full h-full flex flex-col justify-center items-center`}>
        Apologies, your session has expired. Please log in again.
      </div>
    );
  }

  if (errorInitializeNewUser) {
    return (
      <div css={tw`w-full h-full flex flex-col justify-center items-center`}>
        <p>
          Apologies, there was an error. Please refresh and try logging in
          again.
        </p>
        {JSON.stringify(errorInitializeNewUser)}
      </div>
    );
  }

  if (!handleLoginSuccessData?.currentUser) {
    return (
      <form
        css={tw`w-[500px] pb-12 mx-auto max-w-full h-full flex flex-col justify-center items-center`}
        onSubmit={async (e) => {
          e.preventDefault();
          initializeNewUser({
            variables: {
              // @ts-expect-error TS doesn't know about e.target.username
              name: e.target.username.value,
              token: backendAccessToken,
              tokenType: AuthTokenType.Firebase,
            },
          }).then(async (response) => {
            const newOrgSlug =
              response.data?.initializeCurrentUser?.memberships[0].organization
                .slug;

            if (!newOrgSlug) {
              throw new Error(
                'An error occured while provisioning your user account. Please reach out to support.',
              );
            }
            await apolloClient.clearStore();

            history.push(
              whereTheUserWasBeforeLoggingIn ||
                routes.projects.getPath({ orgSlug: newOrgSlug }),
            );
          });
        }}
      >
        <h1 css={tw`text-4xl mb-16`}>Welcome to Mailcrunch!</h1>
        <FormControl>
          <FormLabel css={tw`text-center`}>Please enter your name</FormLabel>
          <Input
            name="username"
            variant="flushed"
            isRequired
            css={tw`text-center text-2xl`}
          />
        </FormControl>
        <Button css={tw`mt-12`} colorScheme="blue" type="submit">
          Continue
        </Button>
      </form>
    );
  }

  if (
    handleLoginSuccessData?.currentUser?.memberships.length === 0 &&
    !isAcceptingInvitation
  ) {
    return (
      <div css={tw`flex flex-col justify-center items-center w-full`}>
        <h1 css={tw`p-2 font-medium text-4xl pb-6`}>
          You are not a member of any teams.
        </h1>
        <div css={tw``}>
          <CreateOrganization
            currentUserId={handleLoginSuccessData.currentUser.id}
            buttonSize={'lg'}
          />
        </div>
      </div>
    );
  }

  if (whereTheUserWasBeforeLoggingIn && doesUserHaveAccessToCallbackUrl) {
    return <Redirect to={whereTheUserWasBeforeLoggingIn} push />;
  }

  FullStory('setIdentity', {
    uid: handleLoginSuccessData.currentUser.id,
    properties: {
      displayName: handleLoginSuccessData.currentUser.name,
      email: handleLoginSuccessData.currentUser.email,
    },
  });

  if (handleLoginSuccessData.currentUser.memberships.length > 0) {
    return (
      <Redirect
        to={routes.projects.getPath({
          orgSlug:
            handleLoginSuccessData.currentUser.memberships[0].organization.slug,
        })}
        push
      />
    );
  }

  return (
    <div css={tw`w-full h-full flex flex-col justify-center items-center`}>
      <p>
        Apologies, there was an error. Please refresh and try logging in again.
      </p>
    </div>
  );
};
