import tw from 'twin.macro';
import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { VeevaVaultService } from 'src/utils/veeva/VeevaVaultService';
import { useLocalStorage } from 'src/utils/useLocalStorage';

export const useVeevaSessionToken = ({ apiHost }: { apiHost: string }) => {
  const [veevaSessionToken, setVeevaSessionToken] = useLocalStorage<
    string | undefined
  >(`veevaVault:sessiontoken`, undefined);
  const modalStuff = useDisclosure();
  const obtainToken = () => {
    modalStuff.onOpen();
  };
  return {
    veevaSessionToken,
    obtainToken,
    veevaLoginModal: (
      <VeevaLoginModal
        {...modalStuff}
        apiHost={apiHost}
        onTokenChange={(newToken) => setVeevaSessionToken(newToken)}
      />
    ),
  };
};

const VeevaLoginModal = ({
  apiHost,
  isOpen,
  onClose,
  onTokenChange,
}: {
  apiHost: string;
  isOpen: boolean;
  onClose: () => void;
  onTokenChange: (token: string) => void;
}) => {
  const [username, setUsername] = useLocalStorage<string>(
    `veevaVault:username`,
    '',
  );
  const [storedPassword, setStoredPassword] = useLocalStorage<string>(
    'veevaVault:password',
    '',
  );
  const [enteredPassword, setEnteredPassword] = useState(storedPassword);

  const [shouldStorePassword, setShouldStorePassword] = useLocalStorage<
    boolean
  >('veevaVault:shouldStorePassword', false);
  const [isObtainingAccessToken, setIsObtainingAccessToken] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="sm">
      <ModalOverlay />
      <ModalContent
        borderRadius="4px"
        as="form"
        onSubmit={async (e) => {
          e.preventDefault();
          setIsObtainingAccessToken(true);
          shouldStorePassword && setStoredPassword(enteredPassword);
          const sessionId = await VeevaVaultService.getSessionTokenByUsernamePassword(
            {
              apiHost,
              username,
              password: enteredPassword,
            },
          );
          setIsObtainingAccessToken(false);
          onTokenChange(sessionId);
          onClose();
        }}
      >
        <ModalHeader color="gray.800" fontSize="1rem" fontWeight="700">
          Obtain Veeva Vault Access Token
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel
              htmlFor="veevaVaultApiHost"
              color="gray.700"
              fontSize="0.8rem"
            >
              Veeva Vault API Host
            </FormLabel>
            <Input
              id="veevaVaultApiHost"
              size="sm"
              placeholder="https://vv-agency-xyz.veevavault.com/"
              defaultValue={apiHost}
              isDisabled
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="veevaVaultUsername"
              color="gray.700"
              fontSize="0.8rem"
            >
              Username
            </FormLabel>
            <Input
              id="veevaVaultUsername"
              name="veevaVaultUsername"
              size="sm"
              defaultValue={username}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setUsername(e.target.value)
              }
            />
          </FormControl>

          <FormControl marginTop="0.5rem">
            <FormLabel
              htmlFor="veevaVaultPassword"
              color="gray.700"
              fontSize="0.8rem"
            >
              Password
            </FormLabel>
            <Input
              size="sm"
              id="veevaVaultPassword"
              name="veevaVaultPassword"
              type="password"
              defaultValue={enteredPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEnteredPassword(e.target.value)
              }
            />
          </FormControl>
          <Checkbox
            isDisabled={isObtainingAccessToken}
            css={tw`mt-2`}
            id="approveRevision"
            size="sm"
            isChecked={shouldStorePassword}
            onChange={(e) => {
              if (e.target.checked) {
                setShouldStorePassword(true);
              } else {
                setShouldStorePassword(false);
                setStoredPassword('');
              }
            }}
          >
            Remember password <small>(stored in browser localstorage)</small>
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            isDisabled={isObtainingAccessToken}
            size="sm"
            mr={3}
            onClick={onClose}
            color="gray.600"
          >
            Close
          </Button>
          <Button
            type="submit"
            isDisabled={isObtainingAccessToken}
            colorScheme="blue"
            size="sm"
            transition="none"
            _hover={{ boxShadow: '0 1px 3px 1px rgba(0,0,0,0.1)' }}
          >
            {isObtainingAccessToken ? `Submitting` : `Submit`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
