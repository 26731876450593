import { useEffect } from 'react';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { useMonaco } from '@monaco-editor/react';

export const useSetMarkers = ({
  editor,
}: {
  editor: monacoEditor.editor.IStandaloneCodeEditor | null;
}) => {
  const monacoEditorInstance = useMonaco();
  useEffect(() => {
    if (monacoEditorInstance === null) {
      return;
    }
    const model = editor?.getModel();

    if (editor && model) {
      const markers: monacoEditor.editor.IMarkerData[] = [];
      const lines = model.getValue().split('\n');
      lines.forEach((line, lineIndex) => {
        const chars = line.split('');
        chars.forEach((char, charIndex) => {
          if (char === '\u00a0') {
            markers.push({
              message: 'non-breaking space',
              severity: monacoEditorInstance.MarkerSeverity.Info,
              startLineNumber: lineIndex + 1,
              startColumn: charIndex + 1,
              endLineNumber: lineIndex + 1,
              endColumn: charIndex + 2,
            });
          }
          if (char === '\u200C') {
            markers.push({
              message: 'zero-width non-joiner',
              severity: monacoEditorInstance.MarkerSeverity.Info,
              startLineNumber: lineIndex + 1,
              startColumn: charIndex,
              endLineNumber: lineIndex + 1,
              endColumn: charIndex + 3,
            });
          }
          if (char === '\u2011') {
            markers.push({
              message: 'non-breaking hyphen',
              severity: monacoEditorInstance.MarkerSeverity.Info,
              startLineNumber: lineIndex + 1,
              startColumn: charIndex + 1,
              endLineNumber: lineIndex + 1,
              endColumn: charIndex + 2,
            });
          }
        });
      });

      monacoEditorInstance.editor.setModelMarkers(model, 'mailcrunch', markers);
    }
  }, [editor, monacoEditorInstance]);
};
