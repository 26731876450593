import _ from 'lodash';
import { CompileFunction } from './types';
// @ts-ignore typescript complains about not being able to find types
// eslint-disable-next-line import/no-webpack-loader-syntax
import compileWorker from 'workerize-loader!./compile';

export * from './types';

console.log({ compileWorker });

const compileWorkerInstance = new compileWorker();
export const compile: CompileFunction = compileWorkerInstance.compile;
