import {
  useAddAssetsToProjectMutation,
  useDuplicateProjectMutation,
  useRemoveAssetsFromProjectMutation,
  useRenameProjectMutation,
  useUpdateProjectAssetNameTransformTemplateMutation,
  useUpdateProjectSrcMutation,
  useUseProjectQuery,
} from 'src/generated/graphql';
import { useFileUpload } from 'src/useFileUpload';
import { shallowMergeList } from '../shallowMergeList';
import { Project, ProjectFile, ProjectMethodsAndStuff } from './typesAndErrors';

export const useGraphqlProject = ({
  projectSlug,
  orgSlug,
}: {
  projectSlug: string;
  orgSlug: string;
}): [Project, ProjectMethodsAndStuff] => {
  const { data, loading } = useUseProjectQuery({
    variables: {
      projectSlug,
      orgSlug,
    },
  });

  const [
    updateProjectSrc,
    { loading: isSavingProject },
  ] = useUpdateProjectSrcMutation();
  const [renameProject] = useRenameProjectMutation();
  const [addAssetsToProject] = useAddAssetsToProjectMutation();
  const [removeAssetsFromProject] = useRemoveAssetsFromProjectMutation();
  const [duplicateProject] = useDuplicateProjectMutation();
  const [
    updateProjectAssetNameTransformTemplate,
  ] = useUpdateProjectAssetNameTransformTemplateMutation();

  const [uploadFile, uploadStatusMap, removeFile] = useFileUpload(
    orgSlug,
    projectSlug,
  );

  // images that were uploaded this session; these include images
  // that couldn't be uploaded and completed uploads

  const uploadStatuses = Object.entries(uploadStatusMap).map(
    ([relativePath, uploadStatus]) => ({
      relativePath,
      uploadStatus,
      ...(uploadStatus.downloadUrl
        ? { remoteUrl: uploadStatus.downloadUrl }
        : {}),
    }),
  );

  const uploadingAndUploadedImages = shallowMergeList(
    (data?.project?.latestRevision.assets || []) as ProjectFile[],
    uploadStatuses,
    (x) => x.relativePath,
  );

  return [
    {
      id: data?.project?.id,
      name: data?.project?.name ?? '',
      files: data?.project?.latestRevision.assets,
      src: data?.project?.latestRevision.src ?? '',
      assetNameTransformTemplate:
        data?.project?.latestRevision.assetNameTransformTemplate,
      archived: data?.project?.archived,
      createdAt: data?.project?.createdAt,
      createdBy: data?.project?.createdBy.name,
      lastModified: data?.project?.latestRevision.createdAt,
      lastModifiedBy: data?.project?.latestRevision.createdBy.name,
      thumbnail: data?.project?.latestRevision.thumbnail?.remoteUrl,
    },
    {
      loading,
      uploadFile,
      uploadAndSetProjectThumbnail: async (blob: Blob) => 'project.id',
      uploadingAndUploadedImages,
      updateProjectSrc: async (src: string) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for "${projectSlug}" not found`);
        }

        const response = await updateProjectSrc({
          variables: {
            projectId: data?.project?.id,
            src,
          },
        });

        return response.data?.updateProjectSrc?.latestRevision.src;
      },
      isSavingProject,
      addFiles: async (newFiles: Array<ProjectFile>) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for "${projectSlug}" not found`);
        }
        await addAssetsToProject({
          variables: {
            projectId: data?.project?.id,
            files: newFiles,
          },
        });
      },
      removeFile: async (fileToRemove: ProjectFile) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for "${projectSlug}" not found`);
        }

        const assetToRemove = data?.project?.latestRevision.assets.find(
          (asset) => asset.relativePath === fileToRemove.relativePath,
        );
        if (!assetToRemove) {
          console.log(`Could not find file in project assets`);
          return;
        }

        removeFile(fileToRemove.relativePath);
        await removeAssetsFromProject({
          variables: {
            projectId: data?.project?.id,
            assetIds: [assetToRemove.id],
          },
          optimisticResponse: {
            removeAssetsFromProject: {
              __typename: 'Project',
              id: data?.project?.id,
              latestRevision: {
                __typename: 'Revision',
                ...data?.project?.latestRevision,
                id: 'removeAssetsFromProject:revision:tempid',
                assets: data?.project?.latestRevision.assets.filter(
                  (asset) => asset.relativePath !== fileToRemove.relativePath,
                ),
              },
            },
          },
        });
      },
      duplicateProject: async ({
        // firebase orgid is postgres orgSlug
        orgId: organizationSlug,
        newProjectId: newProjectSlug,
        newProjectName,
      }: {
        orgId: string;
        newProjectId: string;
        newProjectName: string;
      }) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for ${projectSlug} not found`);
        }
        const newProject = await duplicateProject({
          variables: {
            newProjectName,
            organizationSlug,
            currentProjectId: data.project.id,
          },
        });

        return newProject.data?.duplicateProject?.slug;
      },
      renameProject: async (newName: string) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for "${projectSlug}" not found`);
        }
        renameProject({
          variables: {
            newName,
            projectId: data?.project?.id,
          },
        });
      },
      setProjectAssetNameTransformTemplate: async (
        assetNameTransformTemplate: string,
      ) => {
        if (!data?.project?.id) {
          throw new Error(`Project ID for "${projectSlug}" not found`);
        }
        await updateProjectAssetNameTransformTemplate({
          variables: {
            assetNameTransformTemplate,
            projectId: data?.project?.id,
          },
        });
      },
      errors: [],
    },
  ];
};
