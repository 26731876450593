import { arrayBufferToBlob } from 'blob-util';

export interface MemoryImage {
  name: string;
  type: string;
  blob: Blob;
}

// export const fileToMemoryImage = (file: File): Promise<MemoryImage> =>
//   new Promise((resolve, reject) => {
//     var fileReader = new FileReader();

//     fileReader.addEventListener('loadend', function fileLoadEndListener(e) {
//       const fileType = file.type;
//       const blob = arrayBufferToBlob((e.target as any).result, fileType);
//       resolve({
//         name: file.name,
//         type: file.type,
//         blob,
//       });
//       fileReader.removeEventListener('loadend', fileLoadEndListener);
//     });
//     fileReader.readAsArrayBuffer(file);
//   });

export const fileToBuffer = (file: File): Promise<Buffer> =>
  new Promise((resolve, reject) => {
    var fileReader = new FileReader();

    fileReader.addEventListener('loadend', function fileLoadEndListener(
      e: ProgressEvent<FileReader>,
    ) {
      const arrayBuffer = new Buffer((e.target as any).result);
      resolve(arrayBuffer);
      fileReader.removeEventListener('loadend', fileLoadEndListener);
    });
    fileReader.readAsArrayBuffer(file);
  });

export const fileToMemoryImage = async (file: File): Promise<MemoryImage> => {
  const blob = arrayBufferToBlob(await fileToBuffer(file));
  return {
    name: file.name,
    type: file.type,
    blob,
  };
};
