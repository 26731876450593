/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useState } from 'react';
import slugify from 'slugify';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { routes } from 'src/routes';
import {
  useCreateOrganizationMutation,
  useIsOrganizationSlugAvailableQuery,
} from 'src/generated/graphql';

export const CreateOrganization = ({
  currentUserId,
  buttonSize,
}: {
  currentUserId: string;
  buttonSize: string;
}) => {
  const minOrgSlugLength = 4;
  const history = useHistory();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationSlug, setOrganizationSlug] = useState('');
  const [canShowMinLengthError, setCanShowMinLengthError] = useState(false);
  const [orgCreationError, setOrgCreationError] = useState<string | null>(null);

  const [createOrganization, { loading }] = useCreateOrganizationMutation({
    variables: {
      name: organizationName,
      slug: organizationSlug,
    },
  });

  const {
    data: isOrgSlugAvailableData,
    loading: isCheckingOrgSlugAvailability,
  } = useIsOrganizationSlugAvailableQuery({
    variables: {
      slug: organizationSlug,
    },
    skip: organizationSlug.length < minOrgSlugLength,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isValid = organizationName.trim().length > 0;

  return (
    <div css={tw`w-full`}>
      <Button
        onClick={onOpen}
        size={buttonSize}
        css={tw`my-4 w-full`}
        _hover={{ bg: 'blue.500', color: 'white' }}
      >
        Create Team
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setOrgCreationError(null);
          setOrganizationName('');
          setOrganizationSlug('');
          setCanShowMinLengthError(false);
          onClose();
        }}
        closeOnOverlayClick
        size="sm"
      >
        <ModalOverlay />
        <ModalContent
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!isValid) {
              setOrgCreationError('ID cannot be empty.');
              return;
            }

            await createOrganization({
              variables: {
                name: organizationName,
                slug: organizationSlug,
              },
              update(cache, createOrganizationResult) {
                if (!createOrganizationResult.data) return;
                if (!createOrganizationResult.data.createOrganization) {
                  return;
                }
                const createdOrganizationMembership =
                  createOrganizationResult.data.createOrganization;
                if (createdOrganizationMembership.__typename !== 'Membership') {
                  // got an error instead
                  return;
                }

                cache.modify({
                  id: cache.identify({
                    __typename: 'User',
                    id: currentUserId,
                  }),
                  fields: {
                    memberships(existingMemberships = []) {
                      return existingMemberships.concat({
                        id: createdOrganizationMembership.id,
                      });
                    },
                  },
                });
              },
            }).then((response) => {
              if (
                response.data?.createOrganization?.__typename ===
                'ErrorNoDuplicates'
              ) {
                setOrgCreationError(response.data?.createOrganization.message);
              } else {
                history.push(
                  routes.projects.getPath({
                    orgSlug: organizationSlug,
                  }),
                );
                onClose();
              }
            });
          }}
        >
          <ModalHeader color="gray.800" mt="2">
            Create Team
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel
                htmlFor="project-name"
                css={tw`uppercase text-sm text-gray-500`}
              >
                Team Name
              </FormLabel>
              <Input
                id="project-name"
                placeholder="Organization Name"
                css={tw`border-l border-t border-b px-2`}
                value={organizationName}
                onChange={(e) => {
                  setOrganizationName(e.currentTarget.value);
                  setOrganizationSlug(
                    slugify(e.currentTarget.value, {
                      lower: true,
                    }),
                  );
                }}
                onBlur={() => setCanShowMinLengthError(true)}
              />
            </FormControl>
            <FormControl css={tw`mt-4`}>
              <FormLabel
                htmlFor="project-name"
                css={tw`uppercase text-sm text-gray-500 flex flex-row`}
              >
                ID
              </FormLabel>
              <Input
                id="project-name"
                placeholder="Organization ID"
                css={tw`border-l border-t border-b px-2`}
                value={organizationSlug}
                onBlur={() => setCanShowMinLengthError(true)}
                onChange={(e) => {
                  setOrganizationSlug(
                    slugify(e.currentTarget.value, { lower: true }),
                  );
                }}
              />
            </FormControl>
            <div css={tw`py-2 text-sm`}>
              {organizationSlug.length < minOrgSlugLength &&
              canShowMinLengthError ? (
                <div css={[tw`text-red-700`]}>
                  Organization ID must be at least {minOrgSlugLength} characters
                </div>
              ) : isCheckingOrgSlugAvailability ? (
                <span>Checking availability...</span>
              ) : isOrgSlugAvailableData?.isOrganizationSlugAvailable ===
                false ? (
                <div css={tw`text-red-700`}>
                  An organization with ID of "{organizationSlug}" already
                  exists.
                </div>
              ) : organizationSlug.length >= minOrgSlugLength ? (
                <div css={tw`text-xs`}>
                  ✔️ "{organizationSlug}" is available
                </div>
              ) : null}

              {orgCreationError && (
                <div css={tw`text-red-700`}>{orgCreationError}</div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              size="sm"
              textTransform="uppercase"
              fontWeight="400"
              mr={5}
              onClick={() => {
                onClose();
              }}
              color="gray.600"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              textTransform="uppercase"
              fontWeight="400"
              colorScheme="blue"
              size="sm"
              transition="none"
              isDisabled={loading || !isValid}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
