/** @jsxImportSource @emotion/react */
import { css } from 'twin.macro';
import { ReactNode } from 'react';

export const ToolBarMenuButton = ({
  onPress,
  ...props
}: {
  children: ReactNode;
  disabled?: boolean;
  onPress?: () => void;
  onClick?: () => void;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => (
  <button
    css={css`
      border: none;
      border-radius: 2px;

      cursor: pointer;
      padding: 4px 8px;
      background-color: transparent;
      ${!props.disabled &&
      css`
        &:hover {
          background-color: #e9eff4;
        }
      `}
    `}
    onClick={onPress ? onPress : props.onClick}
    {...props}
  ></button>
);
