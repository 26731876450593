/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import { useResizeDetector } from 'react-resize-detector';
import _ from 'lodash';
import MonacoEditor, {
  loader as monacoInstanceLoader,
} from '@monaco-editor/react';

import {
  CUSTOM_TEMPLATE_LANGUAGE_ID,
  customLanguage,
  customLanguageConfiguration,
} from './monaco/emailTemplateLanguage';
import * as monacoJs from './monaco/javascript.definition';
import { CUSTOM_THEME_ID, customTheme } from './monaco/customTheme';
import { useSetMarkers } from './useSetMarkers';

monacoInstanceLoader.init().then((monacoInstance) => {
  monacoInstance.editor.defineTheme(CUSTOM_THEME_ID, customTheme);

  monacoInstance.languages.register({
    id: CUSTOM_TEMPLATE_LANGUAGE_ID,
  });

  monacoInstance.languages.setLanguageConfiguration(
    CUSTOM_TEMPLATE_LANGUAGE_ID,
    customLanguageConfiguration,
  );

  monacoInstance.languages.setMonarchTokensProvider(
    CUSTOM_TEMPLATE_LANGUAGE_ID,
    customLanguage,
  );

  monacoInstance.languages.register({
    id: 'custom-javascript',
    aliases: ['JavaScript', 'javascript', 'js'],
    mimetypes: ['text/custom-javascript'],
  });
  monacoInstance.languages.setLanguageConfiguration(
    'custom-javascript',
    monacoJs.conf,
  );
  monacoInstance.languages.setMonarchTokensProvider(
    'custom-javascript',
    monacoJs.language,
  );
});

const Editor: React.FC<{
  className?: string;
  src: string;
  onChange: (src: string) => void;
  options?: monacoEditor.editor.IEditorConstructionOptions;
  editorRef?: React.MutableRefObject<monacoEditor.editor.IStandaloneCodeEditor>;
  editorDidMount?: (
    editorInstance: monacoEditor.editor.IStandaloneCodeEditor,
    monaco: typeof monacoEditor,
  ) => void;
}> = ({ className, src, onChange, options, editorRef, editorDidMount }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onResize = useCallback(() => {
    if (!containerRef.current) return;
    setSize(
      _.pick(containerRef.current.getBoundingClientRect(), ['width', 'height']),
    );
    if (editorRef && editorRef.current) {
      editorRef.current.layout();
    }
  }, [containerRef, editorRef]);

  useResizeDetector({
    onResize,
    targetRef: containerRef,
  });

  const [size, setSize] = useState<{
    width: number | string;
    height: number | string;
  }>({ width: '100%', height: '100%' });

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      setSize(
        _.pick(containerRef.current.getBoundingClientRect(), [
          'width',
          'height',
        ]),
      );
    }
  }, [containerRef]);

  useEffect(handleResize, [handleResize]);
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const [
    editor,
    setEditor,
  ] = useState<monacoEditor.editor.IStandaloneCodeEditor | null>(null);

  useSetMarkers({ editor });

  return (
    <div
      ref={containerRef}
      className={className}
      css={[
        css`
          width: 100%;
          height: 100%;
          overflow: hidden;
        `,
      ]}
    >
      <MonacoEditor
        {...size}
        language={CUSTOM_TEMPLATE_LANGUAGE_ID}
        theme={CUSTOM_THEME_ID}
        value={src}
        options={{
          selectOnLineNumbers: true,
          tabCompletion: 'on',
          scrollBeyondLastLine: false,
          ...options,
        }}
        onChange={(v) => v && onChange(v)}
        onMount={(editorInstance, monaco) => {
          if (editorRef) {
            editorRef.current = editorInstance;
            setEditor(editorInstance);
          }
          monaco.languages.registerCompletionItemProvider('html', {
            provideCompletionItems: (m, position) => {
              return {
                suggestions: [
                  {
                    label: 'translate',
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    documentation: 'Iterate over an Array',
                    insertText: '<%- enFr("", "") %>',
                    ...({} as { range: any }), // range is optional
                  },
                  {
                    label: 'veeva',
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    documentation: 'Iterate over an Array',
                    insertText: '<%- veeva.dropdown(enFr([], [])) %>',
                    ...({} as { range: any }), // range is optional
                  },
                  {
                    label: 'bold-span',
                    kind: monaco.languages.CompletionItemKind.Snippet,
                    documentation: 'Iterate over an Array',
                    insertText: '<span style="font-weight: bold;"></span>',
                    ...({} as { range: any }), // range is optional
                  },
                ],
              };
            },
          });
          editorDidMount?.(editorInstance, monaco);
        }}
      />
    </div>
  );
};
export default Editor;
