/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import tw from 'twin.macro';
import { useState } from 'react';
import { useDebouncedEffect } from 'src/utils/useDebouncedEffect';
import {
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { SegmentedControl } from '@mantine/core';
import { ReactComponent as TimesIcon } from '@mailcrunch/assets/fontawesome/regular/times.svg';
import { IoFilterSharp as FilterIcon } from 'react-icons/io5';

import { ProjectList } from './ProjectList/ProjectList';
import { routes } from 'src/routes';
import { CreateProject } from './CreateProject';
import { TopNav } from '../../navbar/TopNav';
import { PAGE_WRAPPER_STYLES } from 'src/styles/pageStyles';
import {
  ProjectFilterArchivedOrNotArchived,
  ProjectFilterRelationship,
  ProjectsFilter,
} from 'src/generated/graphql';

export const ProjectsPage = ({ orgSlug }: { orgSlug: string }) => {
  const [selectedProject, setSelectedProject] = useState<{
    id: string;
    slug: string;
  } | null>(null);

  const [projectFilter, setProjectFilter] = useState<ProjectsFilter>({
    archivedOrNotArchived: ProjectFilterArchivedOrNotArchived.NotArchived,
    relationship: ProjectFilterRelationship.All,
  });

  const [filterInput, setFilterInput] = useState<string>('');
  const [debouncedFilterByName, setDebouncedFilterByName] = useState<string>(
    '',
  );
  const [cursor, setCursor] = useState<string | null | undefined>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useDebouncedEffect(
    () => {
      setDebouncedFilterByName(filterInput);
    },
    1000,
    [filterInput],
  );

  return (
    <div css={tw`flex flex-col w-full`}>
      <TopNav orgSlug={orgSlug} />

      <div css={[PAGE_WRAPPER_STYLES, tw`flex flex-row w-[1100px]`]}>
        <div
          css={tw`flex flex-col flex-shrink-0 w-[460px] border-r border-gray-100`}
        >
          <div css={tw`flex flex-row items-center mt-6 mb-3 ml-4 z-[2]`}>
            <Menu>
              <MenuButton
                _hover={{ bg: 'blue.500', color: 'white' }}
                css={tw`flex-shrink-0 mr-2 py-1 px-2 rounded`}
              >
                <FilterIcon className="icon" size={17} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  as={() => (
                    <SegmentedControl
                      fullWidth
                      size="xs"
                      color="blue"
                      value={projectFilter.relationship}
                      onChange={(nextValue) => {
                        setProjectFilter({
                          ...projectFilter,
                          relationship: nextValue as ProjectFilterRelationship,
                        });
                      }}
                      data={Object.values(ProjectFilterRelationship).map(
                        (value) => {
                          return {
                            value: value,
                            label:
                              value.charAt(0) +
                              value.slice(1).toLowerCase() +
                              ' projects',
                          };
                        },
                      )}
                    />
                  )}
                ></MenuItem>
                <MenuItem
                  as={() => (
                    <SegmentedControl
                      fullWidth
                      size="xs"
                      color="blue"
                      value={projectFilter.archivedOrNotArchived}
                      onChange={(nextValue) => {
                        setProjectFilter({
                          ...projectFilter,
                          archivedOrNotArchived: nextValue as ProjectFilterArchivedOrNotArchived,
                        });
                      }}
                      data={Object.values(ProjectFilterArchivedOrNotArchived)
                        .map((value) => {
                          return {
                            value: value,
                            label:
                              value.charAt(0) +
                              value.replace('_', ' ').slice(1).toLowerCase(),
                          };
                        })
                        .reverse()}
                    />
                  )}
                ></MenuItem>
              </MenuList>
            </Menu>

            <InputGroup size="sm">
              <Input
                placeholder="Search..."
                autoFocus={true}
                inputMode="search"
                value={filterInput}
                onChange={(e) => {
                  setFilterInput(e.target.value);
                }}
              />
              {filterInput.length && (
                <InputRightAddon
                  onClick={() => {
                    setCursor(null);
                    setFilterInput('');
                    setHasMore(true);
                  }}
                  children={
                    <TimesIcon
                      className="edit-icon"
                      css={css`
                        width: 10px;
                        fill: #686767;
                      `}
                    />
                  }
                />
              )}
            </InputGroup>
            <CreateProject orgSlug={orgSlug} />
          </div>
          <ProjectList
            orgSlug={orgSlug}
            onSelectProject={({ id, slug }: { id: string; slug: string }) =>
              setSelectedProject({ id, slug })
            }
            filterByName={debouncedFilterByName}
            projectFilter={projectFilter}
            cursor={cursor}
            hasMore={hasMore}
            setCursor={setCursor}
            setHasMore={setHasMore}
          />
        </div>

        <div css={tw`flex w-full h-screen bg-gray-100 sticky top-0`}>
          {selectedProject && (
            <iframe
              src={routes.projectViewer.getPath({
                orgSlug,
                projectSlug: selectedProject.slug,
              })}
              css={css`
                min-width: 320px;
                border: none;
                flex: 1;
              `}
              title="email preview"
            />
          )}
        </div>
      </div>
    </div>
  );
};
