import React, { useEffect, useMemo } from 'react';
import morphdom from 'morphdom';
import { useCrossWindowCompileReceiver } from '../utils/crossWindow/useCrossWindowCompileReceiver';
import { CompileOptions, compile } from '../compile';
import _ from 'lodash';

/**
 * This is embedded in the workbench, shown next to the editor as a preview that live updates
 */
export const EmailDisplay: React.FC = () => {
  const { src, files, previewOptions } = useCrossWindowCompileReceiver();

  // TODO: maybe compile should happen in main window and only send compiled html out?
  // would allow memoization (although only for windows sharing the same compile settings)
  const compileCallback = useMemo(() => {
    return _.debounce(async (args: CompileOptions, node: HTMLElement) => {
      try {
        const html = (await compile(args)).html;

        morphdom(node, html, {
          onBeforeNodeDiscarded: function (node) {
            if (
              'id' in node &&
              // preserve litmus chrome extension from being removed
              ['inject-litmus-checklist', 'litmus-pre-inject-css'].includes(
                (node as HTMLElement).id,
              )
            ) {
              return false;
            }
            return true;
          },
          onBeforeElUpdated: function (fromEl, toEl) {
            // spec - https://dom.spec.whatwg.org/#concept-node-equals
            if (fromEl.isEqualNode(toEl)) {
              // skip traversing subtree
              return false;
            }
            return true;
          },
        });
        // @ts-expect-error
        delete document.body.style.opacity;
      } catch (e) {
        console.error(e);
        document.body.style.opacity = '0.5';
        let errorOverlay = document.querySelector('.mailcrunch-error-overlay');
        if (!errorOverlay) {
          document.body.insertAdjacentHTML(
            'beforeend',
            `
            <pre class="mailcrunch-error-overlay" style="
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #ffa5a8ba;
              font-size: 13px;
              color: black;
              padding: 10px;
              margin: 0;
            ">
            </pre>
          `,
          );
          errorOverlay = document.querySelector('.mailcrunch-error-overlay');
        }
        if (errorOverlay) {
          errorOverlay.innerHTML = _.template(
            `<%- e.name %>
          <%- e.message %>
          `,
          )({ e });
        }
      }
    }, 350);
  }, []);

  useEffect(() => {
    if (previewOptions && document.body.parentElement) {
      compileCallback(
        {
          src,
          files,
          remoteImages: true,
          isForRenderedPreview: true,
          ...previewOptions,
        },
        document.body.parentElement,
      );
    }
  }, [previewOptions, files, src, compileCallback]);

  return <></>;
};

export default EmailDisplay;
