/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import tw from 'twin.macro';
import _ from 'lodash';
import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { LoadingScreen } from 'src/LoadingScreen';
import Editor from 'src/Workbench/Editor';
import { Preview } from 'src/Workbench/ToolBar/ToolBar';
import SplitPane from 'react-split-pane';
import { splitpaneStyles } from 'src/Workbench/Workbench.styles';
import { useWindowManager } from 'src/utils/crossWindow/useWindowManager';
import { useCrossWindowCompileSender } from 'src/utils/crossWindow/useCrossWindowCompileSender';
import { useCrossWindowPreviewOptions } from 'src/utils/crossWindow/useCrossWindowPreviewOptions';
import {
  useHistoryPageQuery,
  useOrgUrlQuery,
  useRestoreProjectToRevisionMutation,
  useSetRevisionCommentMutation,
} from 'src/generated/graphql';
import { ReactComponent as MoreOptionsIcon } from '@mailcrunch/assets/fontawesome/solid/ellipsis-v.svg';
import { routes } from '../../../routes';
import logo from 'src/mailcrunch-logo.png';

export const HistoryPage = ({
  projectSlug,
  orgSlug,
}: {
  projectSlug: string;
  orgSlug: string;
}) => {
  const [selectedRevisionId, setSelectedRevisionId] = useState<string | null>(
    null,
  );

  const pageSize = 50;
  const [maybeHasMore, setMaybeHasMore] = useState<boolean>(true);

  const { data, loading, fetchMore } = useHistoryPageQuery({
    variables: {
      slug: projectSlug,
      organizationSlug: orgSlug,
      limit: pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: currentUserData } = useOrgUrlQuery();

  const currentOrgLogoUrl = currentUserData?.currentUser?.memberships?.find(
    (membership) => {
      return membership.organization.slug === orgSlug;
    },
  )?.organization.logoUrl;

  const [setRevisionComment] = useSetRevisionCommentMutation();

  const [restoreProjectToRevision] = useRestoreProjectToRevisionMutation();

  const selectedRevision = data?.project?.revisions.find(
    ({ id }) =>
      id === (selectedRevisionId ?? data?.project?.latestRevision?.id),
  );

  const [, forceRender] = useReducer((state) => (state += 1), 1);

  const editorRef = useRef<monacoEditor.editor.IStandaloneCodeEditor>(
    null,
  ) as React.MutableRefObject<monacoEditor.editor.IStandaloneCodeEditor>;

  const previewIframeRef = useRef<HTMLIFrameElement>(null);

  const [companionPane] = useState(Preview.Preview);

  const [previewOptions] = useCrossWindowPreviewOptions({
    orgId: orgSlug,
    projectId: projectSlug,
  });

  const persistedWordWrapPreference = useMemo(
    () => window.localStorage.getItem('eb:wordWrap'),
    [],
  );
  const [wordWrap] = useState<
    monacoEditor.editor.IEditorConstructionOptions['wordWrap']
  >(
    persistedWordWrapPreference &&
      ['off', 'on', 'wordWrapColumn', 'bounded'].includes(
        persistedWordWrapPreference,
      )
      ? (persistedWordWrapPreference as monacoEditor.editor.IEditorConstructionOptions['wordWrap'])
      : 'off',
  );

  const contentWindow = previewIframeRef.current?.contentWindow;

  useEffect(() => {
    // XXX: need to force rerender for dependencies relying on previewIframeRef.current
    // to rerender with updated value
    if (!contentWindow && previewIframeRef.current) {
      const ref = previewIframeRef.current;
      ref.addEventListener('load', forceRender);
      return () => {
        if (ref) {
          ref.addEventListener('load', forceRender);
        }
      };
    }
  }, [contentWindow]);

  const windowManager = useWindowManager();
  const recipients = useMemo(() => {
    return contentWindow
      ? [
          {
            window: contentWindow,
            syncCompileOptions: true,
          },
          ...windowManager.windows.map((w) => ({
            window: w,
            syncCompileOptions: false,
          })),
        ]
      : [];
  }, [contentWindow, windowManager.windows]);

  useCrossWindowCompileSender({
    src: selectedRevision?.src ?? '',
    files: selectedRevision?.assets ?? [],
    previewOptions,
    recipients,
  });

  const [isDragging, setIsDragging] = useState(false);

  const [windowWidth, setWindowWidth] = useState(() => window.innerWidth);
  const [revisionListPaneSize, setRevisionListPaneSize] = useState(() => {
    return (
      Number(window.localStorage.getItem('mc:splitpane:revisionlist')) || 300
    );
  });

  const [previewPaneRatio, setPreviewPaneRatio] = useState(() => {
    if (Number(window.localStorage.getItem('mc:splitpane:preview')) / 100 > 1) {
      return 0.5;
    } else {
      return (
        Number(window.localStorage.getItem('mc:splitpane:preview')) / 100 || 0.5
      );
    }
  });

  useEffect(() => {
    const listener = _.throttle(() => setWindowWidth(window.innerWidth), 300);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [previewPaneRatio]);

  if (loading && !data?.project?.revisions.length) return <LoadingScreen />;
  const project = data?.project;
  if (!project) {
    throw new Error(`Project ${projectSlug} not found`);
  }

  return (
    <div css={tw`flex flex-col w-full`}>
      <div css={tw`flex flex-row items-center m-4`}>
        <img src={logo} alt="logo" css={tw`w-8`} />
        <span css={tw`mx-4 text-3xl text-gray-200 text-center font-light`}>
          /
        </span>
        <NavLink
          to={routes.projects.getPath({
            orgSlug,
          })}
          css={tw`flex items-center font-semibold`}
        >
          <img
            src={
              currentOrgLogoUrl ??
              `https://source.boringavatars.com/marble/120/${orgSlug}?colors=00C253,F9E575,8FF594,32CECE,91D3EC
`
            }
            alt=""
            css={tw`w-7 h-7 rounded-full overflow-hidden bg-gray-300 mr-2`}
          />
          Projects
        </NavLink>
        <span css={tw`mx-4 text-3xl text-gray-200 text-center font-light`}>
          /
        </span>
        <NavLink
          to={routes.projectEditor.getPath({
            orgSlug,
            projectSlug,
          })}
          css={tw`font-semibold`}
        >
          {projectSlug}
        </NavLink>
        <span css={tw`mx-4 text-3xl text-gray-200 text-center font-light`}>
          /
        </span>
        <span>History</span>
      </div>
      <div css={tw`flex flex-col relative flex-grow`}>
        <SplitPane
          css={splitpaneStyles}
          split="vertical"
          minSize={100}
          maxSize={-100}
          onChange={(newSize) => {
            setRevisionListPaneSize(newSize);
          }}
          onDragFinished={(newSize) => {
            window.localStorage.setItem(
              'mc:splitpane:revisions',
              JSON.stringify(newSize * 100),
            );
            setRevisionListPaneSize(newSize);
          }}
          size={revisionListPaneSize}
        >
          <div css={tw`flex h-full w-full flex-col items-start`}>
            {data?.project?.revisions.map((revision) => {
              const revisionCreatedAtString = new Intl.DateTimeFormat(
                'default',
                {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                },
              ).format(new Date(revision.createdAt));

              return (
                <button
                  key={revision.id}
                  css={[
                    tw`border-gray-300 border-solid border-b bg-white`,
                    css`
                      position: relative;
                      width: 100%;
                      padding: 10px;
                      color: inherit;
                      cursor: pointer;
                      border-width: 0;
                      border-top-width: 0;
                      &:first-child {
                        border-top-width: 1px;
                      }
                      &:not(:last-child) {
                        border-bottom-width: 1px;
                      }
                      &:hover {
                        background-color: #f7fafc;
                      }
                    `,
                  ]}
                  onClick={() => {
                    setSelectedRevisionId(revision.id);
                  }}
                >
                  <div css={tw`flex w-full mt-1`}>
                    <img
                      alt=""
                      src={revision.thumbnail?.remoteUrl}
                      css={css`
                        width: auto;
                        min-width: 30px;
                        height: 60px;
                      `}
                    />
                    <div css={tw`relative flex-grow px-4`}>
                      <div css={tw`absolute right-0 mt-1`}>
                        <Popover closeOnBlur={true} closeOnEsc={true}>
                          <PopoverTrigger>
                            <MoreOptionsIcon
                              width={6}
                              css={css`
                                &:hover {
                                  background-color: #e2bff6;
                                  border-radius: 10px;
                                }
                              `}
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            minW="max-content"
                            display="flex"
                            flexDirection="column"
                            py={1}
                            zIndex={2}
                          >
                            <button
                              css={tw`px-2 text-left`}
                              onClick={(e) => {
                                e.currentTarget.blur();
                                restoreProjectToRevision({
                                  variables: {
                                    projectId: data?.project?.id as string,
                                    revisionToRevertToId: revision.id,
                                  },
                                  optimisticResponse: {
                                    restoreProjectToRevision: {
                                      __typename: 'Project',
                                      id: project.id,
                                      revisions: [
                                        {
                                          ...revision,
                                          __typename: 'Revision',
                                          id: 'temp:revision-restored',
                                          createdAt: new Date().toISOString(),
                                          comment: 'Restoring revision...',
                                        },
                                        ...project.revisions,
                                      ],
                                    },
                                  },
                                });
                              }}
                            >
                              Restore this revision
                            </button>
                          </PopoverContent>
                        </Popover>
                      </div>

                      <h4 css={tw`text-left font-bold`}>
                        <textarea
                          rows={1}
                          css={tw`font-semibold text-base resize-none bg-transparent`}
                          style={{
                            height:
                              Math.ceil((revision.comment?.length ?? 26) / 26) *
                                1.2 +
                              'em',
                            lineHeight: '1.2em',
                            fontSize:
                              (revision.comment?.length ?? 0) > 100
                                ? '0.9em'
                                : '1.1em',
                          }}
                          onBlur={(e) => {
                            if (
                              e.target.value !== revision.comment &&
                              e.target.value !== revisionCreatedAtString
                            ) {
                              setRevisionComment({
                                variables: {
                                  revisionId: revision.id,
                                  comment: e.target.value,
                                },
                                optimisticResponse: {
                                  setRevisionComment: {
                                    ...revision,
                                    __typename: 'Revision',
                                    comment: e.target.value,
                                  },
                                },
                              });
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              e.currentTarget.blur();
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                              e.currentTarget.value =
                                revision.comment ?? revisionCreatedAtString;
                              e.currentTarget.blur();
                            }
                          }}
                        >
                          {revision.comment ?? revisionCreatedAtString}
                        </textarea>
                      </h4>
                      <div css={tw`text-left text-xs italic`}>
                        {revision.comment ? revisionCreatedAtString : null}
                      </div>
                      <div css={tw`text-left text-sm`}>
                        {revision.createdBy.name}
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}

            {maybeHasMore && (
              <Button
                width={'100%'}
                py={6}
                mb={10}
                borderRadius={0}
                colorScheme="blue"
                disabled={
                  !maybeHasMore ||
                  loading ||
                  (data.project?.revisions.length ?? 0) < pageSize
                }
                onClick={() => {
                  fetchMore({
                    variables: {
                      cursor: data.project?.revisions.slice(-1)[0].id,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      if (
                        (fetchMoreResult?.project?.revisions.length ?? 0) <
                        pageSize
                      ) {
                        setMaybeHasMore(false);
                      }
                      return Object.assign({}, prev, {
                        project: {
                          ...prev.project,
                          revisions: [
                            ...(prev.project?.revisions ?? []),
                            ...(fetchMoreResult?.project?.revisions ?? []),
                          ],
                        },
                      });
                    },
                  });
                }}
              >
                {loading
                  ? 'Loading...'
                  : maybeHasMore
                  ? 'Load more'
                  : 'No more revisions'}
              </Button>
            )}
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              height: 100%;
            `}
          >
            {selectedRevision && (
              <SplitPane
                css={splitpaneStyles}
                split="vertical"
                minSize={100}
                maxSize={-100}
                onDragStarted={() => setIsDragging(true)}
                onDragFinished={(newSize) => {
                  setIsDragging(false);
                  const newRatio =
                    newSize / (windowWidth - revisionListPaneSize);
                  window.localStorage.setItem(
                    'eb:paneSize',
                    JSON.stringify(newRatio * 100),
                  );
                  setPreviewPaneRatio(newRatio);
                }}
                size={previewPaneRatio * (windowWidth - revisionListPaneSize)}
              >
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <Editor
                    editorRef={editorRef}
                    css={css`
                      flex: 1;
                      flex-basis: 1px;
                      width: 1px;
                    `}
                    src={selectedRevision.src}
                    onChange={(newSrc) => {
                      // this is readonly, onChange doesn't happen
                    }}
                    options={{
                      wordWrap,
                      readOnly: true,
                      renderValidationDecorations: 'on',
                      minimap: {
                        enabled: false,
                      },
                    }}
                    editorDidMount={(mountedEditor) => {
                      mountedEditor.focus();
                    }}
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    height: 100%;
                  `}
                >
                  <iframe
                    ref={previewIframeRef}
                    src="/display"
                    css={css`
                      width: 1px;
                      min-width: 320px;
                      flex-basis: 1px;
                      border: none;
                      flex: 1;
                      display: ${companionPane === Preview.Preview
                        ? 'block'
                        : 'none'};
                      ${isDragging &&
                      css`
                        pointer-events: none;
                      `}
                    `}
                    title="preview"
                  />
                </div>
              </SplitPane>
            )}
          </div>
        </SplitPane>
      </div>
    </div>
  );
};
