import { useEffect, useReducer } from 'react';

import { ProjectFile } from 'src/utils/useProject/useProject';
import { PreviewOptions } from './useCrossWindowPreviewOptions';

interface State {
  src: string;
  files: ProjectFile[];
  previewOptions: PreviewOptions | null;
}

export const useCrossWindowCompileReceiver = () => {
  const [{ src, files, previewOptions }, setState] = useReducer(
    (state: State, action: Partial<State>) => ({ ...state, ...action }),
    {
      src: '',
      files: [],
      previewOptions: null,
    },
  );

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) return;
      if (e.data.type === 'update') setState(e.data.data);
    };
    window.addEventListener('message', handleMessage);
    window.postMessage({ type: 'init' }, window.location.origin); // let parent know the view is ready to get data
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return { src, files, previewOptions };
};
