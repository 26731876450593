/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import html2canvas from 'html2canvas';
import { Language, compile } from 'src/compile';
import { CircleLoader } from 'src/CircleLoader';
import {
  useSetRevisionThumbnailMutation,
  useUpdateAndViewProjectPreviewQuery,
} from 'src/generated/graphql';
import { blobToFile, useFileUpload } from 'src/useFileUpload';

const scale = 0.2;
const html2canvasOptions = {
  scale,
  height: 200 / scale,
  useCORS: false,
  allowTaint: false,
  scrollY: 20,
};

const imageStyles = css`
  width: auto;
  height: ${(html2canvasOptions.height / 2) * scale}px;
  min-width: 60px;
  box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.2);
`;

const UpdateAndViewProjectPreview: React.FC<{
  orgId: string;
  projectId: string;
}> = ({ projectId, orgId }) => {
  // TODO: this is uploading files using firebase's storage refs
  const [uploadFile] = useFileUpload(orgId, projectId);
  const { data, loading } = useUpdateAndViewProjectPreviewQuery({
    variables: {
      projectId: projectId,
      organizationId: orgId,
    },
  });

  const [setRevisionThumbnail] = useSetRevisionThumbnailMutation();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const latestRevision = data?.organization?.project?.latestRevision;
  const src = latestRevision?.src;
  const files = latestRevision?.assets;
  const latestRevisionId = latestRevision?.id;

  useEffect(() => {
    if (
      !iframeRef.current ||
      !iframeRef.current.contentWindow ||
      !iframeRef.current.contentWindow.document.body.parentElement ||
      !src ||
      !files ||
      !latestRevisionId
    ) {
      return;
    }

    compile({
      src,
      language: Language.English,
      files,
      remoteImages: true,
      previewVeevaTags: false,
      isForRenderedPreview: true,
    }).then((compileResults) => {
      if (iframeRef?.current?.contentWindow?.document.body.parentElement) {
        iframeRef.current.contentWindow.document.body.parentElement.innerHTML = compileResults.html?.replace(
          new RegExp(
            'https://email-builder-c70fb.appspot.com.storage.googleapis.com/',
            'gi',
          ),
          '/uploaded-images/',
        );
      }
    });

    const timeout = setTimeout(async () => {
      if (
        iframeRef.current &&
        iframeRef.current.contentWindow &&
        iframeRef.current.contentWindow.document.body
      ) {
        let canvas;

        canvas = await html2canvas(
          iframeRef.current.contentWindow.document.body,
          html2canvasOptions,
        );

        if (!canvas) {
          console.error('problem creating canvas');
          return;
        }
        setCanvas(canvas);
        canvas.toBlob(async (blob) => {
          if (!blob) {
            throw new Error(`Error converting canvas to blob`);
          }

          const remoteUrl = await uploadFile(
            blobToFile(blob, '__mailcrunch_project_thumbnail__.png'),
            '__mailcrunch_project_thumbnail__.png',
          );

          setRevisionThumbnail({
            variables: {
              revisionId: latestRevisionId,
              thumbnailRemoteUrl: remoteUrl,
            },
          });
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [
    iframeRef,
    files,
    src,
    uploadFile,
    latestRevisionId,
    setRevisionThumbnail,
  ]);

  if (loading) {
    return (
      <div
        css={[
          imageStyles,
          css`
            display: flex;
            justify-content: center;
            align-items: center;
          `,
        ]}
      >
        <CircleLoader
          fgColor="#8ad3ff"
          bgColor="#e0e0e0"
          thickness={4}
          width="30px"
          height="30px"
        />
      </div>
    );
  }

  if (canvas) {
    return <img css={imageStyles} src={canvas.toDataURL()} alt="" />;
  } else if (!latestRevisionId) {
    // This is a project that doesn't have a revision yet
    return <div css={imageStyles} />;
  } else {
    return (
      <Fragment>
        <div
          css={[
            imageStyles,
            css`
              display: flex;
              justify-content: center;
              align-items: center;
            `,
          ]}
        >
          <CircleLoader
            fgColor="#8ad3ff"
            bgColor="#e0e0e0"
            thickness={4}
            width="30px"
            height="30px"
          />
        </div>
        <Portal>
          <iframe
            css={css`
              position: absolute;
              pointer-events: none;
              opacity: 0;
            `}
            ref={iframeRef}
            title={projectId}
            width="600"
            height="800"
          />
        </Portal>
      </Fragment>
    );
  }
};

export const ProjectListItemThumbnail = ({
  orgId,
  projectId,
  thumbnailUrl,
}: {
  orgId: string;
  projectId: string;
  thumbnailUrl?: string | null | undefined;
}) => {
  if (thumbnailUrl) {
    return <img css={imageStyles} src={thumbnailUrl} alt="" />;
  } else {
    return <UpdateAndViewProjectPreview projectId={projectId} orgId={orgId} />;
  }
};
