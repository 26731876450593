import React, { useMemo } from 'react';

import { Language } from 'src/compile';
import { useCrossWindowCompileSender } from 'src/utils/crossWindow/useCrossWindowCompileSender';
import { useProjectContext } from 'src/utils/useProject/useProject';
import { useParams } from 'src/useRouter';

import { EmailDisplay } from './EmailDisplay';

/**
 * This is meant to be directly navitated to to preview an email by itself (without an editor)
 * so that a user can more easily mess with the html via devtools
 */
export const EmailViewer = () => {
  const [project, { uploadingAndUploadedImages }] = useProjectContext();
  const [{ language, previewVeevaTags }] = useParams();

  const previewOptions = useMemo(() => {
    return {
      language: language || Language.English,
      previewVeevaTags: previewVeevaTags ?? false,
      shouldMinify: false,
    };
  }, [language, previewVeevaTags]);

  const recipients = useMemo(
    () => [
      {
        window: window,
        syncCompileOptions: false,
      },
    ],
    [],
  );

  useCrossWindowCompileSender({
    src: project.src,
    files: uploadingAndUploadedImages,
    previewOptions,
    recipients,
  });

  return <EmailDisplay />;
};

export default EmailViewer;
