import _ from 'lodash';

/**
shallowMergeList(
  [{id: 1, a: 'a'}, {id: 3}],
  [{id: 1, b: 'b'}, {id: 2}],
  (a) => a.id,
)

// >
// [
//   { "id": 1, "b": "b", "a": "a" },
//   { "id": 2 },
//   { "id": 3 }
// ]
*/

export function shallowMergeList<T1, T2>(
  list1: T1[],
  list2: T2[],
  mergeOn: (item: T1 | T2) => string | number,
): Array<T1 | T2 | (T1 & T2)> {
  return Object.values(_.groupBy([...list1, ...list2], mergeOn)).map((group) =>
    group
      // have field values in list2 take precedence over values in list1
      .reverse()
      .reduce((acc, item) => ({
        ...item,
        ...acc,
      })),
  );
}
