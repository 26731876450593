/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import React, { useState } from 'react';
import { ReactComponent as TrashIcon } from '@mailcrunch/assets/fontawesome/light/trash.svg';
import { ProjectFile } from 'src/utils/useProject/useProject';

export const ImageListEntry: React.FC<{
  imageUrl: string;
  relativePath: string;
  onClick: (path: string) => void;
  onRequestDeleteFile: (file: ProjectFile) => void;
}> = ({ imageUrl, relativePath, onClick, onRequestDeleteFile }) => {
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        cursor: pointer;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .visible-on-parent-hover {
          opacity: 0;
        }
        &:hover .visible-on-parent-hover {
          opacity: 1;
        }
      `}
      onClick={() => {
        onClick(relativePath);
      }}
    >
      {
        // XXX: this is likely incredibly incredibly expensive and wasteful
        // drawing and transitioning full-screen rectangles for EACH image
        // looks nice tho
        imageDimensions && (
          <div
            className="thumbnail-preview"
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;

              position: absolute;
              z-index: 1000;
              pointer-events: none;

              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;

              background-color: dimgrey;
              background-image: linear-gradient(
                  45deg,
                  #808080 25%,
                  transparent 25%
                ),
                linear-gradient(-45deg, #808080 25%, transparent 25%),
                linear-gradient(45deg, transparent 75%, #808080 75%),
                linear-gradient(-45deg, transparent 75%, #808080 75%);
              background-size: 20px 20px;
              background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

              opacity: 0;
              transition: 0.15s opacity;
            `}
          >
            <img
              className="thumbnail-preview-image"
              css={css`
                width: ${imageDimensions.width / 2}px;
                height: ${imageDimensions.height / 2}px;
                max-width: 100%;
                max-height: 100%;
              `}
              src={imageUrl}
              alt=""
            />
          </div>
        )
      }
      <img
        onLoad={(e) => {
          if (!e.currentTarget) {
            throw new Error(`Image load failed`);
          }
          setImageDimensions({
            width: e.currentTarget.naturalWidth,
            height: e.currentTarget.naturalHeight,
          });
        }}
        css={css`
          height: auto;
          min-height: 80px;
          max-height: 100px;
          width: calc(100% - 20px);
          align-self: center;
          object-fit: contain;
        `}
        src={imageUrl}
        alt=""
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <span
          css={css`
            font-size: 11px;
            word-break: break-all;
          `}
        >
          {relativePath.replace(/^\.\/images\//, '')}
        </span>
        <div
          css={css`
            display: flex;
            position: relative;
          `}
        >
          {imageDimensions && (
            <span
              css={css`
                font-size: 10px;
                opacity: 0.7;
              `}
            >
              {imageDimensions.width} x {imageDimensions.height}
            </span>
          )}

          <div
            className="visible-on-parent-hover"
            css={css`
              width: 24px;
              height: 24px;
              fill: #ffffff;
              background-color: #ffffff00;
              &:hover {
                fill: #000000;
                background-color: #ffffff70;
              }
              border-radius: 50%;
              z-index: 1;
              padding: 4px;
              position: absolute;
              right: 0;
              bottom: 2px;
            `}
            onClick={(e) => {
              e.stopPropagation();
              onRequestDeleteFile({
                relativePath,
                remoteUrl: imageUrl,
              });
            }}
          >
            <TrashIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
