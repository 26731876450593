/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';

import { routes } from 'src/routes';
import { useOrganizationLogoQuery } from './generated/graphql';
import { useAcceptInvitationMutation } from './generated/graphql';

export const AcceptInvitation = ({ token }: { token: string }) => {
  const [redirectPath, setRedirectPath] = useState<string>();

  const [orgSlug, invitationCode] = token.split(':');

  const { data } = useOrganizationLogoQuery({
    variables: {
      organizationSlug: orgSlug,
    },
  });

  const [
    acceptInvitation,
    { loading: loadingAcceptInvitation },
  ] = useAcceptInvitationMutation({
    variables: {
      organizationSlug: orgSlug,
      code: invitationCode,
    },
  });

  if (!orgSlug || !invitationCode) {
    return <div>Invalid invite link</div>;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} push />;
  }

  return (
    <div
      css={tw`flex flex-col justify-center items-center text-center w-full mx-16`}
    >
      {data?.organization?.logoUrl && (
        <img
          src={data?.organization?.logoUrl}
          alt=""
          css={tw`w-[100px] h-[100px] rounded-full`}
        />
      )}
      <h1 css={tw`text-7xl mt-6 mb-12 max-w-[13em]`}>
        You've been invited to join the{' '}
        <span css={tw`text-blue-600`}>{orgSlug}</span> organization.
      </h1>
      <Button
        size="lg"
        css={tw`text-2xl px-8 py-4 h-auto`}
        rounded="none"
        colorScheme="blue"
        onClick={async (e) => {
          e.preventDefault();

          acceptInvitation({
            variables: {
              organizationSlug: orgSlug,
              code: invitationCode,
            },
          })
            .then((response) => {
              if (!response.errors) {
                setRedirectPath(routes.projects.getPath({ orgSlug: orgSlug }));
              }
            })
            .catch((error) => {
              if (
                error.message.includes(
                  'Cannot accept invitation to an org you are already a member of',
                )
              ) {
                setRedirectPath(routes.projects.getPath({ orgSlug: orgSlug }));
              }
            });
        }}
      >
        {loadingAcceptInvitation
          ? 'Accepting invitation...'
          : 'Accept invitation'}
      </Button>
    </div>
  );
};
