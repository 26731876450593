
				var addMethods = require("../../../../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["processUsingAST","compile"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "0ee9dc24f6dd9c284e07.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "0ee9dc24f6dd9c284e07.worker.js");
					addMethods(w, methods)
					
					return w
				}
			