import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CSSReset, ChakraProvider, extendTheme, theme } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Login } from './Login';

import './App.css';
import { AuthContextProvider, useAuthContext } from './auth';
import { IsOnlineContextProvider } from './utils/useIsOnline';
import { LoadingScreen } from './LoadingScreen';
import RouterAnalyticsReporter from './RouterAnalyticsReporter';
import { routes } from './routes';
import { RoutesIndex } from './RoutesIndex';
import { ApolloClientProvider } from './components/ApolloClientProvider';
import { AcceptInvitation } from './AcceptInvitation';
import { LandingPage } from '../../editor/src/LandingPage/LandingPage';

const queryClient = new QueryClient();

const EmailDisplay = React.lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "EmailDisplay" */ './EmailViewer/EmailDisplay'
  ),
);

const App: React.FC = () => {
  const { user, initializing, logout } = useAuthContext();
  // @ts-ignore
  window.logout = logout;

  return (
    <div className="App" style={{ display: 'flex' }}>
      <RouterAnalyticsReporter />

      <Switch>
        <Route
          exact={true}
          path={routes.root.definition}
          render={({ location }) => {
            if (initializing) {
              return <LoadingScreen />;
            }
            if (!user) {
              return <LandingPage />;
            }

            return <RoutesIndex />;
          }}
        />
        <Route path={routes.login.definition} component={Login} />
        <Route
          exact={true}
          path={routes.acceptInvitation.definition}
          render={({ location, match }) => {
            if (initializing) {
              return <LoadingScreen />;
            }
            if (!user) {
              return (
                <Redirect
                  to={routes.login.getPath({
                    postLoginRedirect: location.pathname + location.search,
                  })}
                />
              );
            }

            return <AcceptInvitation token={match.params.invitationToken} />;
          }}
        />

        <Route
          render={({ location }) => {
            if (initializing) {
              return <LoadingScreen />;
            }
            if (!user) {
              return (
                <Redirect
                  to={routes.login.getPath({
                    postLoginRedirect: location.pathname + location.search,
                  })}
                />
              );
            }

            return (
              <Switch>
                <Route path="/display" component={EmailDisplay} />
                <RoutesIndex />
              </Switch>
            );
          }}
        />
      </Switch>
    </div>
  );
};

const Providers = () => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={customTheme}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<LoadingScreen />}>
            <AuthContextProvider>
              <ApolloClientProvider>
                <IsOnlineContextProvider>
                  <CSSReset />
                  <App />
                </IsOnlineContextProvider>
              </ApolloClientProvider>
            </AuthContextProvider>
          </Suspense>
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default Providers;

const customTheme = extendTheme({
  ...theme,
  colors: {
    ...theme.colors,
    blue: {
      ...theme.colors.blue,
      '500': '#3d87f5',
    },
  },
});
