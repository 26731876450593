import mjml2html from 'mjml';
import { UploadingAndUploadedFile } from '../utils/useProject/useProject';

// TODO: to be replaced by "variant", which should be user-configurable / dynamic
export enum Language {
  English = 'English',
  French = 'French',
}

export interface CompileOptions {
  src: string;
  language: Language;
  files: UploadingAndUploadedFile[];
  isForRenderedPreview: boolean;
  previewVeevaTags?: boolean;
  remoteImages?: boolean;
  shouldMinify?: boolean;
  shouldAddChecksum?: boolean;
}

export type CompileReturnType = Promise<
  ReturnType<typeof mjml2html> & {
    html: string;
    matchedImages: UploadingAndUploadedFile[];
  }
>;

export type CompileFunction = (options: CompileOptions) => CompileReturnType;
