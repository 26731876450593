import React from 'react';
import { LoadingScreen } from 'src/LoadingScreen';
import { Project, ProjectMethodsAndStuff } from './typesAndErrors';
import { useFirebaseProject } from './useFirebaseProject';
import { useGraphqlProject } from './useGraphqlProject';
export * from './typesAndErrors';

export default useFirebaseProject;

const ProjectContext = React.createContext<
  [Project, ProjectMethodsAndStuff] | null
>(null);

export const FirebaseProjectContextProvider = ({
  orgSlug,
  projectSlug: projectId,
  children,
}: {
  orgSlug: string;
  projectSlug: string;
  children: React.ReactNode;
}) => {
  const firebaseProjectStuff = useFirebaseProject({
    orgId: orgSlug,
    projectId,
  });
  return (
    <ProjectContext.Provider value={firebaseProjectStuff}>
      {children}
    </ProjectContext.Provider>
  );
};

export const GraphQLProjectContextProvider = ({
  orgSlug,
  projectSlug: projectId,
  children,
}: {
  orgSlug: string;
  projectSlug: string;
  children: React.ReactNode;
}) => {
  const graphqlProjectStuff = useGraphqlProject({
    orgSlug: orgSlug,
    projectSlug: projectId,
  });
  const isLoading = graphqlProjectStuff[1].loading;
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <ProjectContext.Provider value={graphqlProjectStuff}>
      {children}
    </ProjectContext.Provider>
  );
};
export const useProjectContext = () => {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error(`useProjectContext must be used within a ProjectContext`);
  }
  return context;
};
