import { useMemo, useState } from 'react';
import { Language } from 'src/compile';
import { useLocalStorage } from '../useLocalStorage';

export interface PreviewOptions {
  language: Language;
  shouldMinify: boolean;
  previewVeevaTags: boolean;
}

export interface PreviewOptionsMutations {
  setLanguage: (language: Language) => void;
  setShouldMinify: (shouldMinify: boolean) => void;
  setPreviewVeevaTags: (shouldPreviewVeevaTag: boolean) => void;
}

export const useCrossWindowPreviewOptions = ({
  projectId,
  orgId,
}: {
  projectId: string;
  orgId: string;
}): [PreviewOptions, PreviewOptionsMutations] => {
  const [language, setLanguage] = useState(Language.English);
  const [shouldMinify, setShouldMinify] = useLocalStorage(
    `${orgId}:${projectId}:minify`,
    true,
  );
  const [previewVeevaTags, setPreviewVeevaTags] = useState(false);
  const compileOptions = useMemo(
    () => ({
      language,
      shouldMinify,
      previewVeevaTags,
    }),
    [language, shouldMinify, previewVeevaTags],
  );

  const compileOptionsMutations = useMemo(
    () => ({
      setLanguage,
      setShouldMinify,
      setPreviewVeevaTags,
    }),
    [setLanguage, setShouldMinify, setPreviewVeevaTags],
  );

  return [compileOptions, compileOptionsMutations];
};
