/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import BarLoader from 'react-spinners/BarLoader';

export const LoadingScreen = (props: any) => (
  <div
    css={css`
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
    {...props}
  >
    <BarLoader color="#8ad3ff" />
  </div>
);
