/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { Tooltip } from '@chakra-ui/react';

import React from 'react';
import { IoMdImages as ImagesIcon } from 'react-icons/io';
import { IoSettingsOutline as SettingsIcon } from 'react-icons/io5';

export type SideContent = 'images' | 'projectSettings' | null;

export const SideNavbar = ({
  sideContent,
  setSideContent,
}: {
  sideContent: SideContent;
  setSideContent: (sideContent: SideContent) => void;
}) => {
  return (
    <>
      <Tooltip
        hasArrow
        label="Image List"
        aria-label="Image List"
        placement="right"
        bg="gray.400"
        color="gray.900"
      >
        <button
          onClick={() => {
            if (sideContent === 'images') setSideContent(null);
            else setSideContent('images');
          }}
          css={[
            tw`text-gray-400 hover:text-gray-100 fill-current px-2 py-2 rounded-lg`,
            sideContent === 'images' && tw`text-gray-100 bg-gray-700`,
          ]}
          type="button"
        >
          <ImagesIcon size={20} />
        </button>
      </Tooltip>
      <Tooltip
        hasArrow
        label="Project Settings"
        aria-label="Project Settings"
        placement="right"
        bg="gray.400"
        color="gray.900"
      >
        <button
          onClick={() => {
            if (sideContent === 'projectSettings') setSideContent(null);
            else setSideContent('projectSettings');
          }}
          css={[
            tw`text-gray-400 hover:text-gray-100 fill-current px-2 py-2 rounded-lg`,
            sideContent === 'projectSettings' && tw`text-gray-100 bg-gray-700`,
          ]}
          type="button"
        >
          <SettingsIcon size={20} />
        </button>
      </Tooltip>
    </>
  );
};
