import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuthContext } from '../auth';

export const ApolloClientProvider = ({ children }: PropsWithChildren<{}>) => {
  const { backendAccessToken: accessToken } = useAuthContext();
  const accessTokenRef = useRef<string | undefined>();
  useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  const apolloClient = useMemo(() => {
    const httpLink = createHttpLink({
      uri: (operation) => {
        return `/api/graphql?operationName=${encodeURIComponent(
          operation.operationName,
        )}`;
      },
    });

    const authLink = setContext((__, { headers }) => {
      const token = accessTokenRef.current;
      return {
        headers: _.omitBy(
          {
            ...headers,
            'x-firebase-token': token ? token : undefined,
          },
          _.isNil,
        ),
      };
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          Project: {
            fields: {
              latestRevision: {
                merge: false,
              },
            },
          },
          Revision: {
            fields: {
              assets: {
                merge: false,
              },
            },
          },
        },
      }),
    });
  }, [accessTokenRef]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
