import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

function sendPageView(location: RouteComponentProps['history']['location']) {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}

const RouterAnalyticsReporter = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    sendPageView(history.location);
    return history.listen(sendPageView);
  }, [history]);

  return null;
};

export default withRouter(RouterAnalyticsReporter);
