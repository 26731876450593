/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import React, { ChangeEvent, useState } from 'react';
import { sendMail } from 'src/utils/sendMail/sendMail';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import Downshift from 'downshift';

import { useLocalStorage } from 'src/utils/useLocalStorage';
import { MenuItem } from './NestableMenu';

export const SendEmailButton = ({
  getHTML,
  defaultSubject,
}: {
  getHTML: () => Promise<string>;
  defaultSubject: string;
}) => {
  const [isSending, setIsSending] = useState(false);
  const [toAddress, setToAddress] = useLocalStorage('sendMailTo', '');
  const [subject, setSubject] = useState(defaultSubject);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [potentialRecipients, setPotentialRecipients] = useLocalStorage<
    Array<string>
  >('eb:potentialRecipients', toAddress.length ? [toAddress] : []);

  return (
    <>
      <MenuItem
        onClick={onOpen}
        className="menu-item"
        label="Send Test Email"
      ></MenuItem>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick size="sm">
        <ModalOverlay />
        <ModalContent
          borderRadius="4px"
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            setIsSending(true);

            if (!potentialRecipients.includes(toAddress)) {
              setPotentialRecipients([...potentialRecipients, toAddress]);
            }

            await sendMail({
              toAddress,
              subject,
              html: await getHTML(),
            });

            setIsSending(false);
            onClose();
          }}
        >
          <ModalHeader color="gray.800" fontSize="1rem" fontWeight="700">
            Send Email
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel
                htmlFor="emailSubject"
                color="gray.700"
                fontSize="0.8rem"
              >
                Email Subject
              </FormLabel>
              <Input
                id="emailSubject"
                size="sm"
                defaultValue={subject}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSubject(e.target.value)
                }
              />
            </FormControl>

            <Downshift
              initialInputValue={toAddress}
              onInputValueChange={(inputValue) => {
                if (inputValue === '' || inputValue === undefined) return;
                setToAddress(inputValue);
              }}
              onChange={({ selectedItem }) => {
                if (selectedItem === undefined) return;
                setToAddress(selectedItem ?? '');
              }}
            >
              {({
                isOpen: isOpenEmailList,
                getLabelProps,
                getMenuProps,
                getInputProps,
                getRootProps,
                highlightedIndex,
                getItemProps,
                inputValue,
              }) => (
                <FormControl marginTop="0.5rem">
                  <FormLabel
                    htmlFor="toAddress"
                    color="gray.700"
                    fontSize="0.8rem"
                    {...getLabelProps()}
                  >
                    To Address
                  </FormLabel>
                  <div {...getRootProps()}>
                    <Input
                      size="sm"
                      id="toAddress"
                      type="email"
                      defaultValue={toAddress}
                      {...getInputProps()}
                    />
                  </div>
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    {isOpenEmailList && (
                      <ul
                        {...getMenuProps({
                          style: {
                            position: 'absolute',
                            maxHeight: 160,
                            width: '100%',
                            backgroundColor: 'white',
                            zIndex: 1,
                            overflowY: 'scroll',
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                          },
                        })}
                      >
                        {potentialRecipients.map((email, index) => {
                          if (
                            inputValue?.length &&
                            !email
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          ) {
                            return null;
                          }
                          return (
                            <li
                              key={`${email}${index}`}
                              {...getItemProps({ item: email, index })}
                              style={
                                highlightedIndex === index
                                  ? {
                                      backgroundColor: '#c7d4f8',
                                      padding: '0.2rem 0.5rem',
                                    }
                                  : { padding: '0.2rem 0.5rem' }
                              }
                            >
                              {email}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </FormControl>
              )}
            </Downshift>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              isDisabled={isSending}
              size="sm"
              mr={3}
              onClick={onClose}
              color="gray.600"
            >
              Close
            </Button>
            <Button
              type="submit"
              isDisabled={isSending}
              colorScheme="blue"
              size="sm"
              transition="none"
              _hover={{ boxShadow: '0 1px 3px 1px rgba(0,0,0,0.1)' }}
            >
              {isSending ? `Sending Email` : `Send Email`}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
