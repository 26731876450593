import type * as monacoEditor from 'monaco-editor';

export const CUSTOM_THEME_ID = 'mailcrunch-dark';
export const customTheme: monacoEditor.editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: true,
  colors: {},
  rules: [
    { token: 'ejs.delimiter', foreground: '8b7ae8', fontStyle: 'bold' },
    { token: 'delimiter', foreground: '808080' },
  ],
};
