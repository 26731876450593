/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
} from '@chakra-ui/react';

import {
  useDeleteOrganizationMutation,
  useOrgSettingPageQuery,
  useSetOrgLogoUrlMutation,
  useSetOrgNameMutation,
} from 'src/generated/graphql';
import { LoadingScreen } from 'src/LoadingScreen';
import { routes } from 'src/routes';

export const OrgGeneralSettings = ({ orgSlug }: { orgSlug: string }) => {
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgLogoUrl, setNewOrgLogoUrl] = useState('');

  const [isDeleteOrgOpen, setIsDeleteOrgOpen] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string>();
  const cancelDeleteOrg = useRef<any>();

  const [renameOrganization] = useSetOrgNameMutation({
    variables: {
      newName: newOrgName,
      organizationSlug: orgSlug,
    },
  });

  const [setOrgLogoUrl] = useSetOrgLogoUrlMutation({
    variables: {
      newLogoUrl: newOrgLogoUrl,
      organizationSlug: orgSlug,
    },
  });

  const [deleteOrganization] = useDeleteOrganizationMutation({
    variables: {
      organizationSlug: orgSlug,
    },
  });

  const { data, loading } = useOrgSettingPageQuery({
    variables: {
      organizationSlug: orgSlug,
    },
  });

  const memberships = data?.currentUser?.memberships;

  if (loading) {
    return <LoadingScreen />;
  }

  if (!memberships) {
    return <div>No data</div>;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} push />;
  }

  const organizationId = data?.organization?.id;

  if (!organizationId) {
    return <div>No data</div>;
  }

  const isCurrentUserAdmin =
    data.currentUser?.memberships.find(
      ({ organizationId }) => organizationId === data?.organization?.id,
    )?.role === 'ADMIN';

  return (
    <div>
      {memberships
        .filter((membership) => membership.organization.slug === orgSlug)
        .map((membership) => {
          if (!membership) return null;

          return (
            <div key={membership.id} css={tw`flex flex-col`}>
              <div
                css={tw`flex flex-col border rounded-md mb-12 flex-shrink-0`}
              >
                <h2 css={tw`font-bold ml-4 mt-6`}>Team Name</h2>
                <div css={tw`my-6 mx-4`}>
                  <Input
                    id="newOrgName"
                    size="sm"
                    disabled={!isCurrentUserAdmin}
                    defaultValue={membership.organization.name?.toString()}
                    onChange={(e) => {
                      setNewOrgName(e.target.value);
                    }}
                  />
                </div>

                <Button
                  css={tw`ml-auto mb-4 mr-4`}
                  type="submit"
                  colorScheme="blue"
                  size="sm"
                  transition="none"
                  disabled={
                    newOrgName === '' ||
                    newOrgName === membership.organization.name ||
                    !isCurrentUserAdmin
                  }
                  onClick={() => {
                    renameOrganization({
                      variables: {
                        newName: newOrgName,
                        organizationSlug: orgSlug,
                      },
                    });
                  }}
                >
                  Save
                </Button>
              </div>

              <div
                css={tw`flex flex-col border rounded-md mb-12 flex-shrink-0`}
              >
                <h2 css={tw`font-bold ml-4 mt-6`}>Team Avatar</h2>
                <div css={tw`my-6 mx-4`}>
                  <Input
                    id="newOrgLogoUrl"
                    size="sm"
                    disabled={!isCurrentUserAdmin}
                    defaultValue={membership.organization.logoUrl?.toString()}
                    onChange={(e) => setNewOrgLogoUrl(e.target.value)}
                  />
                </div>

                <Button
                  css={tw`ml-auto mb-4 mr-4`}
                  type="submit"
                  colorScheme="blue"
                  size="sm"
                  transition="none"
                  disabled={
                    newOrgLogoUrl === '' ||
                    newOrgLogoUrl === membership.organization.logoUrl ||
                    !isCurrentUserAdmin
                  }
                  onClick={() => {
                    setOrgLogoUrl({
                      variables: {
                        newLogoUrl: newOrgLogoUrl,
                        organizationSlug: orgSlug,
                      },
                    });
                  }}
                >
                  Save
                </Button>
              </div>

              <div
                css={tw`flex flex-col border border-red-400 rounded-md mb-12 flex-shrink-0`}
              >
                <h2 css={tw`font-bold ml-4 mt-6`}>Delete Team</h2>
                <span css={tw`flex items-center my-4 mx-4`}>
                  <img
                    src={
                      membership?.organization?.logoUrl ??
                      `https://source.boringavatars.com/marble/120/${membership.organization.slug}?colors=00C253,F9E575,8FF594,32CECE,91D3EC
`
                    }
                    alt=""
                    css={tw`w-12 h-12 rounded-full overflow-hidden bg-gray-300 mr-4`}
                  />
                  <span css={tw`font-medium`}>
                    {membership.organization.slug}
                  </span>
                </span>

                <Button
                  css={tw`ml-auto mb-4 mr-4`}
                  type="submit"
                  colorScheme="red"
                  size="sm"
                  transition="none"
                  disabled={!isCurrentUserAdmin}
                  onClick={() => {
                    setIsDeleteOrgOpen(true);
                  }}
                >
                  Delete
                </Button>
                <AlertDialog
                  isOpen={isDeleteOrgOpen}
                  leastDestructiveRef={cancelDeleteOrg}
                  onClose={() => {
                    setIsDeleteOrgOpen(false);
                  }}
                >
                  <AlertDialogOverlay />
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete Team
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure you want to delete this team?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button
                        ref={cancelDeleteOrg}
                        size={'sm'}
                        onClick={() => {
                          setIsDeleteOrgOpen(false);
                        }}
                        mr={3}
                      >
                        Cancel
                      </Button>
                      <Button
                        colorScheme="red"
                        size={'sm'}
                        onClick={async (e) => {
                          e.preventDefault();

                          await deleteOrganization({
                            variables: {
                              organizationSlug: membership.organization.slug,
                            },
                          });

                          setIsDeleteOrgOpen(false);

                          if (!data?.currentUser?.memberships) return null;

                          const anotherOrgSlug =
                            data?.currentUser?.memberships?.[0]?.organization
                              ?.slug === orgSlug
                              ? data?.currentUser?.memberships?.[1]
                                  ?.organization?.slug
                              : data?.currentUser?.memberships?.[0]
                                  ?.organization?.slug;

                          setRedirectPath(
                            routes.projects.getPath({
                              orgSlug: anotherOrgSlug,
                            }),
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
          );
        })}
    </div>
  );
};
