/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useState } from 'react';
import { Button, FormControl, Input } from '@chakra-ui/react';

import { useRenameProjectMutation } from 'src/generated/graphql';

export const ProjectSettings = ({
  projectId,
  projectName,
}: {
  projectId: string;
  projectName: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [newProjectName, setNewProjectName] = useState<string>('');

  const [renameProject] = useRenameProjectMutation();

  return (
    <div css={tw`text-white my-2`}>
      <h1>Project Settings</h1>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);

          await renameProject({
            variables: {
              newName: newProjectName,
              projectId: projectId,
            },
          });

          setIsSubmitting(false);
          setNewProjectName(newProjectName);
        }}
      >
        <FormControl>
          <Input
            bg="gray.700"
            _hover={{ bg: 'gray.800' }}
            variant="filled"
            id="newProjectName"
            css={tw`mt-2`}
            size="sm"
            value={newProjectName.length !== 0 ? newProjectName : projectName}
            onChange={(e) => {
              setNewProjectName(e.target.value);
            }}
          />
        </FormControl>
        <Button
          color="gray.700"
          size="sm"
          transition="none"
          isDisabled={isSubmitting}
          css={tw`m-4`}
          _hover={{ bg: 'gray' }}
          onClick={() => {
            setIsSubmitting(false);
            setNewProjectName(projectName);
          }}
        >
          Reset
        </Button>
        <Button
          type="submit"
          isDisabled={isSubmitting || projectName === newProjectName}
          colorScheme="blue"
          size="sm"
          transition="none"
          css={tw`m-4`}
        >
          Save
        </Button>
      </form>
    </div>
  );
};
