/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';
import React from 'react';
import { useVeevaSessionToken } from './useVeevaSesstionToken';
import { useCreateVeevaDocumentRevision } from './useCreateVeevaDocumentRevision';
import { UploadingAndUploadedFile } from 'src/utils/useProject/useProject';

import { Menu, MenuItem } from '../NestableMenu';
import { Badge } from '@chakra-ui/react';

export const VeevaMenu = ({
  projectId,
  orgId,
  getProjectResources,
}: {
  projectId: string;
  orgId: string;
  getProjectResources: () => Promise<{
    html: string;
    matchedImages: UploadingAndUploadedFile[];
  }>;
}) => {
  const temporarilyHardCodedApiHost = `https://vv-agency-mavenmm.veevavault.com`;
  const {
    veevaLoginModal,
    obtainToken,
    veevaSessionToken,
  } = useVeevaSessionToken({
    apiHost: temporarilyHardCodedApiHost,
  });
  const {
    showDocumentUploadUI,
    documentUploadModal,
  } = useCreateVeevaDocumentRevision({
    orgId,
    projectId,
    veevaSessionToken,
    apiHost: temporarilyHardCodedApiHost,
    getProjectResources,
  });

  return (
    <React.Fragment>
      {veevaLoginModal}
      {documentUploadModal}
      <Menu
        label={
          <>
            Veeva Vault <Badge variant="subtle">beta</Badge>
          </>
        }
        css={[tw`flex justify-center items-center self-center`]}
      >
        <MenuItem
          label="Obtain New Session Token"
          onClick={() => {
            obtainToken();
          }}
        >
          Obtain New Session Token
          {veevaSessionToken && (
            <React.Fragment>
              <span
                css={[
                  tw`truncate ml-1`,
                  css`
                    max-width: 5em;
                  `,
                ]}
              >
                ({veevaSessionToken}
              </span>
              )
            </React.Fragment>
          )}
        </MenuItem>
        <MenuItem
          label="Export New Revision"
          disabled={!veevaSessionToken}
          onClick={() => {
            if (!veevaSessionToken) {
              throw new Error(
                'A Veeva Vault session token is required but missing',
              );
            }
            showDocumentUploadUI();
          }}
        >
          Export New Revision
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
