import crc32 from 'buffer-crc32';

export const getChecksum = (input: string | Buffer) =>
  crc32(input)
    .toString('base64')
    // remove padding `=`
    .replace(/=*$/, '')
    // make safe for url
    .replace(/\//, '_')
    .replace(/\+/, '-');
