import queryString from 'querystring';
import { useContext, useMemo } from 'react';
import { __RouterContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Language } from './compile';

export function useRouter<Params>() {
  return useContext(__RouterContext) as RouteComponentProps<Params>;
}

function getLanguage(language: string) {
  switch (language) {
    case 'english':
      return Language.English;
    case 'french':
      return Language.French;
    default:
      console.warn(
        `Unsupported language "${language}". Defaulting to "english"`,
      );
      return Language.English;
  }
}

export function useParams() {
  const route = useRouter();
  const parsedParams = useMemo(() => {
    const params = queryString.parse(route.location.search.slice(1));
    return {
      language: getLanguage(([] as string[]).concat(params.language || '')[0]),
      previewVeevaTags:
        ([] as string[]).concat(params.previewVeevaTags || '')[0] === 'true',
      archived: ([] as string[]).concat(params.archived || '')[0] === 'true',
    };
  }, [route.location.search]);

  return [parsedParams];
}
