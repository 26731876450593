/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { TopNav } from 'src/navbar/TopNav';
import { OrgSettingsSideNavbar } from './OrgSettingsSideNavbar';
import { Route, Switch } from 'react-router';
import { routes } from 'src/routes';
import { OrgMembers } from './OrgMembers';
import { OrgGeneralSettings } from './OrgGeneralSettings';
import { PAGE_WRAPPER_STYLES } from 'src/styles/pageStyles';

export const OrgSettings = ({ orgSlug }: { orgSlug: string }) => {
  return (
    <div css={tw`flex flex-col w-full`}>
      <TopNav orgSlug={orgSlug} />

      <h1
        css={[
          tw`text-[32px] font-medium leading-6 px-6 mt-12 mb-0`,
          PAGE_WRAPPER_STYLES,
        ]}
      >
        Team Settings
      </h1>

      <div css={[tw`flex`, PAGE_WRAPPER_STYLES]}>
        <OrgSettingsSideNavbar orgSlug={orgSlug} />
        <div css={tw`w-full mx-6 my-12`}>
          <Switch>
            <Route
              exact
              path={routes.memberships.definition}
              render={({ match }) => (
                <OrgMembers orgSlug={match.params.orgSlug} />
              )}
            />
            <Route
              path={[
                routes.orgSettingsGeneral.definition,
                routes.orgSettings.definition,
              ]}
              render={({ match }) => (
                <OrgGeneralSettings orgSlug={match.params.orgSlug} />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};
