import firebase from 'firebase/app';
import 'firebase/firestore'; // Required for side-effects
import 'firebase/storage'; // Required for side-effects
import 'firebase/auth'; // Required for side-effects
import { config } from './config';

firebase.initializeApp(config.firebase);

if (window.location.hostname === 'localhost') {
  // @ts-ignore
  window.__firestore__ = firebase.firestore();
}
