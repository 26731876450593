/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { NavLink } from 'react-router-dom';

import { routes } from 'src/routes';

export const OrgSettingsSideNavbar = ({ orgSlug }: { orgSlug: string }) => {
  return (
    <div css={tw`flex flex-col w-[400px]`}>
      <div css={tw`flex flex-col w-[228px] mt-12 mx-6`}>
        <NavLink
          css={[
            tw`px-3 py-2 mb-2 hover:bg-gray-100 rounded`,
            css`
              &.active {
                font-weight: 500;
              }
            `,
          ]}
          to={routes.orgSettingsGeneral.getPath({ orgSlug })}
          isActive={(match, location) => {
            const validActivePaths = [
              routes.orgSettings.getPath({ orgSlug }),
              routes.orgSettingsGeneral.getPath({ orgSlug }),
            ];
            return validActivePaths.includes(location.pathname);
          }}
        >
          General
        </NavLink>
        <NavLink
          css={[
            tw`px-3 py-2 mb-2 hover:bg-gray-100 rounded`,
            css`
              &.active {
                font-weight: 500;
              }
            `,
          ]}
          to={routes.memberships.getPath({ orgSlug })}
        >
          Team Members
        </NavLink>
      </div>
    </div>
  );
};
