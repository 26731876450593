/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/core';
import React from 'react';

export const CircleLoader: React.FC<{
  thickness: number;
  fgColor: string;
  bgColor: string;
  width: string;
  height: string;
}> = ({ thickness, fgColor, bgColor, width, height }) => {
  return (
    <div
      className="CircleLoader"
      css={[
        css`
          width: ${width || '100%'};
          height: ${height || '100%'};
          border: ${thickness}px solid transparent;
          border-top-color: ${fgColor};
          border-radius: 50%;
          animation: 2s ${keyframes`
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(720deg);
              }
            `} cubic-bezier(0.24, 0.07, 0.73, 0.85) infinite;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: inherit;
            height: inherit;
            position: absolute;
            top: -${thickness}px;
            left: -${thickness}px;
            border: ${thickness}px solid ${bgColor};
            border-radius: 50%;
            opacity: 0.5;
          }
        `,
      ]}
    />
  );
};
